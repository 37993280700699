const resources = {
  en: {
    translation: {
      dir: 'ltr',
      'Display reports up to': 'Time of the latest transaction:',
      'Show more documents': 'Show more documents',
      Show: 'Show',
      Search: 'Search',
      ChangedStatus: 'Changed status',
      Pages: 'Pages',
      Charge: 'Charge',
      ChooseAccountType: 'Choose account type',
      Transaction: 'Transaction',
      'Number Of Transactions': 'Number of transactions',
      'Accounting Panel': 'Accounting Panel',
      Update: 'Update',
      'Add Ticket': 'Add Ticket',
      TicketTitle: 'Ticket title',
      'Your Credit': 'Your Credit',
      'General Ledger': 'General Ledger',
      'Journal Entries': 'Journal Entries',
      'Cash Transaction': 'Cash Transaction',
      'Bank Transaction': 'Bank Transaction',
      'Transfer Between Accounts': 'Transfer Between Accounts',
      'Foreign Exchange': 'Foreign Exchange',
      'Credit Limit': 'Credit limit',
      'Account Title': 'Account Title',
      'Account Registration': 'Account Registration',
      RegistrationIsDone: 'Registration is done',
      Administrator: 'Administrator',
      'Sample Person': 'Moein Rabti',
      Drafts: 'Drafts',
      Draft: 'Draft',
      Equal: 'Equal',
      'Equivalent Amount': 'Equivalent amount',
      'Equivalent Amount Currency': 'Equivalent amount currency',
      'Draft Equivalent Amount': 'Draft equivalent amount',
      Title: 'Title',
      English: 'English',
      Alerts: 'Alerts',
      Alert: 'Alert',
      Persian: 'Persian',
      Account: 'Account',
      Documents: 'Documents',
      Reports: 'Reports',
      Settings: 'Settings',
      'Create Customer': 'Create Customer',
      And: 'And',
      Dashboard: 'Dashboard',
      Welcome: 'Welcome',
      'Moneyx Accounting Panel': 'Moneyx Accounting Panel',
      'Moneyx Exchange Accounting Software':
        'Moneyx Exchange Accounting Software',
      'Site under construction or maintenance':
        'Site under construction or maintenance',
      'building not finished yet': 'building not finished yet',
      'First Name': 'First Name',
      'Middle Name': 'Middle Name',
      'Last Name': 'Last Name',
      Gender: 'Gender',
      Undefined: 'Undefined',
      Unknown: 'Unknown',
      Known: 'Known',
      Male: 'Male',
      Female: 'Female',
      'Phone Number': 'Phone Number',
      'Language Selection': 'Language Selection',
      'Transfer Owners': 'Transfer Owners',
      Description: 'Description',
      Return: 'Return',
      'Register New Customer': 'Register New Customer',
      'Basic Information': 'Basic Information',
      BranchBasicInformation: 'Branch basic information',
      CustomerBasicInformation: 'اطلاعات اولیه مشتری',
      Colon: ':',
      'Account Number': 'Account number',
      'Add Account': 'Add account',
      'Occupation And Personal Details': 'Occupation And Personal Details',
      'Add Address': 'Add Address',
      AddNewAddress: 'Add new address',
      SubmitNewAddress: 'Submit new address',
      SubmitNewPhoneNumber: 'Submit new phone number',
      AddNewPaymentPlace: 'Add new payment place',
      AddNewBranch: 'Add new branch',
      AddNewContactDetails: 'Add new contact details',
      AddNewCustomer: 'Add new customer',
      'Bank Information': 'Bank Information',
      Attachment: 'Attachment',
      'Birth Place Country': 'Brith Place Country',
      Nationality: 'Nationality',
      'Birth Place': 'Birth Place',
      'Birth Date': 'Birth Date',
      Email: 'Email',
      Website: 'Website',
      Occupation: 'Occupation',
      'Other Occupation': 'Other Occupation',
      'Occupation Branch': 'Occupation Branch',
      'Economic Registering Number': 'Economic Registering Number',
      Continue: 'Continue',
      England: 'England',
      Iranian: 'Iranian',
      Programmer: 'Programmer',
      Employee: 'Employee',
      Country: 'Country',
      Province: 'Province',
      City: 'City',
      'Zip Code': 'Zip Code',
      'Exact Address': 'Exact Address',
      Tehran: 'Tehran',
      London: 'London',
      'Currency Type': 'Currency',
      'Account Owner Name': 'Account owner name',
      'Branch Code': 'Branch Code',
      'Swift Code': 'Swift Code',
      IBAN: 'IBAN',
      Register: 'Register',
      Rial: 'Rial',
      Pond: 'Pond',
      CEO: 'CEO',
      'Document Type': 'Document type',
      'Document Type2': 'Document type',
      'Holder Name': 'Holder name',
      'Document Number': 'Document number',
      'Document Number2': 'Document number',
      'Receive Document Date': 'Receive document date',
      'Due Date': 'Due date',
      'Due Date Of Document': 'Due date of document',
      'Expire Date': 'Expire Date',
      'Issue Date': 'Issue Date',
      Profile: 'Profile',
      'Sign Out': 'Sign Out',
      'Document Status': 'Document Status',
      Permanent: 'Permanent',
      Current: 'Current',
      'Type 1': 'Type 1',
      'Type 2': 'Type 2',
      'Drop Your Desired File Here': 'Drop Your Desired File Here',
      Or: 'Or',
      Choose: 'Choose',
      'Valid Formats': 'Valid Formats',
      Application: 'Application',
      'Application Settings': 'Application Settings',
      'Edit Number': 'Edit Number',
      'Connected Account': 'Connected Account',
      'Block Access To Application': 'Block Access To Application',
      'Send Notification Via': 'Send Notification Via',
      SMS: 'SMS',
      Telegram: 'Telegram',
      Whatsapp: 'WhatsApp',
      'Accept And Register': 'Accept and register',
      Customers: 'Customers',
      Customer: 'Customer',
      List: 'List',
      'Customers List': 'Customers List',
      Accounts: 'Accounts',
      'Draft Orders List': 'Draft Orders List',
      'Register Customer': 'Register Customer',
      None: 'None',
      'Select Customer': 'Select Customer',
      'Sign in to your account to continue':
        'Sign in to your account to continue',
      'Welcome back': 'Welcome back',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Sign in': 'Sign in',
      'Remember me': 'Remember me',
      'Forgot password': 'Forgot password',
      Moneyx: 'Moneyx',
      'Exchange Accounting Software': 'Exchange Accounting Software',
      Balance: 'Balance',
      CalculationBalance: 'The balance is calculating',
      BalanceCurrency: 'Balance currency',
      FinancialInformation: 'Financial information',
      Inventory: 'Inventory',
      'Sample Balance': '5,435,000',
      'Sample Balance Currency': 'Tomans',
      'Complete Customer Information': 'Complete Customer Information',
      'Next Step': 'Next',
      Sent: 'Sent',
      'Not Sent': 'Not Sent',
      'Customer Information': 'Customer Information',
      All: 'All',
      Currencies: 'Currencies',
      SelectedCurrenciesList: 'Selected currencies list',
      'Draft Submission': 'Draft Submission',
      Branch: 'Branch',
      Sender: 'Sender',
      Receiver: 'Receiver',
      'Draft Currency': 'Draft Currency',
      'Draft Amount': 'Draft amount',
      'Company Registration': 'Company Registration',
      'Contact Details': 'Contact Details',
      Address: 'Address',
      Addresses: 'Addresses',
      Shareholders: 'Shareholders',
      'Receivers List': 'Receivers List',
      'Receiver Registration': 'Receiver Registration',
      Accept: 'Accept',
      'Customer Registration': 'Customer Registration',
      'Search By': 'Search by',
      Name: 'Name',
      Comma: ',',
      'Document Prime': 'Document',
      DocumentName: 'document name',
      'Advanced Search': 'Advanced Search',
      'Customer Number': 'Customer Number',
      'Customer Name': 'customer name',
      CustomerPhoneNumber: 'customer phone number',
      ReceiverName: 'receiver name',
      Group: 'Group',
      Groups: 'Groups',
      'Father Name': 'Father Name',
      'Account History': 'Account History',
      'Transaction History': 'Transaction history',
      'Selected Transaction History': 'Selected transaction history',
      'Applications Settings': 'Application Settings',
      'Draft Receivers': 'Draft Receivers',
      'Send Message': 'Send Message',
      'Customer Full Details': "Customer's Full Details",
      'Download Customers List Summary': 'Download Customers List Summary',
      'Download Customers Full List': 'Download Customers Full List',
      'Document Expiry Date': 'Document Expiry Date',
      'Total Debt In Dollars': 'Total debt in Dollars',
      To: 'To',
      Select: 'Select',
      Collateral: 'Collateral',
      'Communication Way': 'Communication Way',
      Enable: 'Enable',
      Webcam: 'Webcam',
      Upload: 'Upload',
      Photo: 'Photo',
      'Add New Phone Number': 'Add New Phone Number',
      'At Least One Number Is Mandatory': 'At Least One Number Is Mandatory',
      'Phone Number Cannot Be Repeated.': 'Phone Number Cannot Be Repeated.',
      'Register Customer Without Additional Information': 'Register Customer',
      'Wholesale Exchange': 'Wholesale Exchange',
      'Retail Exchange': 'Retail Exchange',
      Create: 'Create',
      'System Management': 'System Management',
      Business: 'Business',
      'Create Group': 'Create Group',
      'Initial Settings': 'Initial Settings',
      'Header Settings': 'Header Settings',
      'Add Currency': 'Add Currency',
      'System Settings': 'System Settings',
      'System Rate': 'System Rate',
      'Advanced Settings': 'Advanced Settings',
      Debtor: 'Debtor',
      DebtorSum: "Debtor's sum",
      CreditorSum: "Creditor's sum",
      'List Of Most Debtors': 'List of the most debtors',
      Creditor: 'Creditor',
      'Create Currency': 'Create Currency',
      Normal: 'Normal',
      Other: 'Other',
      Dollar: 'Dollar',
      'Account Remaining': 'Account remaining',
      Remaining: 'Remaining',
      From: 'From',
      Date: 'Date',
      'Balance Currency Type': 'Currency',
      'Mobile Number': 'Phone number',
      'Account Details': 'Account Details',
      'Banks List': 'Banks list',
      'Banks Management': 'Banks Management',
      Row: 'Row',
      Add: 'Add',
      'New Bank': 'New Bank',
      'Bank Name': 'Bank Name',
      'Card Number': 'Card Number',
      'Bank Branch Code': 'Bank Branch Code',
      Status: 'Status',
      OverallStatus: 'Overall status',
      ChequeOverallStatus: 'Cheque allover cheque',
      SubmitChequeInMoneyx: 'Submit cheque in moneyx system',
      DebtCollection: 'Debt collection',
      Active: 'Active',
      Archived: 'Archived',
      'Bank Book': 'Bank Book',
      'No Bank Is Registered': 'No Bank Is submitted',
      'No Submited Transaction': 'No Submitted Transaction',
      Bank: 'Bank',
      Edit: 'Edit',
      State: 'State',
      Yes: 'Yes',
      No: 'No',
      IncreaseCredit: 'Increase Credit',
      CancelDraft: 'Cancel Draft',
      'Edit Draft': 'Edit Draft',
      'Edit Transaction': 'Edit Transaction',
      'Edit Bank Transaction': 'Edit Bank Transaction',
      'Submit Edit': 'Edit',
      'Archiving The Account': 'Archiving The Account',
      'Cash withdrawal': 'Cash withdrawal',
      'Cash deposit': 'Cash deposit',
      Deposit: 'Deposit',
      Withdraw: 'Withdraw',
      Deposit2: 'Deposit',
      Withdraw2: 'Withdraw',
      'Search In Moneyx': 'Search In Moneyx',
      'Quick Access': 'Quick access',
      'Print Information': 'Print',
      'Upload File': 'Upload file',
      'Transaction Description': 'Transaction description',
      'Receipt Number': 'Receipt number',
      Receipt: 'receipt',
      ReceiptWithHeader: 'Receipt with header',
      ReceiptWithoutHeader: 'Receipt without header',
      'Transaction Amount': 'Transaction amount',
      'Changed Amount': 'Changed amount',
      Messages: 'Messages',
      Notifications: 'Notifications',
      Languages: 'Languages',
      'Account Balance': 'Account balance',
      HasAccountInitialBalance: 'Does the account have an initial balance?',
      IsAccountHidden: 'The account should be hidden from other users',
      'Cash Desk': 'Cash desk',
      'System Accounts': 'System accounts',
      Cash: 'Cash',
      'Cash Name': 'Cash name',
      Costs: 'Costs',
      Commission: 'Commission',
      OnlyCommissions: 'Only commissions',
      OnlyTransactions: 'Only transactions',
      WithCommission: 'With commission',
      WithoutCommission: 'Without commission',
      creditaccount: 'Credit account',
      UnknownCreditAccount: 'Unknown credit account',
      unknowncreditaccount: 'Unknown credit account',
      'Unexecuted draft account': 'Unexecuted draft account',
      'unexecuted draft account': 'Unexecuted draft account',
      'Account Information': 'Account information',
      ChargingSale: 'Charging sale',
      UnrealizedDraft: 'Unrealized draft',
      'Unrealized Drafts': 'Unrealized drafts',
      'Pending Drafts List': 'Pending drafts list',
      UnexecutedDraft: 'Unrealized drafts',
      BankPayment: 'Bank Payment',
      'No Option Message': 'No options',
      'No Content Message': 'No content',
      'Branch Registration': 'Branch registration',
      'Branch Edition': 'Branch edition',
      'Advanced Details': 'Advanced details',
      'Drafts List': 'Drafts list',
      'Draft Documents List': 'Draft documents list',
      'Unrealized Drafts List': 'Unrealized drafts list',
      'Branches List': 'Branches list',
      'Branch List': 'Branch list',
      'Trade Currency': 'Trade currency',
      'Currencies Management': 'Currencies management',
      'Branch Account': 'Branch account',
      'Credits Account': 'Credits account',
      'Customer Credit Account': 'Customer credit account',
      'Unknown Bank Deposits': 'Unknown deposit',
      'Submit Transaction': 'Submit transaction',
      'Submit Cash Transaction': 'Submit cash transaction',
      'Submit Bank Transaction': 'Submit bank transaction',
      'New Submit Bank Transaction': 'Submit bank transaction (New)',
      SubmitInterBankTransactionSuccessfully:
        'Interbank transaction submitted successfully.',
      'Register Person': 'Register person',
      'Edit Person': 'Edit person',
      'Edit Company': 'Edit company',
      CommonAccount: 'Account',
      BranchAccount: 'Branch account',
      BankAccount: 'Bank account',
      SubmitNewBankAccount: 'Submit new bank account',
      NewBankAccount: 'New bank account',
      'Unknown Bank Transactions': 'Unknown bank transactions',
      customer: 'Customer',
      receiver: 'Receiver',
      'Interbank Transaction': 'Interbank transaction',
      'Outgoing Draft Submission': 'Outgoing Draft Submission',
      'Incoming Draft Submission': 'Incoming Draft Submission',
      'Draft Conduction': 'Draft Conduction',
      'Cheques List': 'Cheques List',
      'Debtor And Creditor List': 'Debtor And Creditor List',
      'Commissions Report': 'Report Of Commissions',
      'Trade Currencies List': 'Trade Currencies List',
      SavedToCashDeskOrBankAccount: 'Saved to cash desk or bank account',
      SavedToCustomerAccount: 'Saved to customer account',
      HasReturned: 'Has Returned',
      CashedToMoney: 'Cashed To Money',
      'Cashed to money': 'Cashed to money',
      'cashed to money': 'Cashed to money',
      UnknownBankAccounts: 'Unknown Bank Accounts',
      'Profit And Loss Statement': 'Profit And Loss Statement',
      'Calculating Profit and Loss': 'Calculating Profit and Loss',
      'Payment Order': 'Payment Order',
      profile: 'profile',
      'Operation of the day': 'Operation of the day',
      'Financial Operation': 'Financial Operation',
      DocumentIsPrivate: 'Document is private',
      'Net Profit': 'Net Profit',
      'Gross Profit': 'Gross Profit',
      Cost: 'Cost',
      Delete: 'Delete',
      'no attachments': 'no attachments',
      OK: 'OK',
      Cancel: 'Cancel',
      Clear: 'Clear',
      'Account Status': 'Account status',
      'System Guides': 'System Guides',
      'System Main Currency': 'System Main Currency',
      'Increase Customer Credit': 'Increase Customer Credit',
      'Date And Time': 'Date and time',
      AllEmployees: 'All employees',
      ViewGroup: 'View group',
      DataEntry: 'Data entry',
      HaveAccount: 'Have an account',
      BranchStatus: 'Branch status',
      DraftDescription: 'Description of draft',
      'Credit Currency': 'Credit Currency',
      'Credit Amount': 'Credit Amount',
      'Transaction Information': 'Transaction information',
      'Credit Registration': 'Credit Registration',
      'Reported Bugs': 'Reported Bugs',
      'Excel Data Output': 'Excel Data Output',
      'Bug Details': 'Bug Details',
      'My Tickets': 'My Tickets',
      'From Date': 'From Date',
      'To Date': 'To Date',
      Failed: 'Failed',
      Pend: 'Pend',
      Success: 'Success',
      'Request Status': 'Request Status',
      'Pending Transactions List': 'Pending Transactions List',
      Submmit: 'Submmit',
      Reject: 'Reject',
      'Remove Filters': 'Remove filters',
      Number: 'Number',
      'From Number': 'From number',
      'To Number': 'To number',
      'From Amount': 'From amount',
      'To Amount': 'To amount',
      numbers: 'numbers',
      'Total Numbers': 'Total numbers',
      'Date Of Payment Order': 'Date Of Payment Order',
      'Account Owner': 'Account Owner',
      'Total Deposit Amount': 'Total Deposit Amount',
      Detail: 'Detail',
      ShowDetails: 'Show details',
      ShowBrief: 'Show brief',
      SeeDetails: 'See details',
      Operation: 'Operation',
      RepeatOperation: 'Repeat operation',
      'Operation Type': 'Operation type',
      'Account Name': 'Account name',
      'Account Type': 'Account type',
      'Employee Name': "Employee's name",
      Type: 'Type',
      'Visit Account': 'Visit Account',
      VisitCustomerAccount: 'Visit customer account',
      VisitCashAccount: 'Visit cash',
      VisitBankAccount: 'Visit bank account',
      visitCustomerCreditAccount: 'visit customer credit account',
      VisitUnknownBankTransactionsAccount:
        'Visit unknown bank transactions account',
      VisitDestinationBankBook: 'Visit destination bank book',
      VisitSourceBankBook: 'Visit source bank book',
      DestinationBranchAccount: 'Destination branch account',
      SourceBranchAccount: 'Source branch account',
      'Creditor Customer': 'Creditor customer',
      Recieve: 'Recieve',
      'Debtor Customer': 'Debtor customer',
      'Bank Transaction Is Confidential': 'Bank Transaction Is Confidential',
      'Transaction Currency': 'Transaction currency',
      'Commission Amount': 'Commission amount',
      'Commission Creditor Amount': 'Commission Creditor Amount',
      'Is There A Fee For This Transaction':
        'Is there a fee for this transaction',
      UncertainCreditLimit:
        'If the credit limit is not specified, the credit limit is considered zero',
      'Commission Currency': 'Commission currency',
      Incoming: 'Incoming',
      Outgoing: 'Outgoing',
      'Checkout History': 'Checkout History',
      'Last Checkout': 'Last checkout',
      'Cheque History': 'Cheque History',
      'Upload Excel File': 'Upload Excel File',
      'Information Output': 'Information Output',
      'Normal Print': 'Normal Print',
      'Private Print': 'Private Print',
      'Transaction Date': 'Transaction date',
      'Operation Date': 'Operation date',
      'Transaction Number': 'Transaction number',
      'Buy Currency': 'Buy Currency',
      'Sell Currency': 'Sell Currency',
      'Buy Commission': 'Buy Commission',
      'Sell Commission': 'Sell Commission',
      '(Optional)': '(Optional)',
      'In progress': 'In progress',
      'Buy Currency Setting': 'Buy Currency Setting',
      'Sell Currency Setting': 'Sell Currency Setting',
      'Upload Documents': 'Upload Documents',
      Private: 'Private',
      'Not Private': 'Not Private',
      'Not Change The Credit Limit': 'Not Change The Credit Limit',
      'Change The Credit Limit': 'Change The Credit Limit',
      'Confirm Buy': 'Confirm Buy',
      'Confirm Sell': 'Confirm Sell',
      'Trade Rate': 'Trade Rate',
      'Currency Trade Rate': 'Currency trade rate',
      Convert: 'Convert',
      SubmitConversion: 'Submit conversion',
      'Buy Amount': 'Buy Amount',
      'Sell Amount': 'Sell Amount',
      'Seller Commission': 'Seller Commission',
      'Buyer Commission': 'Buyer Commission',
      Buyer: 'Buyer',
      Seller: 'Seller',
      Submit: 'Submit',
      SubmitTransfer: 'Submit transfer',
      NotHave: "Doesn't have",
      'Aggregation Of Fees': 'Aggregation of fees',
      'Draft Number': 'Draft Number',
      'Incoming Draft Number': 'Incoming Draft Number',
      'Outgoing Draft Number': 'Outgoing Draft Number',
      Confirm: 'Confirm',
      'System Account': 'System Account',
      'Customer Commission': 'Customer commission',
      'Branch Commission': 'Branch commission',
      'Settings And File Upload': 'Settings And File Upload',
      'Other Number': 'Other Number',
      'Debtor Commission': 'Debtor commission',
      'Creditor Commission': 'Creditor commission',
      'Debtor Account': 'Debtor Account',
      'Creditor Account': 'Creditor Account',
      'Source Of Money': 'Source of money',
      'Purpose Of Exchange': 'Purpose of exchange',
      'Periodic Profit Chart': 'Periodic profit chart',
      'Daily Transactions Chart': 'Daily transactions chart',
      'Organization Chart': 'Organization chart',
      'Opening Account In Australia Bank': 'Opening Account In Australia Bank',
      'See Australia Bank Account': 'Australia bank account information',
      AdditionalInformation: 'Additional Information',
      // ************************ default document types **************************
      'Birth Certificate': 'Birth Certificate',
      'National Card': 'National Card',
      Passport: 'Passport',
      'Physical Tezkire': 'Physical Tezkire',
      'Electronic Tezkire': 'Electronic Tezkire',
      'Kimlik Card': 'Kimlik Card',
      'Driving License': 'Driving License',
      'Temporary Residence Card Of Foreign Nationals':
        'Temporary Residence Card Of Foreign Nationals',
      Police: 'Police',
      'Civil Registry Office': 'Civil Registry Office',
      'Immigration Office': 'Immigration Office',
      'Live Currency Rate': 'Live Currency Rate',
      // ************************ end default document types **************************
      // ************************ phrase items translations **************************
      'transfer between accounts': 'Transfer between accounts',
      'transaction id': 'Transaction Id',
      'transaction id:': 'transaction id:',
      'transfer between accounts with': 'transfer between accounts with',
      amount: 'Amount',
      currency: 'Currency',
      currencyCode: 'Currency code',
      CurrencyRate: 'Currency rate',
      DecimalPlacesCalculation: 'How many decimal places should be calculated?',
      CalculationType: 'Calculation type',
      benchMarkCurrency: 'Benchmark currency',
      'Bank Currency': 'Bank Currency',
      debtor: 'debtor',
      creditor: 'creditor',
      Percent: 'Percent',
      Liquidity: 'Liquidity',
      Today: 'Today',
      'Until Today': 'Until today',
      'outgoing draft order': 'outgoing draft order',
      'incoming draft order': 'incoming draft order',
      IncomingDrafts: 'Incoming drafts',
      OutgoingDrafts: 'Outgoing drafts',
      UnexecutedDrafts: 'Unexecuted drafts',
      ExecutedDrafts: 'Executed drafts',
      AllDrafts: 'All drafts',
      'Miscellaneous Draft Submission': 'miscellaneous draft order',
      'Miscellaneous Draft': 'Miscellaneous draft',
      'Payment Place': 'Payment Place',
      to: 'to',
      from: 'from',
      at: 'at',
      'was registered.': 'was registered.',
      'commission for': 'commission for',
      'bank account': 'bank account',
      'customer account': 'customer account',
      'sell currency': 'sell currency',
      'buy currency': 'buy currency',
      'bank transaction': 'bank transaction',
      CustomerAccountCode: 'Customer Account Code',
      commission: 'commission',
      cash: 'cash',
      costs: 'costs',
      'cash transaction': 'cash transaction',
      unrealizeddraft: 'unrealized draft',
      unexecuteddraft: 'unrealized draft',
      unknownbankaccounts: 'unknown bank accounts',
      chargingsale: 'charging sale',
      'buy charge': 'buy charge',
      'Error Number': 'Error Number',
      'Error Title': 'Error Title',
      'Error Opening Date': 'Error Opening Date',
      'Error Closing Date': 'Error Closing Date',
      'Error Status': 'Error Status',
      Documentations: 'documentations',
      Others: 'others',
      'Draft Cancellation': 'Cancel draft',
      'Undo Cancellation': 'Undo Cancellation',
      'Transaction Type': 'Transaction Type',
      'Transaction Status': 'Transaction Status',
      'Edit Miscellaneous Draft': 'Edit Miscellaneous Draft',
      'Spot Rate': 'Spot Rate',
      'Draft List': 'Draft List',
      Manual: 'Manual',
      SystemLanguage: 'System language',
      PresentNumbers: 'Present numbers',
      FontSize: 'Font size',
      Small: 'Small',
      Medium: 'Medium',
      Large: 'Large',
      'New Version Available Phrase':
        'A new version of the system is available, please refresh the page',
      OnlyWithAccountTitle: 'Only with account title',
      accountStatusMessage:
        'Balance will be shown after select the account and currency.',
      draftStatusMessage:
        'After selecting the account and draft currency, the account balance will be displayed.',
      accountStatusMessage2:
        'Balance will be shown after selecting the account.',
      bankStatusMessage: 'Balance will be shown after selecting the bank.',
      DestinationBankStatusMessage:
        'After selecting the destination bank, the balance of the bank will be displayed.',
      SourseBankStatusMessage:
        'After selecting the source bank, the balance of the bank will be displayed.',
      bankStatusMessage2: 'Balance will be shown after selecting the bank.',
      positiveTradeRate: 'Trade rate must be greater than zero',
      invalidTradeRate: 'Trade rate is not valid',
      'Bank withdrawal': 'Bank withdrawal',
      'Bank deposit': 'Bank deposit',
      AccountStatusMessage:
        'Balance will be shown after selecting the account.',
      // ************************ end phrase items translations **************************
      // ***********************************
      // countries
      // ***********************************
      Afghanistan: 'Afghanistan',
      Australia: 'Australia',
      China: 'China',
      Denmark: 'Denmark',
      Germany: 'Germany',
      Greece: 'Greece',
      Iran: 'Iran',
      Iraq: 'Iraq',
      Norway: 'Norway',
      Pakistan: 'Pakistan',
      'Saudi Arabia': 'Saudi Arabia',
      Sweden: 'Sweden',
      Turkey: 'Turkey',
      'United Arab Emirates': 'United Arab Emirates',
      Baghdad: 'Baghdad',
      Ankara: 'Ankara',
      Kabul: 'Kabul',
      'Islamabad, Karachi': 'Islamabad, Karachi',
      // ***********************************
      // end countries
      // ***********************************
      // ***********************************
      // actionTypes
      // ***********************************
      TransferBetweenTwoAccounts: 'Transfer Between Accounts',
      Cheque: 'Cheque',
      ChequeStatus: 'Cheque status',
      ChequeCurrentStatus: 'Cheque current status',
      ConsideringCheque: 'considering cheque',
      CashTransaction: 'Cash Transaction',
      IncomingDraft: 'Incoming Draft',
      OutgoingDraft: 'Outgoing Draft',
      Miscellaneous: 'Miscellaneous',
      BuySellCurrency: 'Trade Currency',
      BankTransaction: 'Bank Transaction',
      InterBankTransaction: 'Inter Bank Transaction',
      Checkout: 'Checkout',
      RemoveCheckout: 'Remove Checkout',
      PendDraft: 'Pend Draft',
      RemovePendDraft: 'Remove PendDraft',
      PaymentOrderReceipt: 'Payment Order Receipt',
      ExecuteDraft: 'Execute Draft',
      ChangeBranch: 'Change Rate',
      UnknownBankTransaction: 'Unknown Bank Transaction',
      AddNewAccount: 'Add new account',
      AddNewBank: 'Add new bank',
      AddBankAccount: 'Add bank account',
      UpdateAccount: 'Update Account',
      DeleteAccount: 'Delete Account',
      AddBank: 'Add Bank',
      UpdateBank: 'Update Bank',
      DeleteBank: 'Delete Bank',
      AddCustomer: 'Add Customer',
      UpdateCustomer: 'Update Customer',
      DeleteCustomer: 'Delete Customer',
      AddCompany: 'Add Company',
      UpdateCompany: 'Update Company',
      DeleteCompany: 'Delete Company',
      AddBranch: 'Add Branch',
      UpdateBranch: 'Update Branch',
      DeleteBranch: 'Delete Branch',
      ConnectToBranch: 'Connect To Branch',
      DisconnectFromBranch: 'Disconnect from branch',
      OneWayConnecting: 'One-Way Connecting',
      BranchName: 'Branch name',
      BranchManager: 'Branch Manager',
      BranchIsNotFound: 'Branch Is Not Found',
      SelecTheDesiredBranch: 'Selec The Desired Branch',
      InOneWayConnectionModeTheCounterpartyIsNotAblleToSeeYoureTransactions:
        'In One-Way Connection Mode The Counterparty Is Not Ablle To See Youre Transactions',
      AddCurrency: 'Add Currency',
      UpdateCurrency: 'Update Currency',
      DeleteCurrency: 'Delete Currency',
      AddPayOrder: 'Add Pay Order',
      UpdatePayOrder: 'Update Pay Order',
      DeletePayOrder: 'Delete Pay Order',
      PublishTransactionInWhatsapp: 'Publish transaction in Whatsapp',
      TransactionBeWithoutDescription: 'Transaction be without description',
      CashTransactionWithoutDescription:
        'Cash transaction be without description.',
      SyncCreditLimit: 'Sync credit limit',
      ShouldHaveSamevaluein: 'Should Have Same value in',
      'private transaction': 'Private transaction',
      'Invalid date': 'The date is invalid',
      'Required field message': 'Filling this field is necessary',
      uploaderInputLabelMaximumSize: 'The maximum file size should be 3 MB',
      DocumentsModalAcceptFormats: 'فرمت آن باید JPG, PNG, PDF, xlsx باشد.',
      ExcelUploaderInputLabelFormat: 'its format should be xlsx.',
      FileUploaderInputLabelFormat:
        'its format should be xls, xlsx, pdf, png, jpg, jpeg, doc, docx, webp.',
      WhatsAppCommunication: 'Link or Address of whatsapp group',
      DocumentTypeDescription: 'Document Type Description',
      CommissionAmountRegisteredToCreditor:
        "The commission amount will be submitted to the creditor's account.",
      CommissionAmountRegisteredToDebtor:
        "The commission amount will be submitted to the debtor's account.",
      'The commission amount must be less than the transaction amount':
        'The commission amount must be less than the transaction amount',
      SelectItemToAutoCalculate:
        'Which item should be calculated automatically?',
      'Value must be greater than zero': 'Value must be greater than zero',
      'File required': 'Attaching file is required',
      'no future dates message':
        "The date cannot be greater than today's date.",
      'Invalid email message': 'The email Entered is invalid',
      'Invalid website message': 'Website address is invalid',
      'Number message': 'Only numbers are allowed',
      'Existed username message': 'The username is exist',
      'Numbers and English letters message':
        'Just numbers and English letters are allowed',
      'Capital English letters message':
        'Just capital English letters are allowed',
      'Uncheck message': 'If no information is recorded, remove the tick',
      'Cheque date message': 'The due date cannot be less than the check date',
      'Iban message':
        'The Iban code must be exactly 26 characters and only English numbers and capital and small English letter are valid',
      'Card number message':
        'The card number must be number and contain 16 digits',
      'Other occupation message':
        'This field is required when occupation value is other',
      'Customer age limit message':
        'The age of the customer must be more than 10 years',
      'Issue date message': "The issue date cannot be older than today's date",
      'Expire date message':
        'The expiration date must be bigger than the issue date',
      'Phone number minimum length message':
        'The length of the phone number must be more than 9 characters',
      'Phone number maximum length message':
        'The length of the phone number must be less than 15 characters',
      'Phone number start message':
        'The phone number should nut start with zero',
      'Max number message': 'The number must be between zero and one hundred',
      'Invalid trade rate': 'The rate is not in the valid rate',
      'Required currency': 'Currency selection is required',
      InsertResidenceNumberMessage:
        'Enter the residence number from document type description field in edit customer section',
      HasUserName: 'Has username',
      InstantAccountAddition: 'Instant account addition',
      PersianUserNameError: 'Username should not be Persian',
      DuplicatedPhoneNumberError: 'This number is already registered',
      RegisterCompanyWithoutAdditionalInformation:
        'Register the company without additional information',
      SaveInformation: 'Will the information be saved?',
      AddNewDocument: 'Add new document',
      SelectAsActiveDocument: 'Select as the active document',
      SelectAsMainPhoneNumber: 'Select as the main phone number',
      SelectAsActiveAddress: 'Select as the active address',
      ChangeActiveDocumentSuccessfully: 'Active document changed successfully',
      SubmitNewDocument: 'Submit new Document',
      ContinueWithoutSubmitDocument: 'Continue without submit document',
      SelectedReferenceDocument: 'Selected reference document',
      SelectAsReferenceDocument:
        'Select this document as the refrence document',
      ContinueWithoutSubmitAccount: 'Continue without submit account',
      ContinueWithoutSubmitBankInformation:
        'Continue without submit bank information',
      SubmitNewAccount: 'Submit new account',
      'Continue without submit attachment':
        'Continue without submit attachment',
      'Submit new attachment': 'Submit new attachment',
      AddNewAttachment: 'Add new attachment',
      NoShareholders: 'No shareholder has been selected for the company.',
      NoBalanceIsThere: 'No balance has been submitted.',
      SelectShareholder: 'Choose shareholders now.',
      AddToShareholdersList: 'Add to shareholders list',
      DeleteUnexecutedDraftTitle:
        'Are you sure to delete the unexecuted draft?',
      DeleteDraftTitle: 'Are you sure to delete the draft?',
      DeleteBankAccountTitle: 'Are you sure to delete the bank account?',
      DeleteDraftOrderTitle: 'Are you sure to delete the draft order?',
      DeleteTradeCurrencyTitle:
        'Are you sure to delete the trade currency transaction?',
      DeleteSystemAccountTitle: 'Are you sure to delete the system account?',
      DeleteReceiverTitle: 'Are you sure to delete the receiver?',
      DeleteDocumentTitle: 'Are you sure to delete the document?',
      DeleteAddressTitle: 'Are you sure to delete the address?',
      DeleteAttachmentTitle: 'Are you sure to delete the attachment?',
      DeletePaymentOrderTitle: 'Are you sure to delete the payment order?',
      DeleteCustomerTitle: 'Are you sure to delete the customer?',
      DeleteAccountTitle: 'Are you sure to delete the account?',
      DeleteCurrencyTitle: 'Are you sure to delete the currency?',
      DeleteShareholderTitle: 'Are you sure to delete the shareholder?',
      DeleteTransactionTitle: 'Ary you sure to delete the document?',
      DeleteChequeTitle: 'Are you sure to delete the cheque?',
      DeleteHistoryTitle: 'Are you sure to delete Checkout History?',
      DeletePaymentPlaceTitle: 'Are you sure to delete the payment place?',
      DeleteBranchTitle: 'Are you sure to delete the branch?',
      DeleteCurrencyCalculationTitle: 'Are you sure to delete the calculation?',
      DeletePersonTitle: 'Are you sure to delete the person?',
      DeleteDraftDocumentTitle: 'Are you sure to delete the draft document?',
      DeleteTransaction2Title: 'Are you sure to delete the transaction?',
      RejectPendingDraftTitle: 'Are you sure to reject the draft?',
      UndoDraftCancellationTitle:
        'Are you sure to undo cancellation the draft?',
      CancelDraftSuccessfully: 'The draft canceled successfully.',
      NoSubmittedDraft: 'There is no draft.',
      NoSubmittedBranch: 'There is no branch.',
      NoSubmittedReceiver: 'There is no receiver.',
      NoSubmittedPaymentOrder: 'There is no payment order.',
      NoSubmittedCustomer: 'There is no customer.',
      NoSubmittedCheckout: 'There is no checkout.',
      NoSubmittedCheckout2: 'No checkout submitted.',
      NoSubmittedDocument: 'No document submitted.',
      NoSubmittedDocument2: 'No document submitted.',
      NoSubmittedOperation: 'No operation submitted.',
      NoSubmittedTicket: 'No ticket submitted.',
      DraftRegistrationSuccessfully: 'Draft submitted successfully.',
      EditDraftSuccessfully: 'The draft edited successfully.',
      EditedSuccessfully: 'Edited successfully',
      IncreaseCustomerCredit: 'Customer credit increased successfully.',
      DraftNumberSettingsSuccessfully:
        'The draft number settings have been done successfully.',
      EditBranchSuccessfully: 'Branch edited successfully.',
      EditBasicInformation: 'Edit Basic Information',
      EditPaymentPlaceSuccessfully: 'The payment place edited successfully.',
      EditPersonSuccessfully: 'The person edited successfully.',
      RegisterPaymentPlaceSuccessfully:
        'The payment place submitted successfully.',
      RegisterPersonSuccessfully: 'The person submitted successfully.',
      IncreaseOrganizationSuccessfully:
        'Increasing the credit of the organization was done successfully.',
      DeleteOrderDraftSuccessfully: 'Draft order deleted successfully.',
      DeleteDraftSuccessfully: 'The draft deleted successfully.',
      DeleteTransactionSuccessfully: 'The transaction deleted successfully.',
      ExportExcelSuccessfully: 'The excel file has downloaded successfully',
      ExportExcelAttention:
        'Please note that to receive the Excel file, you must have added all the suggested groups to your system settings!',
      AddThisGroupError: 'Plase add this group into your system settings',
      ExportExcelAccountGroupsError:
        'Unfortunately, the download was not successful. Please try again later',
      DeleteChequeSuccessfully: 'The cheque deleted successfully.',
      DeleteSystemAccountSuccessfully:
        'The system account deleted successfully.',
      DeleteReceiverSuccessfully: 'The receiver deleted successfully.',
      DeleteDocumentSuccessfully: 'The document deleted successfully.',
      DeleteAttachmentSuccessfully: 'The attachment deleted successfully.',
      DeleteBankInformationSuccessfully:
        'The bank information deleted successfully.',
      DeleteAddressSuccessfully: 'The address deleted successfully.',
      DeleteCustomerSuccessfully: 'The customer deleted successfully.',
      DeleteAccountSuccessfully: 'The account deleted successfully.',
      DeleteSuccessfully: 'The deletion was successful',
      DeleteCurrencySuccessfully: 'The currency deleted successfully.',
      DeleteShareholderSuccessfully: 'The shareholder deleted successfully.',
      DeletePaymentPlaceSuccessfully: 'The payment place deleted successfully.',
      DeleteBranchSuccessfully: 'The branch deleted successfully.',
      DeleteBankAccountSuccessfully: 'The bank account deleted successfully.',
      RejectPendingDraftSuccessfully: 'The draft rejected successfully.',
      SelectCheckoutDate: 'Select the checkout date.',
      DeleteLastCheckoutText:
        'To delete, you can only delete the last checkout of the customer.',
      DeleteLastBranchCheckoutText:
        'To delete, you can only delete the last checkout of the branch.',
      DisconnectBranchTitle: 'Are you sure to disconnect the branch?',
      DisconnectBranchSuccessfully:
        'Branch disconnection was done successfully.',
      DeletePhoneNumberSuccessfully: 'Phone number deleted successfully.',
      SubmitDocSuccessfully: 'Document submitted successfully.',
      ShareReceiptText:
        'You can share the transaction receipt from these ways:',
      PrintReceiptWithHeader: 'Print receipt with header',
      ShareInterBankTransactionReceiptText:
        'You can share the interbank transaction receipt from these ways:',
      ShareTradeCurrencyTransactionReceiptText:
        'You can share the buy currency receipt from these ways:',
      ShareSellCurrencyTransactionReceiptText:
        'You can share the sell currency receipt from these ways:',
      ShareSubmitTransactionReceiptText:
        'You can share the transaction receipt from these ways:',
      ShareExecuteDraftReceiptText:
        'You can share the execute draft receipt from these ways:',
      ShareDraftReceiptText: 'You can share the draft receipt from these ways:',
      PrintReceiptWithoutHeader: 'Print receipt without header',
      NotActiveSystemError: 'The system is not active for you!',
      ExpiredSubscriptionError: 'Your subscription has expired!',
      ReActiveAccountTitle:
        'Click on the "Buy version" button to reactive the system',
      buyVersionTitle: 'To use the Ariyana system, first purchase a version.',
      UseDesktopVersionTitle:
        'To activate this possibility, use the desktop version of the software',
      NoActiveMobile: 'Account has not any active phone number.',
      SendAccountBalanceSuccessfully: 'Account balance sent successfully.',
      SendToBranch: 'Send to branch',
      SentToWhatsapp: 'Send to whatsApp',
      AutomaticSendToWhatsapp: 'Automatic send to whatsapp',
      SendManuallyToWhatsApp: 'Manually send to WhatsApp',
      DepositAmountsDivision: 'Deposit amounts division',
      SelectBankInformation: 'Select bank information',
      'Cash desk cant be creditor': "Cash desk can't be creditor",
      'What is private transaction?': 'What is private transaction?',
      'Submit unknown bank deposit': 'Submit unknown bank deposit',
      SelectRowAlert: 'First select a row.',
      Copied: 'Copied.',
      SpecifyDraftType: 'Specify the type of draft',
      TransactionRegistrationDetails: 'Transaction registration details.',
      SubmitBugReportSuccessfully:
        'Bug report successfully submitted and will be answered as soon as possible.',
      RegisterBankAccountSuccessfully: 'Bank submitted successfully.',
      RegisterNewFilesSuccessfully: 'New documents successfully submitted.',
      RegisterBranchSuccessfully: 'The Branch successfully submitted.',
      EditAdditionalInfoSuccessfully:
        'Additional information successfully edited.',
      SubmitAdditionalInfoSuccessfully:
        'Additional information successfully submitted.',
      SubmitBuyCurrencySuccessfully: 'Buy currency successfully submitted.',
      changeActivePhoneNumberSuccessfully:
        'Active number changed successfully.',
      CreateAccountSuccessfully: 'Account created successfully.',
      EditAccountSuccessfully: 'Account edited successfully.',
      AddCustomersSuccessfully: 'Customers successfully added.',
      ExecuteDraftSuccessfully: 'The Draft executed successfully.',
      ChangeActiveAddressSuccessfully: 'Address activated successfully.',
      ShowMore: 'Show more',
      ShowLess: 'Show less',
      DraftCommission: 'Draft commission',
      DraftCommissionAmount: 'Draft commission amount',
      BranchShouldBeSelected: 'Branch must be selected.',
      ReceivedCurrency: 'Received currency',
      ConvertCurrencyRate: 'Convert currency rate',
      'Draft Equivalent Currency': 'Draft equivalent currency',
      'Submit Common Commission': 'Submit Common Commission',
      CheckRateTime: 'The check rate has expired.',
      BankAccountBalance: 'The balance of bank account',
      BuyerBankAccountBalance: 'The balance of buyer bank account',
      SelerBankAccountBalance: 'The balance of seller bank account',
      // ***********************************
      // end actionTypes
      // ***********************************
      Row3: '3 rows',
      Row4: '4 rows',
      Row5: '5 rows',
      Row6: '6 rows',
      Row10: '10 rows',
      Row15: '15 rows',
      Row20: '20 rows',
      Row25: '25 rows',
      Row50: '50 rows',
      Row100: '100 rows',
      Row150: '150 rows',
      Row200: '200 rows',
      Row250: '250 rows',
      // ***********************************
      // ***********************************
      // ***********************************
      // ***********************************
      // ***********************************
      Dari: 'Dari',
      Pashto: 'Pashto',
      Like: 'Like',
      Monthly: 'Monthly',
      Yearly: 'Yearly',
      Quarterly: 'Quarterly',
      SpecifyTypesOfIdentityDocuments: 'Specify types of identity documents',
      IssuingOrganization: 'Issuing organization',
      Light: 'Light',
      Dark: 'Dark',
      CheckoutAccount: 'Checkout',
      AccountDetails: "Account's details",
      Copy: 'Copy',
      AUnexecutedDraft: 'Unexecuted draft',
      TransactionDate: 'Transaction date',
      TransactionRow: 'Transaction row',
      openingBalance: 'Opening balance',
      SubmitNewCheckout: 'Submit new checkout',
      BalanceAmount: 'Balance amount',
      OperationHistory: 'Operation history',
      Unlimited: 'Unlimited',
      Share: 'Share',
      Highlight: 'Highlight',
      RemoveHighlight: 'Remove highlight',
      History: 'History',
      HighlightedTransactions: 'Highlighted transactions',
      ShowHighlightedTransactions: 'Show highlighted transactions',
      LastTransaction: 'Last transaction',
      Exchange: 'Exchange',
      RemainingAccount: 'Remaining account',
      BranchAddress: 'Branch address',
      BranchPhone: 'Branch phone number',
      Rate: 'Rate',
      BankWithdraw: 'Bank withdraw',
      BankDeposit: 'Bank deposit',
      SyncBalanceAndCreditLimit: 'Sync the balance with credit limit',
      AmountAccountWhatsappReport:
        'It is hereby confirmed that the balance of your account is as follows:',
      ChequeAssigner: 'Cheque assigner',
      ChequeReceiver: 'Cheque receiver',
      ChequePaymentPlace: 'Cheque payment place',
      'Visit Cheque owner account': 'Visit Cheque owner account',
      Received: 'Received',
      Paid: 'Paid',
      Category: 'Category',
      Branch2: 'Branch',
      CustomerAccountRemaining: 'Customer account remaining',
      VisitBuyerAccount: 'Visit buyer account',
      VisitSellerAccount: 'Visit seller account',
      EditOutgoingDraft: 'Edit outgoing draft',
      EditIncomingDraft: 'Edit incoming draft',
      CancelIncomingDraft: ' Cancel incoming draft',
      CancelOutgoingDraft: 'Cancel outgoing draft',
      ReceiveDraftAmount: 'Receive draft amount',
      VisitCreditorAccount: 'Visit creditor account',
      VisitDebtorAccount: 'Visit debtor account',
      AccountType: 'Account type',
      CustomerType: 'Customer type',
      RealCustomer: 'Real',
      LegalCustomer: 'Legal',
      TradeRate: 'Trade rate',
      SubmitSellCurrencySuccessfully: 'Sell currency successfully submitted.',
      CashDesk: 'Cash desk',
      RegisterTransactionMsg: 'The transaction submitted successfully',
      PrivatePrint: 'Private print',
      General: 'General',
      Banking: 'Banking',
      AccountManagement: 'Account management',
      ReceiveDate: 'Received date',
      NormalBranches: 'Normal branches',
      ConnectedBranches: 'Connected branches',
      ChequeNumber: 'Cheque number',
      ChequeBalance: 'ChequeBalance',
      'Total balance of accounts': 'Total balance of accounts',
      CheckoutDate: 'Checkout date',
      Deactivated: 'Deactivate',
      In: 'in',
      SubmitConnection: 'Submit connection',
      AddAdditionalInformation: 'Add additional information',
      BranchCurrency: 'Branch currency',
      NationalCode: 'National code',
      ConnectBranchText:
        'To connect, send the following link to the branch. (preferably emailed)',
      LinkValidityText: 'The validity of the link below is 10 minutes',
      SureAboutOneWayConnection: 'Are you sure about one way connection?',
      AllowedDraftLimit: 'Allowed draft limit',
      MirrorBranchAccount: 'Mirror branch account',
      DraftNumberSettings: 'draft number settings',
      View: 'View',
      OtherNumbers: 'Other numbers',
      SelectGroup: 'Select group',
      DraftRow: 'Draft row',
      DraftType: 'Draft type',
      BusinessName: 'Business name',
      'With commission only': 'With commission only',
      User: 'User',
      Time: 'Time',
      explanation: 'explanation',
      City2: 'City',
      ReceivedAmount: 'Received amount',
      DraftNumberAlert:
        'To draft number field must be bigger than from draft number field.',
      FilterBasedOnDate: 'Filter by date',
      FilterBasedOnDraftNumber: 'Filter by draft number',
      VisitAccountInMirrorBranch: 'Visit account in mirror branch',
      'Upload a logo for the header': 'Upload logo for the header',
      Canceled: 'Canceled',
      Canceller: 'Canceller',
      AllPaymentPlaces: 'All of the payment places',
      DraftSubmissionSettings: 'Draft submission settings',
      StatusBySelectedFiltersAlert: 'Status based on selected filters.',
      OldBankTransaction: 'Old bank transaction',
      DraftConductionEditPersonAlert:
        'Viewing documents and editing the receiver is possible only if the receiver is not registered manually.',
      AccountOperation: 'Account operation',
      SelectDraftCustomer: 'Select draft customer',
      AddPerson: 'Add person',
      NewBankTransaction: 'New bank transaction',
      selectTransactionType: 'Specify the transaction type.',
      ViewDocuments: 'View documents',
      DraftInformation: 'Draft information',
      ApplicationUsername: 'Application username',
      OutgoingNumber: 'Outgoing number',
      IncomingNumber: 'Incoming number',
      DeleteDocument: 'Delete document',
      DeleteReceiver: 'Delete receiver',
      New: 'New',
      Old: 'Old',
      CashAccount: 'Cash account',
      SameCurrencyAlert: 'The selected transaction currency must be the same',
      ReceiveDraftDate: 'Receive draft date',
      TheWayOfTransaction: 'How should the transaction be done?',
      PrivateDraftConduction: 'Execution of the draft should be private',
      SelectUnexecutedDraftAlert: 'Please select an unexecuted draft first',
      AllBranches: 'All branches',
      BaseOnAmount: 'Based on amount',
      BaseOnRate: 'Based on rate',
      RegistrationCode: 'Registration code',
      Email2: 'Email',
      IssuerName: 'Issuer name',
      IssuerDescription: 'Issuer description',
      OccupationTitle: 'Occupation title',
      RegistrationDate: 'Registration date',
      BankInformation: 'Bank information',
      'Customer ID': 'Customer ID',
      ViewMoreInformation: 'View more information',
      SystemNewFeatures: 'System new features',
      CommunicationType: 'Communication type',
      Close: 'Close',
      Next: 'Next',
      SelectedActiveDocument: 'Selected active document',
      SelectedActiveNumber: 'Selected active number',
      ContinueWithoutSubmitNumber: 'Continue without submit number',
      ViewLessInformation: 'View less information',
      SelectAsTheActiveNumber: 'Select as the active number',
      AddContactInfo: 'Add contact info',
      SelectAsCurrentNumber: 'Select current number',
      AddDocument: 'Add document',
      IndividualJobInformation: 'Individual job information',
      AccountCode: 'Account code',
      PersonalInformation: 'Personal information',
      CountryCode: 'Country code',
      InputAmount: 'Input amount',
      ExcelFile: 'Excel file',
      ExcelFileFormat: 'Excel file format',
      SaderatBank: 'Saderat bank',
      OldFormat: 'Old format',
      NewFormat: 'New format',
      SepahBank: 'Sepah bank',
      UserName: 'User name',
      UploadPhoto: 'Upload  photo',
      MaknakUsername: 'Maknak user name',
      Language: 'Language',
      ActivateTheCamera: 'Activate the camera',
      StandardFormat: 'Standard format',
      AddAttachment: 'Add attachment',
      PunishmentsList: 'List of punishments',
      DuplicateFileNameAlert: 'The file with the same name always exists',
      SearchResult: 'Search result',
      InputCurrency: 'Input currency',
      Position: 'Position',
      shareRate: 'Share rate',
      SubmitShareholder: 'Submit shareholder',
      AddShareholder: 'Add shareholder',
      SubmitShareholder2: 'Submit shareholder',
      CustomerSearch: 'Customer search',
      '404 error': 'Error 404 - Page not found.',
      AddToShareholders: 'Add to shareholders',
      PageNotFount: 'Page not found.',
      PassportNumber: 'Passport number',
      ResidenceNumber: 'Residence number',
      PageNotAvailable: 'The requested page is not available.',
      'Is the Maknak software active for the user or not?':
        'Is the Maknak software active for the user or not?',
      HasApplicationUserName: 'Has application user name',
      'Edit Occupation And Personal Details':
        'Edit Occupation And Personal Details',
      BirthCity: 'Birth city',
      MainDocument: 'Main document',
      ServiceType: 'Service type',
      CompanyType: 'Company type',
      TakePicture: 'Take picture',
      CompanyName: 'Company name',
      OrganizationNumber: 'Organization number',
      RegistrationNumber: 'Registration number',
      OriginCountry: 'Origin country',
      PaymentOrderBalance: 'Payment order balance',
      DebtorInformation: 'Debtor information',
      ConfirmDraftTitle: 'Are you sure to confirm selected drafts?',
      PureProfit: 'Pure profit',
      Profit: 'Profit',
      CalculatedProfitAmount: 'Calculated profit amount',
      CalculatedLossAmount: 'Calculated loss amount',
      Lossment: 'Loss',
      RejectDraft: 'Rejecting the draft',
      RejectDraftReason: 'The reason for rejecting the remittance',
      RejectDraftReasonMessage: 'Write the reason for rejecting the draft.',
      OriginCurrency: 'Origin currency',
      DestinationCurrency: 'Destination currency',
      SubmittedBy: 'Submitted by',
      CreditorInformation: 'Creditor information',
      PreventionCalculations: 'Prevention calculations',
      ComputingManagement: 'Computing management',
      GrindingRate: 'Grinding rate',
      InvalidRate: 'The rate is not valid.',
      CalculateProfit: 'Calculating the profit',
      BuyAmount: 'Buy amount',
      BuyCurrency: 'Buy currency',
      TotalPurchase: 'Total purchase',
      CreditCollection: 'Credit collection',
      ExecutePaymentOrder: 'Execute payment order',
      PaymentOrderDetails: 'Payment order details',
      PrincipalAmount: 'The principal amount',
      CardSender: 'Card sender',
      CurrencyAmount: 'Currency amount',
      ExecuteTransaction: 'Execute transaction',
      Creditors: 'Creditors',
      Deleted: 'Deleted',
      Deposited: 'Deposited',
      VisitDeposited: 'Visit deposited',
      AllCheques: 'All cheques',
      HasAssignorCommission:
        'Does this transaction have commission for the assignor?',
      HasReceiverCommission:
        'Does this transaction have commission for the receiver?',
      SubmitOperation: 'Submit operation',
      ChequeOperation: 'Cheque operation',
      AllOptions: 'All',
      ReceivedFrom: 'Received from',
      FilteredBy: 'Filtered by',
      AllDocs: 'All docs',
      TransactionTypeAmount: 'Transaction type amount',
      VisitCreditors: 'Visit creditors',
      NoState: 'There is no status!',
      Card: 'Card',
      SellCurrency: 'Sell currency',
      MirrorBranch: 'Mirror branch',
      BankDescription: 'Bank description',
      DeterminingDraftRateValidityTime:
        'Determining validity time of draft rate',
      DraftRateValidityDateTime: 'Date and time of draft rate validity',
      DollarRate: 'Dollar rate',
      IncreaseCurrencyDecimalPlaceWarning:
        'Decrease the accuracy of the decimal place of the currency will disrupt the editing of transactions made with this currency.',
      RateValidityTime: 'Validity time of draft rate',
      SystemDefaultCurrency: 'Default currency of system',
      SelectAsSystemDefaultCurrency: 'Select as the system default currency',
      NoSelectedCurrency: 'There is no selected currency to view.',
      DecimalPlace: 'Decimal place',
      AllCurrencies: 'All currencies',
      SubmitNewBank: 'Submit new bank',
      SubmitCheque: 'Submit cheque',
      SubmitPaymentOrder: 'Submit Payment order',
      CommissionDetails: 'Commission details',
      Multiplication: 'multiplication',
      Division: 'Division',
      RoundationAccuracy: 'The degree of accuracy of rounding',
      Solar: 'Solar',
      DateType: 'Date type',
      gregorian: 'Gregorian',
      MoneyxSupport: 'Moneyx support',
      AnswerText: 'Answer content',
      ExchangeSettingsTitle:
        'The information on these pages is related to your exchange settings.',
      CompletionFormsRequired: '(Completion of forms is required)',
      SystemColorMode: 'System color display mode',
      SubmitYourGroups: 'Register your desired groups.',
      ReceiveDocFromCustomerTitle:
        '(If you do not need to receive documents from the customer, skip this section do)',
      'Organizations List': 'Organizations list',
      currencyName: 'Currency name',
      ViewCurrency: 'View currency',
      WhatsappActiveDefault:
        'By default, WhatsApp notification should be active in transactions?',
      CurrencySymbol: 'Currency symbol',
      MyAccounts: 'My accounts',
      TimeZone: 'Time zone',
      ProfitAndLossRate: 'Profit and loss rate',
      PercentageChange: 'Percentage change',
      LongTimeWarning: 'This process may take some time',
      DefaultPhoneNumberCode: 'Default phone number code',
      Slogan: 'Slogan',
      ReceiptRules: 'Receipt rules',
      AddRule: 'Add rules',
      AddSameGroupsError: 'It is not possible to create similar groups',
      DocumentTitleError: 'The document title cannot be empty',
      IssuingOrganizationError: 'The issuing organization cannot be empty',
      CreateSameDocumentError: 'It is not possible to create similar documents',
      CharacterLimit: 'Maximum 100 characters',
      HeaderUploaderTitle:
        'The length and width of the header image must be the same',
      syncCreditLimitActive:
        'Synchronization of the credit limit Should be enable?',
      IncreaseCreditLimitDescription:
        "(If the synchronization is active, whenever the customer's credit limit is insufficient, the customer's credit limit will be increased by the amount of the transaction.)",
      profitAndLossAccuracy: 'Profit and loss percentage accuracy',
      DisplayDeviation:
        'Specify the percentage of the sensitivity of the system to display the deviation of calculations.s',
      CashDeskName: 'Cash desk name',
      CostAccountName: 'Cost account name',
      SubmitYourCurrencies: 'Register your used currencies.',
      DeterminingDraftRate: 'Determining the draft rate',
      DraftCurrencyOrder: 'Draft order currency',
      AddPaymentPlace: 'Add payment place',
      NoCommissions: 'There is no commission.',
      ViewHeader: 'View header',
      Capitals: 'Assets',
      ReportPeriod: 'Period of report',
      ExchangeName: 'Exchange company name',
      CurrentCapitals: 'Current assets',
      ConnectAccountToEquivalentAccount:
        'Connect account to equivalent account',
      PrePays: 'Pre pays',
      PrePurchaseContract: 'Pre purchase contracts',
      TotalValueOfOtherAssets: 'Total value of other assets',
      FixedAssets: 'Fixed assets',
      TangibleAssets: 'Tangible assets',
      ReductionInDepreciationOfTangibleAssets:
        'Reduction in depreciation of tangible assets',
      ReductionInDepreciationOfIntangibleAssets:
        'Reduction in depreciation of intangible assets',
      IntangibleAssets: 'Intangible Assets',
      Debts: 'Debts',
      CurrentDebts: 'Current debts',
      OtherDebts: 'Other debts',
      PreSaleContracts: 'Pre sale contracts',
      PayableBills: 'Payable bills',
      PayableTax: 'Payable tax',
      OperativeExpenses: 'Operative expenses',
      TotalShareholdersEquity: 'Total shareholders equity',
      OtherRevenue: 'Other revenue',
      AdministrativeExpenses: 'Administrative expenses',
      depreciationExpenses: 'Depreciations',
      telephoneExpenses: 'Telephone expenses',
      rentalExpenses: 'Rental expenses',
      insuranceExpenses: 'Insurance',
      licensingExpenses: 'Licensing expenses',
      employeeExpenses: 'employee expenses',
      otherExpenses: 'Other expenses',
      ReceiveExcelFile: 'Receive excel file',
      TotalDataOutput: 'Total data output',
      SelectedDataOutput: 'Selected data output',
      ExitFromSystem: 'Exit from system',
      PersonProfile: 'Person profile',
      ReceiveTotalDataExcel: 'Receive total data excel file',
      DataOutPutOneTimeMessage:
        'It is possible to perform this operation once a day.',
      AutoDownloadTheExcelFile:
        'The output Excel file will be downloaded automatically after preparation.',
      ExcelFileDownloaded:
        'The output Excel file has been successfully downloaded.',
      ExcelFileDownloadFailed: 'There was an error downloading the Excel file.',
      Asset: 'Asset',
      ReceiveTotalDataExcelFile: 'Receive excel file of the total data',
      UserAccountSystem: 'User account system',
      GetScreenShout: 'Get screen shot',
      AddFile: 'Add file if necessary.',
      ErrorPage: 'Error page',
      chargingSystem: 'Charging system',
      AccountingSystem: 'Accounting system',
      WelcomeMessage: 'Welcome to Moneyx accounting system!',
      PoliticalFighter: 'Political fighter',
      HighRisk: 'Hight risk',
      ModerateRisk: 'Moderate risk',
      LowRisk: 'Low risk',
      SmallBusiness: 'Small business',
      BigBusiness: 'Big business',
      CurrencyAuction: 'Currency auction',
      SanctionList: 'Sanction list',
      'Central analysis of trades and financial reports':
        'Central analysis of trades and financial reports',
      'Exchange license validation': 'Exchange license validation',
      'Credits on Da Afghanistan bank': 'Credits on Da Afghanistan bank',
      UseWebcam: 'Use the webcam (CTRL+k)',
      EnterExchangeNameMessage: 'Please enter your exchange name',
      'Exchange Name': 'Exchange name',
      Is: 'Is',
      IsNot: 'Is not',
      ShareholdersList: 'Shareholders list',
      EditShareholder: 'Edit shareholder',
      Attachments: 'Attachments',
      HasCustomer: 'Has customer',
      Limited: 'Limited',
      MainPhoneNumber: 'Main phone number',
      'The Entered Amount Is Bigger Than The Sum Of The Payment Order':
        'The entered amount is higher than the sum of the receipt amounts',
      Done: 'Done',
      'Under Review': 'Under review',
      registerTransactionGroups: 'Register groups for transactions',
      registerAccountsGroups: 'Register groups for accounts',
      'Real customer': 'Real customer',
      'Legal customer': 'Legal customer',
      'Number of Rows': 'Number of rows',
      SubmitPaymentPlace: 'Submit payment place',
      EditPaymentPlace: 'Edit payment place',
      PaymentPlaceName: 'Payment place name',
      agentName: 'Agent name',
      Transactions: 'Transactions',
      TotalCustomerCreditStatus: 'Total customer credit status',
      FromDraftNumber: 'From draft number',
      ToDraftNumber: 'To draft number',
      'Settled documents': 'Settled documents',
      'Submitted By Employee': 'Submitted by employee',
      AllCashDesks: 'All cash desks',
      SelectDocument: 'Select document',
      SelectDocumentAndSubmit:
        'Select desired document and then click on submit',
      'Credits on Da Afghanistan Bank': 'Credits on da Afghanistan bank',
      PaymentType: 'Payment type',
      RemainingCashAccount: 'Cash account remaining',
      ShowCashAccountBalanceMsg:
        'Balance will be shown after selecting the cash account.',
      PrivateCashTransaction: 'Cash transaction should be private.',
      'Submitted By Customer': 'Submitted by customer',
      CanceledDrafts: 'Canceled drafts',
      Count: 'Count',
      'Connected With Data': 'Connected With Data',
      'Retry (Application Is Not Running)':
        'Retry (Application Is Not Running)',
      'Connected Without Data': 'Connected Without Datad',
      'Synchronization Done': 'Synchronization Done',
      'Sync Account Title':
        'Are You Sure About Syncing The Customers Credit Limits?',
      customerStatus: 'Customer Status',
      Prepayments: 'Prepayments',
      AdvancePurchaseContracts: 'Advance Purchase Contracts',
      Assets: 'Assets',
      ReductionInTangibleAssets: 'Reduction in Depreciation of Tangible Assets',
      DepreciationOfIntangibleAssets: 'Depreciation of Intangible Assets',
      PayableTaxes: 'Payable Taxes',
      AdvanceSalesContracts: 'Advance Sales Contracts',
      TelephoneAndCommunicationExpenses: 'Telephone and Communication Expenses',
      RentExpenses: 'Rent Expenses',
      Insurance: 'Insurance',
      Partners: 'Partners',
      LicensingExpenses: 'Licensing Expenses',
      EmployeeSalaries: 'Employee Salaries',
      ClaimsOnCentralBank: 'Claims on Central Bank',
      Vendors: 'Vendors',
      Permanent1: 'Permanent',
      Temporary: 'Temporary',
      uploadFileWithTouch: 'Touch to upload file',
      SubmitedDocumentsCount: 'Number of registered documents',
      'Draft Settlement': 'Draft Settlement',
      SubmitInformation: 'Submit information',
      'No information has been recorded': 'No information has been recorded',
      SubmitAdditionalInformation: 'Submit Additional Information',
      editAdditionalInformation: 'edit Additional Information',
      branchTitle: 'Branch Title',
      NumberOfPaymentPlaces: 'Number Of Payment Places',
      PaymentPlace: 'Payment Place',
      ThePlaceOfPaymentIsNotRegistered: 'The place of payment is not registered'
    }
  },
  fa: {
    translation: {
      dir: 'rtl',
      'Display reports up to': 'زمان آخرین تراکنش:',
      'Show more documents': 'نمایش بیشتر مدارک',
      Show: 'نمایش',
      Search: 'جستجو',
      ChangedStatus: 'وضعیت تغییر',
      'Invalid email message': 'ایمیل نا معتبر است',
      Pages: 'صفحات',
      Charge: 'شارژ',
      ChooseAccountType: 'نوع حساب را انتخاب کنید',
      Alerts: 'اطلاع رسانی',
      Alert: 'اعلان',
      Transaction: 'تراکنش',
      'Number Of Transactions': 'تعداد تراکنش های انجام شده',
      'Accounting Panel': 'پنل حسابداری',
      Update: 'بروزرسانی',
      'Add Ticket': 'ثبت تیکت',
      TicketTitle: 'عنوان تیکت',
      'Your Credit': 'اعتبار شما',
      'General Ledger': 'دفتر کل',
      'Journal Entries': 'دفتر روزنامه',
      'Cash Transaction': 'تراکنش نقدی',
      'Bank Transaction': 'تراکنش بانکی',
      'Transfer Between Accounts': 'انتقال بین حساب',
      'Foreign Exchange': 'خرید و فروش ارز',
      'Sample Person': 'معین ربطی',
      'Credit Limit': 'سقف اعتبار',
      'Account Title': 'عنوان حساب',
      'Account Registration': 'افتتاح حساب',
      'Create Customer': 'ثبت مشتری جدید',
      'Site under construction or maintenance': 'در حال تولید هستیم ...',
      'building not finished yet': 'محتوای این قسمت هنوز آماده نیست :)',
      And: 'و',
      English: 'انگلیسی',
      Persian: 'فارسی',
      Title: 'عنوان',
      Account: 'حساب',
      Drafts: 'حواله‌ها',
      Draft: 'حواله',
      Equal: 'معادل',
      'Equivalent Amount': 'مبلغ معادل',
      'Equivalent Amount Currency': 'ارز مبلغ معادل',
      'Draft Equivalent Amount': 'مبلغ معادل حواله',
      Documents: 'مدارک',
      Reports: 'گزارشات',
      Settings: 'تنظیمات',
      Administrator: 'مدیر سیستم',
      Dashboard: 'داشبورد',
      Welcome: 'خوش آمدید',
      'Moneyx Accounting Panel': 'پنل حسابداری مانیکس',
      'Moneyx Exchange Accounting Software':
        'نرم افزار حسابداری حسابداری مانیکس ',
      'First Name': 'نام',
      'Middle Name': 'میانی',
      'Last Name': 'نام خانوادگی',
      Gender: 'جنسیت',
      Undefined: 'نامعلوم',
      Unknown: 'نامعلوم',
      Known: 'معلوم',
      Male: 'مرد',
      Female: 'زن',
      'Phone Number': 'شماره تماس',
      'Language Selection': 'انتخاب زبان',
      'Transfer Owners': 'حواله داران',
      Description: 'توضیحات',
      Return: 'بازگشت',
      'Register New Customer': 'ثبت مشتری جدید',
      'Basic Information': 'اطلاعات اولیه',
      BranchBasicInformation: 'اطلاعات اولیه نمایندگی',
      CustomerBasicInformation: 'اطلاعات اولیه مشتری',
      Colon: ':',
      'Account Number': 'شماره حساب',
      'Add Account': 'افزودن حساب',
      AddNewAccount: 'افزودن حساب جدید',
      AddNewBank: 'افزودن بانک جدید',
      AddBankAccount: 'افزودن حساب بانکی',
      'Occupation And Personal Details': 'اطلاعات فردی و شغلی',
      'Add Address': 'افزودن آدرس',
      AddNewAddress: 'اضافه کردن آدرس جدید',
      SubmitNewAddress: 'ثبت آدرس جدید',
      SubmitNewPhoneNumber: 'ثبت شماره جدید',
      AddNewPaymentPlace: 'افزودن محل پرداخت جدید',
      PaymentPlace: 'محل پرداخت',
      AddNewBranch: 'افزودن نمایندگی جدید',
      AddNewContactDetails: 'افزودن اطلاعات تماس جدید',
      RegistrationIsDone: 'ثبت انجام شد',
      AddNewCustomer: 'Add new customer',
      'Bank Information': 'اطلاعات بانک',
      Attachment: 'ضمیمه',
      'Birth Place Country': 'کشور محل تولد',
      Nationality: 'ملیت',
      'Birth Place': 'محل تولد',
      'Birth Date': 'تاریخ تولد',
      Email: 'ایمیل',
      Website: 'وب سایت',
      Occupation: 'شغل',
      'Other Occupation': 'عنوان شغل (در صورت انتخاب سایر)',
      'Occupation Branch': 'نوع کار',
      'Economic Registering Number': 'کد اقتصادی',
      Continue: 'ادامه',
      England: 'انگلیس',
      Iranian: 'ایرانی',
      Programmer: 'برنامه نویس',
      Employee: 'کارمند',
      Country: 'کشور',
      Province: 'استان',
      City: 'شهر',
      'Zip Code': 'کد پستی',
      'Exact Address': 'نشانی دقیق',
      Tehran: 'تهران',
      London: 'لندن',
      'Currency Type': 'نوع ارز',
      'Account Owner Name': 'نام صاحب حساب',
      'Account Owner': 'صاحب حساب',
      'Total Deposit Amount': 'جمع مبلغ واریز',
      'Branch Code': 'کد شعبه',
      'Swift Code': 'کد سوئیفت',
      IBAN: 'شماره شبا',
      Register: 'ثبت',
      Rial: 'ریال',
      Pond: 'پوند',
      CEO: 'مدیرعامل',
      'Document Type': 'نوع مدرک',
      'Holder Name': 'نام دارنده',
      'Document Type2': 'نوع سند',
      'Document Number': 'شماره مدرک',
      'Document Number2': 'شماره سند',
      'Receive Document Date': 'تاریخ دریافت سند',
      'Due Date': 'تاریخ سررسید',
      'Due Date Of Document': 'تاریخ سررسید سند',
      'Expire Date': 'تاریخ انقضا',
      'Issue Date': 'تاریخ صدور',
      Profile: 'پروفایل',
      'Sign Out': 'خروج',
      'Document Status': 'وضعیت مدرک',
      Permanent: 'دائمی',
      Current: 'فعلی',
      'Type 1': 'نوع اول',
      'Type 2': 'نوع دوم',
      'Drop Your Desired File Here': 'فایل مورد نظر را اینجا رها کنید',
      Or: 'یا',
      Choose: 'انتخاب کنید',
      'Valid Formats': 'فرمت‌های مجاز',
      Application: 'اپلیکیشن',
      'Application Settings': 'تنظیمات اپلیکیشن',
      'Edit Number': 'ویرایش شماره',
      'Connected Account': 'حساب متصل',
      'Block Access To Application': 'بلاک کردن دسترسی به اپلیکیشن',
      'Send Notification Via': 'ارسال نوتیفیکیشن از طریق',
      SMS: 'پیامک',
      Telegram: 'تلگرام',
      Whatsapp: 'واتساپ',
      'Accept And Register': 'تایید و ثبت',
      Customers: 'مشتریان',
      'Customers List': 'لیست مشتریان',
      'Draft Orders List': 'لیست سفارشات حواله',
      Customer: 'مشتری',
      List: 'لیست',
      'Register Customer': 'ثبت مشتری',
      'Select Customer': 'انتخاب مشتری',
      'Sign in to your account to continue': 'بر ادامه لطفاً وارد شوید.',
      'Welcome back': 'خوش آمدید',
      None: 'هیچکدام',
      'Email Address': 'آدرس ایمیل',
      Password: 'رمز عبور',
      'Sign in': 'ورود',
      'Remember me': 'مرا به خاطر بسپار',
      'Forgot password': 'پسوردم فراموش شده!',
      Use: 'استفاده کنید',
      and: 'و',
      'to sign in': 'برای ورود.',
      Moneyx: 'مانیکس',
      'Exchange Accounting Software': 'نرم افزار حسابداری صرافی',
      Balance: 'باقی‌مانده',
      CalculationBalance: 'درحال محاسبه باقی‌مانده',
      BalanceCurrency: 'ارز باقی‌مانده',
      FinancialInformation: 'اطلاعات مالی',
      Inventory: 'موجودی',
      'Sample Balance': '5/435/000',
      'Sample Balance Currency': 'تومان',
      'Complete Customer Information': 'تکمیل اطلاعات مشتری',
      'Next Step': 'مرحله بعدی',
      Sent: 'ارسال شده',
      'Not Sent': 'ارسال نشده',
      'Customer Information': 'اطلاعات مشتری',
      All: 'همه',
      Currencies: 'ارزها',
      SelectedCurrenciesList: 'لیست ارزهای انتخاب شده',
      'Draft Submission': 'ثبت حواله',
      Branch: 'نمایندگی',
      Sender: 'فرستنده',
      Receiver: 'گیرنده',
      'Draft Currency': 'ارز حواله',
      'Draft Amount': 'مبلغ حواله',
      'Company Registration': 'ثبت شرکت',
      'Contact Details': 'اطلاعات تماس',
      Address: 'آدرس',
      Addresses: 'آدرس‌ها',
      Shareholders: 'سهام‌داران',
      'Add/Update Shareholders': 'افزودن / ویرایش سهام‌دار',
      'Receivers List': 'لیست گیرنده‌ها',
      'Receiver Registration': 'ثبت گیرنده',
      Accept: 'تایید',
      'Customer Registration': 'ثبت مشتری',
      'Search By': 'جستجو با',
      Name: 'نام',
      Comma: '،',
      'Document Prime': 'مدرک',
      DocumentName: 'نام مدرک',
      'Advanced Search': 'جستجوی پیشرفته',
      'Customer Number': 'شماره مشتری',
      'Customer Name': 'نام مشتری',
      CustomerPhoneNumber: 'شماره تلفن مشتری',
      ReceiverName: 'نام گیرنده',
      Group: 'گروه',
      Groups: 'گروه‌ها',
      'Father Name': 'نام پدر',
      'Account History': 'تاریخچه حساب',
      'Transaction History': 'تاریخچه تراکنش',
      'Selected Transaction History': 'تاریخچه تراکنش انتخاب شده',
      'Applications Settings': 'تنظیمات برنامه‌ها',
      'Draft Receivers': 'گیرنده‌های حواله',
      'Send Message': 'ارسال پیام',
      'Customer Full Details': 'اطلاعات کامل مشتری',
      'Download Customers List Summary': 'دانلود لیست با خلاصه مشتریان',
      'Download Customers Full List': 'دانلود لیست با مشخصات کامل',
      'Document Expiry Date': 'تاریخ انقضاء مدرک',
      'Total Debt In Dollars': 'مجموع بدهی به دلار',
      To: 'به',
      Select: 'انتخاب',
      Collateral: 'معرف',
      'Communication Way': 'نوع تماس با شماره',
      Enable: 'فعال‌سازی',
      Webcam: 'وبکم',
      Upload: 'آپلود',
      Photo: 'عکس',
      'Add New Phone Number': 'افزودن شماره جدید',
      'At Least One Number Is Mandatory': 'حداقل یک شماره اجباری است',
      'Phone Number Cannot Be Repeated.': 'شماره تلفن نمی تواند تکراری باشد.',
      'Register Customer Without Additional Information':
        'ثبت شخص بدون اطلاعات تکمیلی',
      'Wholesale Exchange': 'حواله‌دار عمده',
      'Retail Exchange': 'حواله‌دار خرده',
      Create: 'ایجاد',
      'System Management': 'مدیریت سامانه',
      Business: 'کسب و کار',
      'Create Group': 'ایجاد گروه',
      'System Settings': 'تنظیمات سامانه',
      'System Rate': 'نرخ سامانه',
      'Advanced Settings': 'تنظیمات پیشرفته',
      'Initial Settings': 'تنظیمات اولیه',
      'Header Settings': 'تنظیمات سربرگ',
      'Add Currency': 'افزودن ارز',
      'Update Currency': 'ویرایش ارز',
      'Upload a logo for the header': 'آپلود لوگو برای سربرگ',
      Debtor: 'بدهکار',
      DebtorSum: 'جمع بدهکار',
      CreditorSum: 'جمع طلبکار',
      'List Of Most Debtors': 'لیست بیشترین بدهکاران',
      Creditor: 'طلبکار',
      'Create Currency': 'ایجاد ارز',
      Normal: 'عادی',
      Other: 'سایر',
      Dollar: 'دلار',
      'Account Remaining': 'باقیمانده حساب',
      Remaining: 'باقیمانده',
      From: 'از',
      Date: 'تاریخ',
      'Balance Currency Type': 'نوع ارز موجودی',
      'Mobile Number': 'شماره موبایل',
      'Account Details': 'اطلاعات حساب',
      'Banks List': 'لیست بانک‌ها',
      'Banks Management': 'مدیریت بانک‌ها',
      Row: 'ردیف',
      Add: 'افزودن',
      'New Bank': 'بانک جدید',
      'Bank Name': 'نام بانک',
      'Card Number': 'شماره کارت',
      'Bank Branch Code': 'کد شعبه',
      Status: 'وضعیت',
      OverallStatus: 'وضعیت کلی',
      ChequeOverallStatus: 'وضعیت کلی چک',
      SubmitChequeInMoneyx: 'ثبت چک در سامانه مانیکس',
      DebtCollection: 'جمع بدهی',
      Active: 'فعال',
      Archived: 'بایگانی',
      'Bank Book': 'دفتر بانک',
      'No Bank Is Registered': 'هیچ بانکی ثبت نشده است',
      'No Submited Transaction': 'تراکنشی ثبت نشده است',
      Bank: 'بانک',
      Edit: 'ویرایش',
      State: 'ایالت',
      Yes: 'بله',
      IncreaseCredit: 'افزایش اعتبار',
      CancelDraft: 'لغو حواله',
      'Edit Draft': 'ویرایش حواله',
      'Edit Transaction': 'ویرایش تراکنش',
      'Edit Bank Transaction': 'ویرایش تراکنش بانکی',
      'Submit Edit': 'ثبت ویرایش',
      'Archiving The Account': 'بایگانی کردن حساب',
      'Cash withdrawal': 'پرداخت نقدی',
      'Cash deposit': 'دریافت نقدی',
      Deposit: 'دریافت',
      Withdraw: 'پرداخت',
      Deposit2: 'واریز',
      Withdraw2: 'برداشت',
      'Search In Moneyx': 'جستجو در مانیکس',
      'Quick Access': 'دسترسی سریع',
      'Print Information': 'چاپ اطلاعات',
      'Upload File': 'آپلود فایل',
      'Transaction Description': 'شرح تراکنش',
      'Receipt Number': 'شماره فیش',
      Receipt: 'فیش',
      ReceiptWithHeader: 'رسید با سربرگ',
      ReceiptWithoutHeader: 'رسید بدون سربرگ',
      'Transaction Amount': 'مبلغ تراکنش',
      'Changed Amount': 'مبلغ تغییر',
      Messages: 'پیام‌ها',
      Notifications: 'اعلان‌ها',
      Languages: 'زبان‌ها',
      'Account Balance': 'موجودی حساب',
      HasAccountInitialBalance: 'حساب موجودی اولیه دارد؟',
      IsAccountHidden: 'مخفی سازی حساب از سایر کاربران',
      'Cash Desk': 'صندوق',
      'System Accounts': 'حساب‌های سامانه',
      Cash: 'صندوق',
      'Cash Name': 'نام صندوق',
      Costs: 'هزینه‌ها',
      Commission: 'کارمزد',
      OnlyCommissions: 'فقط کارمزدها',
      OnlyTransactions: 'فقط تراکنش ها',
      WithCommission: 'دارای کارمزد',
      WithoutCommission: 'بدون کارمزد',
      creditaccount: 'حساب اعتبارات',
      UnknownCreditAccount: 'اعتبار نامعلوم',
      unknowncreditaccount: 'اعتبار نامعلوم',
      'Unexecuted draft account': 'حواله‌های اجرا نشده',
      'unexecuted draft account': 'حواله‌های اجرا نشده',
      'Account Information': 'اطلاعات حساب',
      Accounts: 'حساب‌ها',
      ChargingSale: 'فروش شارژ',
      UnrealizedDraft: 'حواله‌های اجرا نشده',
      'Unrealized Drafts': 'حواله‌های اجرا نشده',
      UnexecutedDraft: 'حواله‌های اجرا نشده',
      BankPayment: 'دریافت و پرداخت بانکی',
      'No Option Message': 'موردی وجود ندارد',
      'No Content Message': 'موردی وجود ندارد',
      'Branch Registration': 'ثبت نمایندگی',
      'Branch Edition': 'ویرایش نمایندگی',
      'Advanced Details': 'اطلاعات تکمیلی',
      'Drafts List': 'لیست حواله‌ها',
      'Draft Documents List': 'لیست اسناد حواله',
      'Unrealized Drafts List': 'لیست حواله‌های اجرا نشده',
      'Pending Drafts List': 'لیست حواله‌های در انتظار',
      'Branches List': 'لیست نمایندگی‌ها',
      'Branch List': 'لیست نمایندگی‌',
      'Trade Currency': 'خرید و فروش ارز',
      'Currencies Management': 'مدیریت ارزها',
      'Branch Account': 'حساب نمایندگی',
      'Credits Account': 'حساب اعتبارات',
      'Customer Credit Account': 'حساب اعتبار مشتری',
      'Unknown Bank Deposits': 'واریزهای نامعلوم بانکی',
      'Submit Transaction': 'ثبت تراکنش',
      'Submit Cash Transaction': 'ثبت تراکنش نقدی',
      'Submit Bank Transaction': 'ثبت تراکنش بانکی',
      'New Submit Bank Transaction': 'ثبت تراکنش بانکی (جدید)',
      SubmitInterBankTransactionSuccessfully:
        'تراکنش بین بانکی باموفقیت انجام شد.',
      'Register Person': 'ثبت شخص',
      'Edit Person': 'ویرایش شخص',
      'Edit Company': 'ویرایش شرکت',
      CommonAccount: 'حساب',
      BranchAccount: 'نمایندگی',
      BankAccount: 'بانک',
      NotHave: 'ندارد',
      'Aggregation Of Fees': 'تجمیع کارمزدها',
      SubmitNewBankAccount: 'ثبت حساب بانکی جدید',
      NewBankAccount: 'حساب بانکی جدید',
      Operation: 'عملیات',
      RepeatOperation: 'تکرار عملیات',
      'Operation Type': 'نوع عملیات',
      'Unknown Bank Transactions': 'تراکنش‌های نامعلوم بانکی',
      customer: 'مشتری',
      receiver: 'گیرنده',
      'Interbank Transaction': 'تراکنش بین بانکی',
      'Outgoing Draft Submission': 'ثبت حواله رفت',
      'Incoming Draft Submission': 'ثبت حواله آمد',
      'Miscellaneous Draft Submission': 'ثبت حواله متفرقه',
      'Miscellaneous Draft': 'حواله متفرقه',
      'Draft Conduction': 'اجرای حواله',
      'Cheques List': 'لیست چک‌ها',
      'Debtor And Creditor List': 'لیست بدهکار و طلبکار',
      'Commissions Report': 'گزارش کارمزد ها',
      'Trade Currencies List': 'لیست خرید و فروش ارز',
      SavedToCashDeskOrBankAccount: 'خوابانده شده',
      SavedToCustomerAccount: 'واگذار شده',
      HasReturned: 'برگشت خورده',
      CashedToMoney: 'پاس شده',
      'Cashed to money': 'پاس شده',
      'cashed to money': 'پاس شده',
      UnknownBankAccounts: 'تراکنش‌های نامعلوم بانکی',
      'Profit And Loss Statement': 'صورت سود و زیان',
      'Calculating Profit and Loss': 'محاسبه‌گر سود و زیان',
      'Payment Order': 'دستور پرداخت',
      profile: 'حساب کاربری',
      'Operation of the day': 'عملیات روز',
      'Financial Operation': 'عملیات مالی',
      DocumentIsPrivate: 'سند بدون ثبت توضیحات است',
      'Net Profit': 'سود خالص',
      'Gross Profit': 'سود ناخالص',
      Loss: 'ضرر',
      Cost: 'هزینه',
      Delete: 'حذف',
      'no attachments': 'ضمیمه ندارد',
      'The Entered Amount Is Bigger Than The Sum Of The Payment Order':
        'مبلغ وارد شده، از جمع مقدار مبالغ فیش بالاتر می باشد',
      OK: 'تایید',
      Cancel: 'انصراف',
      Clear: 'پاک کردن',
      'System Guides': 'راهنما کار با سامانه',
      'System Main Currency': 'ارز پیشفرض سامانه',
      'Increase Customer Credit': 'افزایش اعتبار مشتری',
      'Date And Time': 'تاریخ و زمان',
      AllEmployees: 'همه کارمندان',
      ViewGroup: 'مشاهده گروه',
      DataEntry: 'ورود اطلاعات',
      HaveAccount: 'دارای حساب',
      BranchStatus: 'وضعیت نمایندگی',
      DraftDescription: 'شرح حواله',
      'Credit Currency': 'ارز اعتبار',
      'Credit Amount': 'مبلغ اعتبار',
      'Transaction Information': 'اطلاعات تراکنش',
      'Credit Registration': 'ثبت اعتبار',
      'Reported Bugs': 'خطاهای گزارش شده',
      'Excel Data Output': 'خروجی اکسل اطلاعات',
      'Bug Details': 'جزئیات خطا',
      'My Tickets': 'تیکت‌های من',
      'From Date': 'از تاریخ',
      'To Date': 'تا تاریخ',
      Failed: 'رد شده',
      Pend: 'در انتظار',
      Success: 'تایید شده',
      'Request Status': 'وضعیت درخواست',
      'Pending Transactions List': 'لیست تراکنش‌های در انتظار',
      'Submitted By Employee': 'ثبت شده توسط کارمند',
      AllCashDesks: 'همه صندوق‌ها',
      SelectDocument: 'انتخاب سند',
      SelectDocumentAndSubmit: 'سند مورد نظر را انتخاب و سپس تایید را بزنید.',
      'Credits on Da Afghanistan Bank': 'طلبات بالای ده افغانستان بانک',
      UseWebcam: 'از وبکم استفاده کنید (CTRL + k)',
      PaymentType: 'نوع پرداخت',
      RemainingCashAccount: 'مانده حساب صندوق',
      ShowCashAccountBalanceMsg:
        'پس از انتخاب صندوق، مانده حساب صندوق به تفکیک نمایش داده می‌شود.',
      PrivateCashTransaction: 'تراکنش نقدی محرمانه باشد.',
      'Submitted By Customer': 'ثبت شده توسط مشتری',
      'Submitted By Mirror Branch': 'ثبت شده توسط نمایندگی مقابل',
      Number: 'شماره',
      numbers: 'عدد',
      'Total Numbers': 'تعداد کل',
      Detail: 'جزئیات',
      ShowDetails: 'نمایش جزئیات',
      ShowBrief: 'نمایش مختصر',
      SeeDetails: 'مشاهده جزئیات',
      'From Number': 'از شماره',
      'To Number': 'تا شماره',
      'From Amount': 'از مبلغ',
      'To Amount': 'تا مبلغ',
      Submit: 'تایید',
      SubmitTransfer: 'ثبت انتقال',
      Reject: 'رد',
      'Error Number': 'شماره خطا',
      'Error Title': 'عنوان خطا',
      'Error Opening Date': 'تاریخ باز شدن خطا',
      'Error Closing Date': 'تاریخ بسته شدن خطا',
      'Error Status': 'وضعیت خطا',
      'Remove Filters': 'حذف فیلترها',
      'Creditor Customer': 'مشتری طلبکار',
      'Debtor Customer': 'مشتری بدهکار',
      Recieve: 'دریافت',
      'Bank Transaction Is Confidential': 'تراکنش بانکی بدون ثبت توضیحات باشد',
      'Transaction Currency': 'ارز تراکنش',
      'Commission Amount': 'مبلغ کارمزد',
      'Commission Creditor Amount': 'مبلغ کارمزد طلبکار',
      'Is There A Fee For This Transaction': 'این تراکنش کارمزد دارد؟',
      UncertainCreditLimit:
        'در صورت مشخص نکردن سقف اعتبار، سقف اعتبار صفر در نظر گرفته می‌شود',
      Incoming: 'آمد',
      Outgoing: 'رفت',
      'Account Name': 'نام حساب',
      'Account Type': 'نوع حساب',
      'Employee Name': 'نام کارمند',
      Type: 'نوع',
      'Visit Account': 'مشاهده حساب',
      VisitCustomerAccount: 'مشاهده حساب مشتری',
      VisitCashAccount: 'مشاهده صندوق',
      VisitBankAccount: 'مشاهده حساب بانک',
      visitCustomerCreditAccount: 'مشاهده حساب اعتبار مشتری',
      VisitUnknownBankTransactionsAccount:
        'مشاهده حساب تراکنش‌های نامعلوم بانکی',
      VisitDestinationBankBook: 'مشاهده دفتر بانک مقصد',
      VisitSourceBankBook: 'مشاهده دفتر بانک مبدا',
      DestinationBranchAccount: 'حساب نمایندگی مقصد',
      SourceBranchAccount: 'حساب نمایندگی مبدا',
      'Private Print': 'چاپ بدون ثبت توضیحات',
      'Operation Date': 'تاریخ عملیات',
      Private: 'بدون ثبت توضیحات باشه',
      'Not Private': 'بدون ثبت توضیحات نباشه',
      'Not Change The Credit Limit': 'سقف اعتبار تغییر نکنه',
      'Change The Credit Limit': 'سقف اعتبار تغییر میکنه',
      'Currency Trade Rate': 'نرخ تبدیل ارز',
      SubmitConversion: 'ثبت تبدیل',
      'Opening Account In Australia Bank': 'افتتاح حساب در بانک استرالیا',
      'See Australia Bank Account': 'مشاهده حساب بانک استرالیا',
      Manual: 'دستی',
      SystemLanguage: 'زبان سامانه',
      PresentNumbers: 'نمایش اعداد',
      FontSize: 'اندازه فونت',
      Small: 'کوچک',
      Medium: 'متوسط',
      Large: 'بزرگ',
      DeleteTransactionMsg: 'تراکنش با موفقیت حذف شد',
      AccountStatusMessage:
        'پس از انتخاب مشتری، مانده حساب مشتری نمایش داده می‌شود.',
      // ************************ end default document types **************************
      // ************************ phrase items translations **************************
      'transfer between accounts': 'انتقال بین حساب',
      'transaction id': 'شماره تراکنش',
      'transaction id:': 'شماره تراکنش:',
      'transfer between accounts with': 'انتقال بین حساب با',
      amount: 'مبلغ',
      currency: 'ارز',
      currencyCode: 'کد ارز',
      CurrencyRate: 'نرخ ارز',
      DecimalPlacesCalculation: 'به چند رقم اعشار محاسبه شود؟',
      CalculationType: 'نوع محاسبه',
      benchMarkCurrency: 'ارز معیار',
      'Bank Currency': 'ارز بانک',
      'Account Status': 'وضعیت حساب',
      debtor: 'بدهکار',
      creditor: 'طلبکار',
      Percent: 'درصد',
      Liquidity: 'نقدینگی',
      Today: 'در تاریخ روز',
      'Until Today': 'تا تاریخ روز',
      'outgoing draft order': 'حواله رفت',
      'incoming draft order': 'حواله آمد',
      IncomingDrafts: 'حواله‌های آمد',
      OutgoingDrafts: 'حواله‌های رفت',
      UnexecutedDrafts: 'حواله‌های اجرا نشده',
      ExecutedDrafts: 'حواله‌های اجرا شده',
      AllDrafts: 'تمام حواله‌ها',
      'Update Check Rate': 'ویرایش نرخ حواله',
      'Add Payment Place': 'افزودن محل پرداخت',
      'Payment Place': 'محل پرداخت',
      to: 'به',
      from: 'از',
      at: 'در',
      'was registered.': 'ثبت شد.',
      'commission for': 'کارمزد',
      'bank account': 'حساب بانک',
      'customer account': 'حساب مشتری',
      'sell currency': 'فروش ارز',
      'buy currency': 'خرید ارز',
      'bank transaction': 'تراکنش بانکی',
      CustomerAccountCode: 'کد حساب مشتری',
      commission: 'کارمزد',
      'cash transaction': 'تراکنش نقدی',
      unrealizeddraft: 'حواله‌های اجرا نشده',
      unexecuteddraft: 'حواله‌های اجرا نشده',
      unknownbankaccounts: 'تراکنش‌های نامعلوم بانکی',
      chargingsale: 'فروش شارژ',
      'buy charge': 'خرید شارژ',
      cash: 'صندوق',
      costs: 'هزینه‌ها',
      Documentations: 'مستندات',
      Others: 'متفرقه',
      'Undo Cancellation': 'بازگرداندن لغو',
      'New Version Available Phrase':
        'نسخه جدید سامانه در دسترس است ؛ لطفا صفحه را بروزرسانی کنید',
      OnlyWithAccountTitle: 'فقط با عنوان حساب',
      // ************************ end phrase items translations **************************
      // ***********************************
      // countries
      // ***********************************
      Afghanistan: 'افغانستان',
      Australia: 'استرالیا',
      China: 'چین',
      Denmark: 'دانمارک',
      Germany: 'آلمان',
      Greece: 'یونان',
      Iran: 'ایران',
      Iraq: 'عراق',
      Norway: 'نروژ',
      Pakistan: 'پاکستان',
      'Saudi Arabia': 'عربستان سعودی',
      Sweden: 'سوئد',
      Turkey: 'ترکیه',
      'United Arab Emirates': 'امارات متحده عربی',
      Baghdad: 'بغداد',
      Ankara: 'انکارا',
      Kabul: 'کابل',
      'Islamabad, Karachi': 'کراچی ,اسلام آباد',
      // ***********************************
      // end countries
      // ***********************************
      // ***********************************
      // actionTypes
      // ***********************************
      TransferBetweenTwoAccounts: 'انتقال بین حساب',
      Cheque: 'چک',
      ChequeStatus: 'وضعیت چک',
      ChequeCurrentStatus: 'وضعیت فعلی چک',
      ConsideringCheque: 'با احتساب چک',
      CashTransaction: 'تراکنش نقدی',
      IncomingDraft: 'حواله آمد',
      OutgoingDraft: 'حواله رفت',
      Miscellaneous: 'متفرقه',
      BuySellCurrency: 'خرید و فروش ارز',
      BankTransaction: 'تراکنش بانکی',
      RemoveCheckout: 'حذف تسویه',
      PendDraft: 'حواله در انتظار',
      RemovePendDraft: 'حذف حواله در انتظار',
      ExecuteDraft: 'اجرای حواله',
      InterBankTransaction: 'تراکنش بین بانکی',
      Checkout: 'تسویه',
      PaymentOrderReceipt: 'رسید دستور پرداخت',
      ChangeBranch: 'تبدیل نرخ',
      UnknownBankTransaction: 'تراکنش نامعلوم بانکی',
      UpdateAccount: 'ویرایش حساب',
      DeleteAccount: 'حذف حساب',
      AddBank: 'افزودن بانک',
      UpdateBank: 'ویرایش بانک',
      DeleteBank: 'حذف بانک',
      AddCustomer: 'افزودن مشتری',
      UpdateCustomer: 'ویرایش مشتری',
      DeleteCustomer: 'حذف مشتری',
      AddCompany: 'افزودن شرکت',
      UpdateCompany: 'ویرایش شرکت',
      DeleteCompany: 'حذف شرکت',
      AddBranch: 'افزودن نمایندگی',
      UpdateBranch: 'ویرایش نمایندگی',
      DeleteBranch: 'حذف نمایندگی',
      ConnectToBranch: 'اتصال به نمایندگی',
      DisconnectFromBranch: 'قطع اتصال',
      OneWayConnecting: 'اتصال یک طرفه',
      BranchName: 'نام نمایندگی',
      BranchManager: 'مسئول نمایندگی',
      BranchIsNotFound: 'نمایندگی یافت نشد',
      SelecTheDesiredBranch: 'نمایندگی مورد نظر را انتخاب کنید',
      InOneWayConnectionModeTheCounterpartyIsNotAblleToSeeYoureTransactions:
        'در حالت اتصال یک طرفه نمایندگی مقابل قادر به مشاهده تراکنش های شما نیست',
      AddCurrency: 'افزودن ارز',
      UpdateCurrency: 'ویرایش ارز',
      DeleteCurrency: 'حذف ارز',
      AddPayOrder: 'افزودن دستور پرداخت',
      UpdatePayOrder: 'ویرایش دستور پرداخت',
      DeletePayOrder: 'حذف دستور پرداخت',
      PublishTransactionInWhatsapp: 'اعلان تراکنش در واتساپ',
      TransactionBeWithoutDescription: 'تراکنش بدون ثبت توضیحات باشد',
      CashTransactionWithoutDescription: 'تراکنش نقدی بدون ثبت توضیحات باشد.',
      SyncCreditLimit: 'همگام سازی سقف اعتبار',
      ShouldHaveSamevaluein: 'باید دارای مقادیر یکسان باشند در',
      'In progress': 'در حال پردازش',
      'private transaction': 'تراکنش بدون ثبت توضیحات باشد',
      'Invalid date': 'تاریخ نامعتبر است',
      'Required field message': 'پرکردن این فیلد اجباری است',
      uploaderInputLabelMaximumSize: 'حجم فایل حداکثر باید ۳مگابایت باشد',
      DocumentsModalAcceptFormats: 'فرمت آن باید JPG, PNG, PDF, xlsx باشد.',
      ExcelUploaderInputLabelFormat: 'فرمت آن باید xlsx باشد.',
      FileUploaderInputLabelFormat:
        'فرمت آن باید xls, xlsx, pdf, png, jpg, jpeg, doc, docx, webp باشد.',
      WhatsAppCommunication: 'لینک یا آدرس گروه واتساپ',
      DocumentTypeDescription: 'توضیحات نوع مدرک',
      CommissionAmountRegisteredToCreditor:
        'مقدار کارمزد به حساب طلبکار ثبت خواهد شد.',
      CommissionAmountRegisteredToDebtor:
        'مقدار کارمزد به حساب بدهکار ثبت خواهد شد.',
      'The commission amount must be less than the transaction amount':
        'مقدار کارمزد باید کمتر از مقدار تراکنش باشد.',
      SelectItemToAutoCalculate: 'کدام آیتم به صورت خودکار محاسبه شود؟',
      'Value must be greater than zero': 'مقدار باید بزرگتر از صفر باشد',
      'File required': 'افزودن فایل اجباری است',
      'no future dates message': 'تاریخ نمی‌تواند بزرگتر از تاریخ امروز باشد',
      'The email is invalid': 'ایمیل نامعتبر است',
      'Invalid website message': 'آدرس وب‌سایت نامعتبر است',
      'Number message': 'فقط استفاده از اعداد مجاز است',
      'Existed username message': 'نام کاربری تکراری می‌باشد',
      'Numbers and English letters message':
        'فقط عدد و حروف کوچک و بزرگ انگلیسی معتبر است',
      'Capital English letters message': 'فقط حروف بزرگ انگلیسی معتبر است',
      'Uncheck message': 'در صورت عدم ثبت اطلاعات، تیک را بردارید',
      'Cheque date message': 'تاریخ سررسید نمی‌تواند از تاریخ چک کوچکتر باشد',
      'Iban message':
        'کد شبا باید دقیقا 26 کاراکتر باشد و فقط اعداد و حروف کوچک و بزرگ انگلیسی معتبر است',
      'Card number message': 'شماره کارت باید تنها عدد و شامل 16 رقم باشد',
      'Other occupation message':
        'هنگامی که شغل، سایر انتخاب می‌شود، پر کردن این فیلد الزامی است',
      'Customer age limit message': 'سن مشتری باید بیشتر از ۱۰سال باشد',
      'Issue date message': 'تاریخ صدور نمی‌تواند از تاریخ امروز بزرگتر باشد',
      'Expire date message': 'تاریخ انقضا باید از تاریخ صدور بزرگتر باشد',
      'Phone number minimum length message':
        'طول شماره تلفن باید بزرگتر از 9 کاراکتر باشد',
      'Phone number maximum length message':
        'طول شماره تلفن باید کوچکتر از 15 کاراکتر باشد',
      'Phone number start message': 'شماره تلفن نباید با صفر شروع شود',
      'Max number message': 'عدد باید بین صفر تا صد باشد',
      'Invalid trade rate': 'نرخ در بازه‌ی معتبر نیست',
      'Required currency': 'انتخاب ارز الزامی است',
      InsertResidenceNumberMessage:
        'شماره اقامت را از بخش ویرایش مشتری در تکست فیلد توضیحات نوع مدرک وارد نمایید.',
      HasUserName: 'نام کاربری دارد',
      InstantAccountAddition: 'افزودن فوری حساب',
      PersianUserNameError: 'نام کاربری نباید فارسی باشد',
      DuplicatedPhoneNumberError: 'این شماره تلفن قبلا ثبت شده است',
      RegisterCompanyWithoutAdditionalInformation:
        'ثبت شرکت بدون اطلاعات تکمیلی',
      SaveInformation: 'آیا اطلاعات ذخیره شود؟',
      AddNewDocument: 'افزودن مدرک جدید',
      SelectAsActiveDocument: 'انتخاب به عنوان مدرک فعال',
      SelectAsMainPhoneNumber: 'انتخاب به عنوان شماره اصلی',
      SelectAsActiveAddress: 'انتخاب به عنوان آدرس فعال',
      ChangeActiveDocumentSuccessfully: 'مدرک فعال باموفقیت تغییر کرد.',
      SubmitNewDocument: 'ثبت مدرک جدید',
      ContinueWithoutSubmitDocument: 'ادامه بدون ثبت مدرک',
      SelectedReferenceDocument: 'مدرک مرجع انتخاب شده',
      SelectAsReferenceDocument: 'انتخاب این مدرک به عنوان مدرک مرجع',
      ContinueWithoutSubmitAccount: 'ادامه بدون ثبت حساب',
      ContinueWithoutSubmitBankInformation: 'ادامه بدون  بانکی',
      SubmitNewAccount: 'ثبت حساب جدید',
      'Continue without submit attachment': 'ادامه بدون ثبت ضمیمه',
      'Submit new attachment': 'ثبت ضمیمه جدید',
      AddNewAttachment: 'افزودن ضمیمه جدید',
      NoShareholders: 'هیچ سهام‌داری برای شرکت انتخاب نشده است.',
      NoBalanceIsThere: 'هیچ موجودی ثبت نشده است.',
      SelectShareholder: 'همین الان سهام‌داران را انتخاب کنید. ',
      AddToShareholdersList: 'افزودن به لیست سهام‌داران',
      DeleteUnexecutedDraftTitle: 'آیا از حذف حواله اجرانشده اطمینان دارید؟',
      DeleteDraftTitle: 'آیا از حذف حواله اطمینان دارید؟',
      DeleteBankAccountTitle: 'آیا از حذف حساب بانک اطمینان دارید؟',
      DeleteDraftOrderTitle: 'آیا از حذف سفارش حواله اطمینان دارید؟',
      DeleteTradeCurrencyTitle: 'آیا از حذف خرید و فروش ارز اطمینان دارید؟',
      DeleteSystemAccountTitle: 'آیا از حذف حساب سامانه اطمینان دارید؟',
      DeleteReceiverTitle: 'آیا از حذف دریافت کننده اطمینان دارید؟',
      DeleteDocumentTitle: 'آیا از حذف مدرک اطمینان دارید؟',
      DeleteAddressTitle: 'آیا از حذف آدرس اطمینان دارید؟',
      DeleteAttachmentTitle: 'آیا از حذف ضمیمه اطمینان دارید؟',
      DeletePaymentOrderTitle: 'آیا از حذف دستور پرداخت اطمینان دارید؟',
      DeleteCustomerTitle: 'آیا از حذف مشتری اطمینان دارید؟',
      DeleteAccountTitle: 'آیا از حذف حساب اطمینان دارید؟',
      DeleteCurrencyTitle: 'آیا از حذف ارز اطمینان دارید؟',
      DeleteShareholderTitle: 'آیا از حذف سهامدار اطمینان دارید؟',
      DeleteTransactionTitle: 'آیا از حذف سند اطمینان دارید؟',
      DeleteChequeTitle: 'آیا از حذف چک اطمینان دارید؟',
      DeleteHistoryTitle: 'آیا از حذف تاریخچه اطمینان دارید؟',
      DeletePaymentPlaceTitle: 'آیا از حذف محل پرداخت اطمینان دارید؟',
      DeleteBranchTitle: 'آیا از حذف حساب نمایندگی اطمینان دارید؟',
      DeleteCurrencyCalculationTitle: 'آیا از حذف محاسبات اطمینان دارید؟',
      DeletePersonTitle: 'آیا از حذف شخص اطمینان دارید؟',
      DeleteDraftDocumentTitle: 'آیا از حذف سند حواله اطمینان دارید؟',
      DeleteTransaction2Title: 'آیا از حذف تراکنش اطمینان دارید؟',
      NoSubmittedDraft: 'حواله‌ای ثبت نشده.',
      NoSubmittedBranch: 'نمایندگی ثبت نشده.',
      NoSubmittedReceiver: 'گیرنده ثبت نشده.',
      RejectPendingDraftTitle: 'آیا از رد حواله اطمینان دارید؟',
      UndoDraftCancellationTitle: 'آیا از بازگرداندن لغو حواله اطمینان دارید؟',
      CancelDraftSuccessfully: 'حواله با موفقیت بازگردانی شد.',
      NoSubmittedPaymentOrder: 'دستور پرداخت ثبت نشده.',
      NoSubmittedCustomer: 'مشتری ثبت نشده.',
      NoSubmittedCheckout: 'تسویه ثبت نشده.',
      NoSubmittedCheckout2: 'تسویه‌ای ثبت نشده است',
      NoSubmittedDocument: 'سندی ثبت نشده.',
      NoSubmittedDocument2: 'مدرکی ثبت نشده.',
      NoSubmittedOperation: 'عملیاتی انجام نشده!',
      NoSubmittedTicket: 'تیکتی ثبت نشده',
      DraftRegistrationSuccessfully: 'حواله با موفقیت ثبت شد.',
      EditDraftSuccessfully: 'حواله با موفقیت ویرایش شد.',
      IncreaseCustomerCredit: 'افزایش اعتبار مشتری باموفقیت انجام شد.',
      DraftNumberSettingsSuccessfully: 'تنظیمات شماره حواله باموفقیت انجام شد.',
      EditBranchSuccessfully: 'ویرایش نمایندگی باموفقیت انجام شد.',
      EditPaymentPlaceSuccessfully: 'ویرایش محل پرداخت باموفقیت انجام شد.',
      EditPersonSuccessfully: 'ویرایش شخص باموفقیت انجام شد',
      RegisterPaymentPlaceSuccessfully: 'ثبت محل پرداخت باموفقیت انجام شد.',
      RegisterPersonSuccessfully: 'ثبت مدرک باموفقیت انجام شد.',
      IncreaseOrganizationSuccessfully:
        'افزایش اعتبار سازمان باموفقیت انجام شد.',
      DeleteOrderDraftSuccessfully: 'سفارش حواله با موفقیت حذف شد',
      DeleteDraftSuccessfully: 'حواله باموفقیت حذف شد',
      DeleteTransactionSuccessfully: 'تراکنش باموفقیت حذف شد',
      ExportExcelSuccessfully: 'خروجی اکسل با موفقیت دانلود شد',
      ExportExcelAttention:
        'توجه داشته باشید که برای دریافت فایل اکسل باید تمامی گروه های پیشنهادی را در تنظیمات سامانه خود اضافه کرده باشید!',
      AddThisGroupError: 'لطفا گروه زیر را در تنظیمات سامانه خود اضافه کنید',
      ExportExcelAccountGroupsError:
        'متاسفانه دانلود انجام نشد. لطفا بعدا دوباره تلاش کنید',
      DeleteChequeSuccessfully: 'چک باموفقیت حذف شد',
      DeleteSystemAccountSuccessfully: 'حساب سامانه باموفقیت حذف شد',
      DeleteReceiverSuccessfully: 'حذف گیرنده باموفقیت انجام شد',
      DeleteDocumentSuccessfully: 'مدرک با موفقیت حذف شد.',
      DeleteAttachmentSuccessfully: 'ضمیمه باموفقیت حذف شد.',
      DeleteBankInformationSuccessfully: 'اطلاعات بانکی باموفقیت حذف شد.',
      DeleteAddressSuccessfully: 'آدرس باموفقیت حذف شد.',
      DeleteCustomerSuccessfully: 'حذف شخص باموفقیت انجام شد.',
      DeleteAccountSuccessfully: 'حساب باموفقیت حذف شد.',
      DeleteSuccessfully: 'حذف باموفقیت انجام شد',
      DeleteCurrencySuccessfully: 'ارز باموفقیت انجام شد',
      DeleteShareholderSuccessfully: 'سهامدار باموفقیت حذف شد.',
      DeletePaymentPlaceSuccessfully: 'محل پرداخت باموفقیت حذف شد.',
      DeleteBranchSuccessfully: 'نمایندگی باموفقیت حذف شد.',
      DeleteBankAccountSuccessfully: 'حساب بانکی باموفقیت حذف شد.',
      RejectPendingDraftSuccessfully: 'حواله باموفقیت رد شد.',
      SelectCheckoutDate: 'تاریخ تسویه مورد نظر را انتخاب کنید.',
      DeleteLastCheckoutText:
        'برای حذف ، صرفا آخرین تسویه مشتری را میتوانید حذف کنید.',
      DeleteLastBranchCheckoutText:
        'برای حذف، صرفا آخرین تسویه نمایندگی را می‌توانید حذف کنید.',
      DisconnectBranchTitle: 'آیا از قطع اتصال نمایندگی اطمینان دارید؟',
      DisconnectBranchSuccessfully: 'قطع اتصال نمایندگی باموفقیت انجام شد.',
      DeletePhoneNumberSuccessfully: 'شماره با موفقیت حذف شد.',
      SubmitDocSuccessfully: 'سند با موفقیت ثبت شد.',
      ShareReceiptText:
        'می‌توانید رسید تراکنش را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareInterBankTransactionReceiptText:
        'می‌توانید رسید تراکنش بین بانکی را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareTradeCurrencyTransactionReceiptText:
        'می‌توانید رسید خرید ارز را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareSellCurrencyTransactionReceiptText:
        'می‌توانید رسید فروش ارز را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareSubmitTransactionReceiptText:
        'می‌توانید رسید سند را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareExecuteDraftReceiptText:
        'می‌توانید رسید اجرای حواله را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareDraftReceiptText:
        'می‌توانید رسید حواله را از طریق راه‌های زیر به اشتراک بگذارید:',
      PrintReceiptWithHeader: 'چاپ رسید با سربرگ',
      PrintReceiptWithoutHeader: 'چاپ رسید بدون سربرگ',
      NotActiveSystemError: 'سامانه برای شما فعال نیست!',
      ExpiredSubscriptionError: 'زمان اشتراک شما به پایان رسیده است!',
      ReActiveAccountTitle: 'برای فعال‌سازی مجدد دکمه خرید نسخه را کلیک کنید.',
      buyVersionTitle: 'برای استفاده از سامانه آریانا ابتدا نسخه خریداری کنید.',
      UseDesktopVersionTitle:
        'جهت فعال کردن این امکان، از نسخه دسکتاپ نرم‌افزار استفاده کنید.',
      NoActiveMobile: 'حساب شماره موبایل فعالی ندارد.',
      SendAccountBalanceSuccessfully: 'باقیمانده حساب باموفقیت ارسال شد.',
      SendToBranch: 'ارسال به نمایندگی',
      SentToWhatsapp: 'ارسال به واتساپ',
      AutomaticSendToWhatsapp: 'ارسال اتوماتیک به واتساپ',
      SendManuallyToWhatsApp: 'ارسال دستی به واتساپ',
      DepositAmountsDivision: 'تقسیم مبالغ واریز',
      SelectBankInformation: 'انتخاب اطلاعات بانکی',
      'Cash desk cant be creditor': 'صندوق نمی‌تواند طلبکار باشد.',
      'What is private transaction?': 'تراکنش محرمانه چیست؟',
      'Submit unknown bank deposit': 'ثبت واریز نامعلوم بانکی',
      SelectRowAlert: 'ابتدا یک ردیف را انتخاب کنید.',
      Copied: 'کپی شد.',
      SpecifyDraftType: 'نوع حواله را مشخص کنید.',
      TransactionRegistrationDetails: 'مشخصات ثبت تراکنش',
      SubmitBugReportSuccessfully:
        'گزارش خطا باموفقیت ثبت شد و در اسرع وقت پاسخ داده خواهد شد.',
      RegisterBankAccountSuccessfully: 'بانک باموفقیت ایجاد شد.',
      RegisterNewFilesSuccessfully: 'سندهای جدید باموفقیت ثبت شدند.',
      RegisterBranchSuccessfully: 'نمایندگی باموفقیت ثبت شد.',
      EditAdditionalInfoSuccessfully: 'اطلاعات تکمیلی باموفقیت ویرایش شد.',
      SubmitAdditionalInfoSuccessfully: 'اطلاعات تکمیلی باموفقیت ثبت شد.',
      SubmitBuyCurrencySuccessfully: 'خرید ارز باموفقیت ثبت شد.',
      changeActivePhoneNumberSuccessfully: 'شماره فعال باموفقیت تغییر کرد.',
      CreateAccountSuccessfully: 'حساب با موفقیت ایجاد شد.',
      EditAccountSuccessfully: 'حساب باموفقیت ویرایش شد.',
      AddCustomersSuccessfully: 'مشتریان باموفقیت افزوده شدند.',
      ExecuteDraftSuccessfully: 'حواله باموفقیت اجرا شد.',
      ChangeActiveAddressSuccessfully: 'آدرس فعال باموفقیت انجام شد.',
      ShowMore: 'نمایش بیشتر',
      ShowLess: 'نمایش کمتر',
      DraftCommission: 'کارمزد حواله',
      DraftCommissionAmount: 'مبلغ کارمزد حواله',
      BranchShouldBeSelected: 'نمایندگی باید انتخاب شود.',
      ReceivedCurrency: 'ارز دریافتی',
      ConvertCurrencyRate: 'نرخ تبدیل ارز',
      'Draft Equivalent Currency': 'ارز معادل حواله',
      'Submit Common Commission': 'ثبت کارمزد مشترک',
      CheckRateTime: 'اعتبار نرخ حواله به پایان رسیده است.',
      BankAccountBalance: 'مانده حساب بانک',
      BuyerBankAccountBalance: 'مانده حساب بانک خریدار',
      SelerBankAccountBalance: 'مانده حساب بانک فروشنده',
      NumberOfPaymentPlaces: 'تعداد محل های پرداخت',
      // end actionTypes
      // ***********************************
      Row3: '3\u200E ردیف',
      Row4: '4\u200E ردیف',
      Row5: '5\u200E ردیف',
      Row6: '6\u200E ردیف',
      Row10: '10\u200E ردیف',
      Row15: '15\u200E ردیف',
      Row20: '20\u200E ردیف',
      Row25: '25\u200E ردیف',
      Row50: '50\u200E ردیف',
      Row100: '100\u200E ردیف',
      Row150: '150\u200E ردیف',
      Row200: '200\u200E ردیف',
      Row250: '250\u200E ردیف',
      Dari: 'دری',
      Pashto: 'پشتو',
      Like: 'مثلا',
      Monthly: 'ماهانه',
      Yearly: 'سالانه',
      Quarterly: 'ربع وار',
      SpecifyTypesOfIdentityDocuments: 'انواع مدرک هویتی را مشخص کنید',
      IssuingOrganization: 'ارگان صادرکننده',
      Light: 'روشن',
      Dark: 'تیره',
      CheckoutAccount: 'تسویه',
      AccountDetails: 'جزئیات حساب',
      Copy: 'کپی',
      AUnexecutedDraft: 'حواله اجرا نشده',
      TransactionDate: 'تاریخ سند',
      TransactionRow: 'ردیف سند',
      openingBalance: 'موجودی اولیه',
      SubmitNewCheckout: 'ثبت تسویه حساب جدید',
      BalanceAmount: 'مقدار موجودی',
      OperationHistory: 'تاریخچه عملیات',
      Unlimited: 'نامحدود',
      Share: 'اشتراک‌گذاری',
      Highlight: 'هایلایت',
      RemoveHighlight: 'حذف هایلایت',
      History: 'تاریخچه',
      HighlightedTransactions: 'تراکنش‌های هایلایت شده',
      ShowHighlightedTransactions: 'نمایش تراکنش‌های هایلایت شده',
      LastTransaction: 'آخرین تراکنش',
      Exchange: 'صرافی',
      RemainingAccount: 'مانده حساب',
      BranchAddress: 'آدرس نمایندگی',
      BranchPhone: 'تلفن نمایندگی',
      Rate: 'نرخ',
      BankWithdraw: 'برداشت بانکی',
      BankDeposit: 'Bank deposit',
      SyncBalanceAndCreditLimit: 'همگام سازی موجودی با سقف اعتبار',
      AmountAccountWhatsappReport:
        'بدینوسیله تایید می گردد که جمع مانده حساب شما به صورت زیر می باشد :',
      ChequeAssigner: 'واگذارنده چک',
      ChequeReceiver: 'گیرنده چک',
      ChequePaymentPlace: 'محل پرداخت چک',
      'Visit Cheque owner account': 'مشاهده حساب صاحب چک',
      Received: 'دریافتی',
      Paid: 'پرداختی',
      Category: 'دسته‌بندی',
      Branch2: 'شعبه',
      CustomerAccountRemaining: 'مانده حساب مشتری',
      VisitBuyerAccount: 'مشاهده حساب خریدار',
      VisitSellerAccount: 'مشاهده حساب فروشنده',
      EditOutgoingDraft: 'ویرایش حواله رفت',
      EditIncomingDraft: 'ویرایش حواله آمد',
      CancelIncomingDraft: 'لغو حواله آمد',
      CancelOutgoingDraft: 'لغو حواله رفت',
      ReceiveDraftAmount: 'دریافت مبلغ حواله',
      VisitCreditorAccount: 'مشاهده حساب طلبکار',
      VisitDebtorAccount: 'مشاهده حساب بدهکار',
      AccountType: 'نوع حساب',
      CustomerType: 'نوع مشتری',
      RealCustomer: 'حقیقی',
      LegalCustomer: 'حقوقی',
      TradeRate: 'نرخ روز',
      SubmitSellCurrencySuccessfully: 'فروش ارز باموفقیت ثبت شد.',
      CashDesk: 'حساب صندوق',
      General: 'عمومی',
      Banking: 'بانکی',
      AccountManagement: 'مدیریت حساب',
      ReceiveDate: 'تاریخ دریافت',
      NormalBranches: 'نمایندگی های عادی',
      ConnectedBranches: 'نمایندگی های متصل',
      ChequeNumber: 'شماره چک',
      ChequeBalance: 'باقیمانده چک',
      'Total balance of accounts': 'تراز کل حساب‌ها',
      CheckoutDate: 'تاریخ تسویه',
      Deactivated: 'غیرفعال',
      In: 'در',
      SubmitConnection: 'تایید اتصال',
      AddAdditionalInformation: 'افزودن اطلاعات تکمیلی',
      BranchCurrency: 'ارز نمایندگی',
      NationalCode: 'کد ملی',
      ConnectBranchText:
        'برای اتصال لینک زیر را را برای نمایندگی ارسال کنید.(ترجیحا ایمیل شود)',
      LinkValidityText: ' اعتبار لینک زیر 10 دقیقه است',
      SureAboutOneWayConnection: 'آیا از اتصال یک طرفه مطمعن هستید؟',
      AllowedDraftLimit: 'سقف مجاز حواله',
      MirrorBranchAccount: 'حساب نمایندگی مقابل',
      DraftNumberSettings: 'تنظیمات شماره حواله',
      View: 'مشاهده',
      OtherNumbers: 'شماره های دیگر',
      SelectGroup: 'انتخاب گروه',
      DraftRow: 'ردیف حواله',
      DraftType: 'نوع حواله',
      BusinessName: 'نام تجاری',
      'With commission only': 'فقط دارای کارمزد',
      User: 'کاربر',
      Time: 'زمان',
      explanation: 'شرح',
      City2: 'شهرستان',
      ReceivedAmount: 'مبلغ دریافتی',
      DraftNumberAlert:
        'فیلد تا شماره حواله باید بزرگتر از فیلد از شماره حواله باشد.',
      FilterBasedOnDate: 'فیلتر براساس تاریخ',
      FilterBasedOnDraftNumber: 'فیلتر براساس شماره حواله',
      VisitAccountInMirrorBranch: 'مشاهده حساب در نمایندگی مقابل',
      Canceled: 'لغوشده',
      Canceller: 'لغوکننده',
      AllPaymentPlaces: 'همه محل پرداخت ها',
      DraftSubmissionSettings: 'تنظیمات ثبت حواله',
      StatusBySelectedFiltersAlert: 'وضعیت براساس فیلترهای انتخابی',
      OldBankTransaction: 'تراکنش بانکی قدیمی',
      DraftConductionEditPersonAlert:
        'مشاهده مدارک و ویرایش گیرنده تنها در صورتی که گیرنده دستی ثبت نشده باشد، ممکن است.',
      AccountOperation: 'عملیات شخص',
      SelectDraftCustomer: 'انتخاب مشتری حواله',
      AddPerson: 'افزودن شخص',
      NewBankTransaction: 'تراکنش بانکی جدید',
      selectTransactionType: 'نوع تراکنش را مشخص کنید.',
      ViewDocuments: 'نمایش مدارک',
      DraftInformation: 'اطلاعات حواله',
      ApplicationUsername: 'نام کاربری اپلیکیشن',
      OutgoingNumber: 'شماره رفت',
      IncomingNumber: 'شماره آمد',
      DeleteDocument: 'حذف مدرک',
      DeleteReceiver: 'حذف گیرنده',
      New: 'جدید',
      Old: 'قدیم',
      CashAccount: 'حساب نقدی',
      SameCurrencyAlert: 'ارز تراکنشهای انتخاب شده باید یکی باشد.',
      ReceiveDraftDate: 'تاریخ دریافت حواله',
      TheWayOfTransaction: 'نحوه انجام تراکنش به چه شکل باشد؟',
      PrivateDraftConduction: 'اجرای حواله محرمانه باشد',
      SelectUnexecutedDraftAlert:
        'لطفا ابتدا حواله اجرا نشده مورد نظر خود را انتخاب کنید',
      AllBranches: 'همه نمایندگی‌ها',
      BaseOnAmount: 'مبلغ محور باشه',
      BaseOnRate: 'نرخ‌محور باشه',
      RegistrationCode: 'کد ثبت',
      Email2: 'پست الکترونیکی',
      IssuerName: 'نام صادرکننده',
      IssuerDescription: 'توضیحات صادرکننده',
      OccupationTitle: 'عنوان کار',
      RegistrationDate: 'تاریخ ثبت',
      BankInformation: 'اطلاعات بانکی',
      'Customer ID': 'شناسه مشتری',
      ViewMoreInformation: 'مشاهده اطلاعات بیشتر',
      SystemNewFeatures: 'ویژگی‌های جدید سامانه',
      CommunicationType: 'نوع ارتباط',
      Close: 'بستن',
      Next: 'بعدی',
      SelectedActiveDocument: 'مدرک فعال انتخاب شده',
      SelectedActiveNumber: 'شماره فعال انتخاب شده',
      ContinueWithoutSubmitNumber: 'ادامه بدون ثبت شماره',
      ViewLessInformation: 'مشاهده کمتر اطلاعات',
      SelectAsTheActiveNumber: 'انتخاب به عنوان شماره فعال',
      AddContactInfo: 'افزودن اطلاعات تماس',
      SelectAsCurrentNumber: 'انتخاب به عنوان شماره فعلی',
      AddDocument: 'افزودن مدرک',
      IndividualJobInformation: 'اطلاعات فردی شغلی',
      AccountCode: 'کد حساب',
      PersonalInformation: 'اطلاعات فردی',
      CountryCode: 'کد کشور',
      InputAmount: 'مبلغ ورودی',
      ExcelFile: 'فایل اکسل',
      ExcelFileFormat: 'فرمت فایل اکسل',
      SaderatBank: 'بانک صادرات',
      OldFormat: 'فرمت قدیم',
      NewFormat: 'فرمت جدید',
      SepahBank: 'بانک سپه',
      UserName: 'نام کاربری',
      UploadPhoto: 'آپلود عکس',
      MaknakUsername: 'نام کاربری مکنک',
      Language: 'زبان',
      ActivateTheCamera: 'فعال‌سازی دوربین',
      StandardFormat: 'فرمت استاندارد',
      AddAttachment: 'افزودن ضمیمه',
      PunishmentsList: 'لیست تعزیرات',
      DuplicateFileNameAlert: 'فایل با نام مشابه همواره وجود دارد',
      SearchResult: 'نتیجه جستجو',
      InputCurrency: 'ارز ورودی',
      Position: 'سمت',
      shareRate: 'مقدار سهام',
      SubmitShareholder: 'ذخیره سهامدار',
      AddShareholder: 'افزودن سهامدار',
      SubmitShareholder2: 'ثبت سهامدار',
      CustomerSearch: 'جستجو مشتری',
      '404 error': 'خطای 404 - صفحه یافت نشد',
      AddToShareholders: 'افزودن به سهامداران',
      PageNotFount: 'صفحه یافت نشد.',
      PassportNumber: 'شماره گذرنامه',
      ResidenceNumber: 'شماره اقامت',
      PageNotAvailable: 'صفحه جستجو شده موجود نیست.',
      'Is the Maknak software active for the user or not?':
        'آیا نرم افزار مکنک برای کاربر فعال باشد یا نه؟',
      HasApplicationUserName: 'نام کاربری اپلیکیشن دارد',
      'Edit Occupation And Personal Details': 'ویرایش اطلاعات فردی و شغلی',
      BirthCity: 'شهر تولد',
      MainDocument: 'مدرک اصلی',
      ServiceType: 'نوع خدمات',
      CompanyType: 'نوع شرکت',
      TakePicture: 'گرفتن عکس',
      CompanyName: 'نام شرکت',
      OrganizationNumber: 'شماره تجاری',
      RegistrationNumber: 'شماره ثبت',
      OriginCountry: 'کشور مبدا',
      PaymentOrderBalance: 'باقی مانده دستور پرداخت',
      DebtorInformation: 'اطلاعات بدهکار',
      ConfirmDraftTitle: 'آیا از تایید حواله های انتخاب شده اطمینان دارید؟',
      PureProfit: 'سود خالص',
      uploadFileWithTouch: 'برای بارگذاری فایل، لمس کنید',
      SubmitedDocumentsCount: 'تعداد اسناد ثبت‌شده',
      Profit: 'سود',
      CalculatedProfitAmount: 'میزان سود محاسبه شده',
      CalculatedLossAmount: 'میزان ضرر محاسبه شده',
      Lossment: 'زیان',
      RejectDraft: 'رد حواله',
      RejectDraftReason: 'دلیل رد حواله',
      RejectDraftReasonMessage: 'علت رد حواله را بنویسید.',
      OriginCurrency: 'ارز مبدا',
      DestinationCurrency: 'ارز مقصد',
      SubmittedBy: 'ثبت شده توسط',
      CreditorInformation: 'اطلاعات طلبکار',
      PreventionCalculations: 'محاسبات پیشنهادی',
      ComputingManagement: 'مدیریت محاسبات',
      GrindingRate: 'نرخ نقدشوندگی',
      InvalidRate: 'نرخ در بازه معتبر نیست.',
      CalculateProfit: 'محاسبه سود',
      BuyAmount: 'قیمت خرید',
      BuyCurrency: 'ارز خرید',
      TotalPurchase: 'جمع کل خرید',
      CreditCollection: 'جمع طلب',
      ExecutePaymentOrder: 'اجرای دستور پرداخت',
      PaymentOrderDetails: 'جزئیات دستور پرداخت',
      PrincipalAmount: 'مبلغ اصلی',
      CardSender: 'ارسال کننده کارت',
      CurrencyAmount: 'ارز مبلغ',
      ExecuteTransaction: 'اجرای تراکنش',
      Creditors: 'طلبکاران',
      Deleted: 'حذف شده',
      Deposited: 'واریز شده',
      VisitDeposited: 'مشاهده واریز شده',
      AllCheques: 'همه چک‌ها',
      HasAssignorCommission: 'این تراکنش برای واگذارنده کارمزد دارد؟',
      HasReceiverCommission: 'این تراکنش برای تحویل گیرنده کارمزد دارد؟',
      SubmitOperation: 'ثبت عملیات',
      ChequeOperation: 'عملیات چک',
      AllOptions: 'همه موارد',
      ReceivedFrom: 'دریافت شده از',
      FilteredBy: 'فیلتر براساس',
      AllDocs: 'همه سندها',
      TransactionTypeAmount: 'باقی مانده نوع تراکنش',
      VisitCreditors: 'مشاهده طلبکاران',
      NoState: 'وضعیتی موجود نیست!',
      Card: 'کارت',
      SellCurrency: 'ارز فروش',
      MirrorBranch: 'نمایندگی مقابل',
      BankDescription: 'توضیحات بانک',
      DeterminingDraftRateValidityTime: 'تعیین زمان اعتبار نرخ حواله',
      DraftRateValidityDateTime: 'تاریخ و ساعت اعتبار نرخ حواله',
      DollarRate: 'نرخ دلار',
      IncreaseCurrencyDecimalPlaceWarning:
        'کاهش دفت رعم اعشار ارز، موجب اختلال در ویرایش تراکنش‌هایی که با این ارز انجام شده‌اند خواهد شد.',
      RateValidityTime: 'زمان اعتبار نرخ حواله',
      SystemDefaultCurrency: 'ارز پیشفرض سامانه',
      SelectAsSystemDefaultCurrency: 'انتخاب به عنوان ارز پیشفرض سامانه',
      NoSelectedCurrency: 'هیچ ارزی برای نمایش انتخاب نشده است.',
      DecimalPlace: 'رقم اعشار',
      AllCurrencies: 'همه ارزها',
      SubmitNewBank: 'ثبت بانک جدید',
      SubmitCheque: 'ثبت چک',
      SubmitPaymentOrder: 'ثبت سفارش پرداخت',
      CommissionDetails: 'جزئیات کارمزد',
      Multiplication: 'ضرب',
      Division: 'تقسیم',
      RoundationAccuracy: 'میزان دقت رندشوندگی',
      Solar: 'خورشیدی',
      DateType: 'نوع تاریخ',
      gregorian: 'میلادی',
      MoneyxSupport: 'پشتیبان ساپورت',
      AnswerText: 'متن پاسخ',
      ExchangeSettingsTitle:
        'اطلاعات این صفحات مربوط به تنظیمات صرافی شما می باشد.',
      CompletionFormsRequired: '(تکمیل فرم‌ها الزامی است)',
      SystemColorMode: 'حالت نمایش رنگ سامانه',
      SubmitYourGroups: 'گروه‌های مدنظر خود را ثبت کنید.',
      ReceiveDocFromCustomerTitle:
        '(اگر نیازمند دریافت مدارک از مشتری نمی باشید از این قسمت عبور کنید.)',
      'Organizations List': 'لیست سازمان‌ها',
      currencyName: 'نام ارز',
      ViewCurrency: 'نمایش ارز',
      WhatsappActiveDefault:
        'به صورت پیشفرض اعلان واتس اپ در تراکنش ها فعال باشد؟',
      CurrencySymbol: 'نماد ارز',
      MyAccounts: 'حساب های من',
      TimeZone: 'منطقه زمانی',
      ProfitAndLossRate: 'نرخ سود و زیان',
      PercentageChange: 'تغییر درصد',
      LongTimeWarning: 'این فرایند ممکن است کمی زمان‌بر باشد',
      DefaultPhoneNumberCode: 'پیش شماره پیش فرض',
      Slogan: 'شعار',
      ReceiptRules: 'قوانین رسید',
      AddRule: 'افزودن قانون',
      AddSameGroupsError: 'امکان ایجاد گروه‌های مشابه وجود ندارد',
      DocumentTitleError: 'عنوان مدرک نمی‌تواند خالی باشد',
      IssuingOrganizationError: 'The issuing organization cannot be empty',
      CreateSameDocumentError: 'امکان ایجاد مدرک یکسان وجود ندارد',
      CharacterLimit: 'حداکثر ۱۰۰ کاراکتر',
      HeaderUploaderTitle: 'طول و عرض تصویر سربرگ باید یکسان باشد.',
      IncreaseCreditLimitDescription:
        '(در صورت فعال بودن همگام سازی هر زمان سقف اعتبار مشتری ناکافی باشد به میزان مبلغ تراکنش سقف اعتبار مشتری افزایش می‌یابد.)',
      profitAndLossAccuracy: 'درصد حساسیت سود و زیان',
      DisplayDeviation:
        'درصد میزان حساسیت سامانه برای نمایش مغایرت محاسبات را مشخص کنید.',
      CashDeskName: 'اسم حساب صندوق',
      CostAccountName: 'اسم حساب هزینه',
      SubmitYourCurrencies: 'ارزهای مورد استفاده خود را ثبت کنید.',
      DeterminingDraftRate: 'تعیین نرخ حواله',
      DraftCurrencyOrder: 'ارز سفارش حواله',
      AddPaymentPlace: 'افزودن محل پرداخت',
      NoCommissions: 'کارمزدی وجود ندارد.',
      ViewHeader: 'مشاهده سربرگ',
      Capitals: 'دارایی ها',
      ReportPeriod: 'تناوب گزارش دهی',
      ExchangeName: 'نام شرکت صرافی',
      CurrentCapitals: 'دارایی های جاری',
      ConnectAccountToEquivalentAccount: 'اتصال حساب به حساب معادل',
      PrePays: 'پیش پرداخت ها',
      PrePurchaseContract: 'قراردادهای پیش خرید',
      TotalValueOfOtherAssets: 'ارزش مجموعی سایر دارایی ها',
      FixedAssets: 'دارایی‌های ثابت',
      TangibleAssets: 'دارایی های ملموس',
      ReductionInDepreciationOfTangibleAssets:
        'کاهش استهلاکات دارایی‌های ملموس',
      ReductionInDepreciationOfIntangibleAssets:
        'کاهش استهلاکات دارایی‌های غیر ملموس',
      IntangibleAssets: 'دارایی های غیر ملموس',
      Debts: 'بدهی ها',
      CurrentDebts: 'بدهی‌های جاری',
      OtherDebts: 'سایر بدهی‌ها',
      PreSaleContracts: 'قراردادهای پیش فروش',
      PayableBills: 'بل های قابل پرداخت',
      PayableTax: 'مالیات قابل پرداخت',
      OperativeExpenses: 'مصارف عملیاتی',
      TotalShareholdersEquity: 'مجموع سرمایه سهم شرکاء',
      OtherRevenue: 'سایر عواید',
      AdministrativeExpenses: 'مصارف اداری',
      depreciationExpenses: 'استهلاکات',
      telephoneExpenses: 'مصارف تیلیفون/ مکالماتی',
      rentalExpenses: 'مصارف کرایه',
      insuranceExpenses: 'بیمه',
      licensingExpenses: 'مصارف اخذ جواز',
      employeeExpenses: 'مصارف کارمندان(معاشات و سایر امتیازات)',
      otherExpenses: 'سایر مصارف',
      ReceiveExcelFile: 'دریافت فایل اکسل',
      TotalDataOutput: 'خروجی کل اطلاعات',
      SelectedDataOutput: 'خروجی اطلاعات انتخابی',
      ExitFromSystem: 'خروج از برنامه',
      PersonProfile: 'پروفایل شخص',
      ReceiveTotalDataExcel: 'دریافت اکسل کل اطلاعات',
      DataOutPutOneTimeMessage:
        'انجام این عملیات یک بار در روز امکان پذیر می باشد.',
      AutoDownloadTheExcelFile:
        'فایل اکسل خروجی، پس از آماده سازی به صورت خودکار دانلود خواهد شد.',
      ExcelFileDownloaded: 'فایل اکسل خروجی باموفقیت دانلود شد.',
      ExcelFileDownloadFailed: 'دانلود فایل اکسل با خطا مواجه شد.',
      ReceiveTotalDataExcelFile: 'دریافت اکسل کل اطلاعات',
      UserAccountSystem: 'سامانه حساب کاربری',
      GetScreenShout: 'اسکرین شات از صفحه',
      AddFile: 'در صورت لزوم فایل اضافه کنید.',
      ErrorPage: 'صفحه خطا',
      chargingSystem: 'سامانه شارژ',
      AccountingSystem: 'سامانه حسابداری',
      Asset: 'سرمایه',
      WelcomeMessage: '!به پنل حساب داری مانیکس خوش آمدید',
      PoliticalFighter: 'متبارز سیاسی',
      HighRisk: 'پرخطر',
      ModerateRisk: 'خطر متوسط',
      LowRisk: 'کم‌خطر',
      SmallBusiness: 'کسب و کار کوچک',
      BigBusiness: 'کسب و کار بزرگ',
      CurrencyAuction: 'لیلام اسعار',
      SanctionList: 'تطبیق لیست تعزیرات',
      'Central analysis of trades and financial reports':
        'مرکز تحلیل معاملات و راپورهای مالی',
      'Exchange license validation': 'اعتبارسنجی جواز',
      EnterExchangeNameMessage: 'لطفا نام صرافی خود را وارد کنید',
      'Exchange Name': 'نام صرافی',
      Is: 'میباشد',
      IsNot: 'نمیباشد',
      ShareholdersList: 'لیست سهامداران',
      EditShareholder: 'ویرایش سهامدار',
      Attachments: 'ضمیمه‌ها',
      HasCustomer: 'مشتری دارد',
      Limited: 'محدود',
      MainPhoneNumber: 'شماره اصلی',
      syncCreditLimitActive: 'همگام سازی سقف اعبتبار فعال باشد؟',
      Displacement: 'جابه‌جایی',
      Done: 'انجام شده',
      'Under Review': 'درحال بررسی',
      registerTransactionGroups: 'ثبت گروه برای تراکنش ها',
      registerAccountsGroups: 'ثبت گروه برای حساب ها',
      'Real customer': 'مشتری حقیقی',
      'Legal customer': 'مشتری حقوقی',
      'Number of Rows': 'تعداد',
      SubmitPaymentPlace: 'ثبت محل پرداخت',
      EditPaymentPlace: 'ویرایش محل پرداخت',
      PaymentPlaceName: 'نام محل پرداخت',
      agentName: 'نام مسئول',
      Transactions: 'تراکنش‌ها',
      TotalCustomerCreditStatus: 'وضعیت کل اعتبار مشتری',
      FromDraftNumber: 'از شماره حواله',
      ToDraftNumber: 'تا شماره حواله',
      'Settled documents': 'اسناد تسویه‌شده',
      // ***********************************
      // ***********************************
      // ***********************************
      // ***********************************
      // ***********************************
      // ***********************************translated ***********************************
      CanceledDrafts: 'حواله‌های لغوشده',
      Count: 'تعداد',
      'Checkout History': 'تاریخچه تسویه',
      'Last Checkout': 'آخرین تسویه',
      'Cheque History': 'تاریخچه چک',
      'Upload Excel File': 'آپلود فایل اکسل',
      'Information Output': 'خروجی اطلاعات',
      'Normal Print': 'چاپ عادی',
      PrivatePrint: 'چاپ محرمانه',
      'Date Of Payment Order': 'تاریخ دستور پرداخت',
      'Transaction Date': 'تاریخ تراکنش',
      'Transaction Number': 'شماره تراکنش',
      'Buy Currency': 'خرید ارز',
      'Sell Currency': 'فروش ارز',
      'Buy Commission': 'کارمزد خرید',
      'Sell Commission': 'کارمزد فروش',
      '(Optional)': '(اختیاری)',
      'Buy Currency Setting': 'تنظیمات خرید ارز',
      'Sell Currency Setting': 'تنظیمات فروش ارز',
      'Upload Documents': 'آپلود مدارک',
      'Confirm Buy': 'ثبت خرید',
      'Confirm Sell': 'ثبت فروش',
      'Trade Rate': 'نرخ تبدیل',
      Convert: 'تبدیل',
      'Buy Amount': 'مبلغ خرید',
      'Sell Amount': 'مبلغ فروش',
      'Seller Commission': 'کارمزد فروشنده',
      'Buyer Commission': 'کارمزد خریدار',
      Buyer: 'خریدار',
      Seller: 'فروشنده',
      'Draft Number': 'شماره حواله',
      'Incoming Draft Number': 'شماره حواله آمد',
      'Outgoing Draft Number': 'شماره حواله رفت',
      Confirm: 'تایید',
      'Draft Cancellation': 'لغو حواله',
      'Transaction Type': 'نوع تراکنش',
      System: 'سامانه',
      'System Account': 'حساب سامانه',
      'Customer Commission': 'کارمزد مشتری',
      'Branch Commission': 'کارمزد نمایندگی',
      'Transaction Status': 'وضعیت تراکنش',
      'Settings And File Upload': 'تنظیمات و آپلود فایل',
      'Other Number': 'شماره متفرقه',
      'Debtor Commission': 'کارمزد بدهکار',
      'Creditor Commission': 'کارمزد طلبکار',
      'Debtor Account': 'حساب بدهکار',
      'Creditor Account': 'حساب طلبکار',
      'Commission Currency': 'ارز کارمزد',
      'Edit Miscellaneous Draft': 'ویرایش حواله متفرقه',
      'Spot Rate': 'نرخ لحظه‌ای',
      'Source Of Money': 'منبع پول',
      'Purpose Of Exchange': 'علت معامله',
      'Periodic Profit Chart': 'نمودار دوره ای سود',
      'Daily Transactions Chart': 'نمودار تراکنش های روزانه',
      'Organization Chart': 'چارت سازمانی',
      'Draft List': 'لیست حواله',
      RegisterTransactionMsg: 'تراکنش با موفقیت ثبت شد',
      'Birth Certificate': 'شناسنامه',
      'National Card': 'کارت ملی',
      Passport: 'گذرنامه',
      'Physical Tezkire': 'تذکره کاغذی',
      'Electronic Tezkire': 'تذکره برقی',
      'Kimlik Card': 'کارت کیملیک',
      'Driving License': 'گواهینامه',
      'Temporary Residence Card Of Foreign Nationals': 'کارت آمایش',
      Police: 'پلیس',
      'Civil Registry Office': 'اداره ثبت احوال',
      'Immigration Office': 'اداره مهاجرت',
      'Live Currency Rate': 'نرخ لحظه ای ارز',
      'Due to the long number of letters of the selected document information, it is not possible to share.':
        'نظر به طولانی بودن تعداد حروف اطلاعات سند انتخابی، امکان اشتراک گذاری فراهم نیست.',
      draftStatusMessage:
        'پس از انتخاب حساب و ارز حواله، مانده حساب نمایش داده می‌شود',
      accountStatusMessage2: 'پس از انتخاب حساب، مانده حساب نمایش داده می‌شود',
      bankStatusMessage: 'پس از انتخاب بانک، موجودی بانک نمایش داده می‌شود',
      DestinationBankStatusMessage:
        'پس از انتخاب بانک مقصد، مانده بانک نمایش داده میشود.',
      SourseBankStatusMessage:
        'پس از انتخاب بانک مبدا، مانده حساب بانک نمایش داده میشود.',
      bankStatusMessage2:
        'پس از انتخاب بانک، مانده حساب بانک نمایش داده می‌شود',
      positiveTradeRate: 'نرخ تبدیل باید بیشتر از صفر باشد.',
      invalidTradeRate: 'نرخ تبدیل معتبر نیست',
      'Bank withdrawal': 'برداشت از بانک',
      'Bank deposit': 'واریز به بانک',
      accountStatusMessage:
        'پس از انتخاب حساب و ارز خرید، مانده حساب نمایش داده می‌شود',
      'Connected With Data': 'متصل با داده',
      'Retry (Application Is Not Running)':
        'تلاش مجدد (برنامه در حال اجرا نیست)',
      'Connected Without Data': 'متصل بدون داده',
      'Synchronization Done': 'همگام سازی انجام شد',
      'Sync Account Title':
        'آیا از  همگام سازی  سقف اعتبار مشتریان اطمینان دارید؟',
      customerStatus: 'وضعیت مشتری',
      Prepayments: 'پیش پرداخت ها',
      AdvancePurchaseContracts: 'قرارداد های پیش خرید',
      Assets: 'دارایی ها',
      ReductionInTangibleAssets: 'کاهش استهلاکات دارایی های ملموس',
      DepreciationOfIntangibleAssets: 'کاهش استهلاکات دارایی های غیر ملموس',
      PayableTaxes: 'مالیات قابل پرداخت',
      AdvanceSalesContracts: 'قرارداد های پیش فروش',
      TelephoneAndCommunicationExpenses: 'مصارف تیلیفون و مکالماتی',
      RentExpenses: 'مصارف کرایه',
      Insurance: 'بیمه',
      Partners: 'شرکا',
      LicensingExpenses: 'مصارف اخذ جواز',
      EmployeeSalaries: 'معاشات کارمندان',
      ClaimsOnCentralBank: 'طلبات بالای ده افغانستان بانک',
      Vendors: 'دست فروشان',
      Permanent1: 'دایمی',
      Temporary: 'موقت',
      'Draft Settlement': 'تعیین و تکلیف حواله',
      AdditionalInformation: 'اطلاعات تکمیلی',
      SubmitInformation: 'ثبت اطلاعات',
      'No information has been recorded': 'اطلاعاتی ثبت نشده است',
      SubmitAdditionalInformation: 'ثبت اطلاعات تکمیلی',
      editAdditionalInformation: 'ویرایش اطلاعات تکمیلی',
      branchTitle: 'عنوان  نمایندگی',
      EditedSuccessfully: 'ویرایش  انجام شد',
      EditBasicInformation: 'ویرایش اطلاعات اولیه',
      ThePlaceOfPaymentIsNotRegistered: 'محل پرداختی ثبت نشده است',
      RegisterPaymentPlace: 'ثبت محل پرداخت'
    },
    dir: 'rtl'
  },
  fad: {
    translation: {
      dir: 'rtl',
      'Display reports up to': 'زمان آخرین معامله:',
      'Show more documents': 'نمایش بیشتر مدارک',
      Show: 'نمایش',
      Search: 'جستجو',
      ChangedStatus: 'وضعیت تغییر',
      Pages: 'صفحات',
      Charge: 'شارژ',
      ChooseAccountType: 'نوع حساب را انتخاب کنید',
      RegistrationIsDone: 'ثبت انجام شد',
      Alerts: 'هشدار ها',
      Alert: 'هشدار',
      Transaction: 'معامله',
      'Number Of Transactions': 'تعداد معامله های انجام شده',
      'Accounting Panel': 'صفحه حسابداری',
      Update: 'بروزرسانی',
      'Your Credit': 'اعتبار شما',
      'Add Ticket': 'ثبت تیکت',
      TicketTitle: 'عنوان تیکت',
      'General Ledger': 'دفتر کل',
      'Journal Entries': 'روزنامچه',
      'Cash Transaction': 'معاملات نقدی',
      'Bank Transaction': 'معاملات بانکی',
      'Transfer Between Accounts': 'انتقال بین حساب',
      'Foreign Exchange': 'تبادله اسعار',
      'Sample Person': 'معین ربطی',
      'Credit Limit': 'سقف اعتبار',
      'Account Title': 'عنوان حساب',
      'Account Registration': 'افتتاح حساب',
      NumberOfPaymentPlaces: 'تعداد محل  های پرداخت',
      ThePlaceOfPaymentIsNotRegistered: 'محل پرداختی ثبت نشده است',
      'Create Customer': 'ایجاد مشتری',
      'Site under construction or maintenance': 'سایت در حال ساخت است ...',
      'building not finished yet': 'محتوای این قسمت هنوز آماده نیست :)',
      VisitCustomerAccount: 'مشاهده حساب مشتری',
      VisitCashAccount: 'مشاهده صندوق',
      VisitBankAccount: 'مشاهده حساب بانک',
      visitCustomerCreditAccount: 'مشاهده حساب اعتبار مشتری',
      VisitUnknownBankTransactionsAccount:
        'مشاهده حساب تراکنش‌های نامعلوم بانکی',
      VisitDestinationBankBook: 'مشاهده دفتر بانک مقصد',
      VisitSourceBankBook: 'مشاهده دفتر بانک مبدا',
      DestinationBranchAccount: 'حساب نمایندگی مقصد',
      SourceBranchAccount: 'حساب نمایندگی مبدا',
      And: 'و',
      English: 'انگلیسی',
      Persian: 'فارسی',
      Title: 'عنوان',
      Account: 'حساب',
      Drafts: 'حواله‌ها',
      Draft: 'حواله',
      Equal: 'معادل',
      'Equivalent Amount': 'مبلغ معادل',
      'Equivalent Amount Currency': 'ارز مبلغ معادل',
      'Draft Equivalent Amount': 'مبلغ معادل حواله',
      Documents: 'مدارک',
      Reports: 'گزارشات',
      Settings: 'تنظیمات',
      Administrator: 'مدیر سیستم',
      Dashboard: 'داشبورد',
      Welcome: 'خوش آمدید',
      'Moneyx Accounting Panel': 'صفحه حسابداری مانیکس',
      'Moneyx Exchange Accounting Software': 'نرم افزار حسابداری مانیکس ',
      'First Name': 'نام',
      'Middle Name': 'لقب',
      'Last Name': 'تخلص',
      Gender: 'جنسیت',
      Undefined: 'تعریف ناشده',
      Unknown: 'نامعلوم',
      Known: 'معلوم',
      Male: 'مرد',
      Female: 'زن',
      'Phone Number': 'شماره تماس',
      'Language Selection': 'انتخاب زبان',
      'Transfer Owners': 'حواله داران', // comment required
      Description: 'توضیحات',
      Return: 'بازگشت',
      'Register New Customer': 'ثبت مشتری جدید',
      'Basic Information': 'اطلاعات اولیه',
      BranchBasicInformation: 'اطلاعات اولیه نمایندگی',
      CustomerBasicInformation: 'اطلاعات اولیه مشتری',
      Colon: ':',
      'Account Number': 'شماره حساب',
      'Add Account': 'افزودن حساب',
      AddNewAccount: 'افزودن حساب جدید',
      AddNewBank: 'افزودن بانک جدید',
      AddBankAccount: 'افزودن حساب بانکی',
      'Add New Account': 'افزودن حساب جدید',
      'Occupation And Personal Details': 'اطلاعات فردی و شغلی',
      'Add Address': 'افزودن آدرس',
      AddNewAddress: 'اضافه کردن آدرس جدید',
      SubmitNewAddress: 'ثبت آدرس جدید',
      SubmitNewPhoneNumber: 'ثبت شماره جدید',
      AddNewPaymentPlace: 'افزودن محل پرداخت جدید',
      AddNewBranch: 'افزودن نمایندگی جدید',
      AddNewContactDetails: 'افزودن اطلاعات تماس جدید',
      AddNewCustomer: 'Add new customer',
      'Bank Information': 'اطلاعات بانک',
      Attachment: 'ضمیمه',
      'Birth Place Country': 'محل تولد (کشور)',
      Nationality: 'ملیت',
      'Birth Place': 'محل تولد',
      'Birth Date': 'تاریخ تولد',
      Email: 'ایمیل',
      Website: 'وب سایت',
      Occupation: 'شغل',
      'Other Occupation': 'شغل دیگر',
      'Occupation Branch': 'شعبه شغلی',
      'Economic Registering Number': 'کد اقتصادی',
      Continue: 'ادامه',
      England: 'انگلیس',
      Iranian: 'ایرانی',
      Programmer: 'برنامه نویس',
      Employee: 'کارمند',
      Country: 'کشور',
      Province: 'ولایت',
      City: 'شهر',
      'Zip Code': 'کد پستی',
      'Exact Address': 'آدرس دقیق',
      Tehran: 'تهران',
      London: 'لندن',
      'Currency Type': 'نوع ارز',
      'Account Owner Name': 'نام صاحب حساب',
      'Branch Code': 'کد شعبه',
      'Swift Code': 'کد سوئیفت',
      IBAN: 'شماره شبا',
      Register: 'ثبت',
      Rial: 'ریال',
      Pond: 'پوند',
      CEO: 'مدیرعامل',
      'Document Type': 'نوع مدرک',
      'Holder Name': 'نام دارنده',
      'Document Type2': 'نوع سند',
      'Document Number': 'شماره مدرک',
      'Document Number2': 'شماره سند',
      'Receive Document Date': 'تاریخ دریافت سند',
      'Due Date': 'تاریخ سررسید',
      'Due Date Of Document': 'تاریخ سررسید سند',
      'Expire Date': 'تاریخ انقضا',
      'Issue Date': 'تاریخ صدور',
      Profile: 'پروفایل',
      'Sign Out': 'خروج',
      'Document Status': 'وضعیت مدرک',
      Permanent: 'دائمی',
      Current: 'فعلی',
      'Type 1': 'نوع اول',
      'Type 2': 'نوع دوم',
      'Drop Your Desired File Here': 'فایل مورد نظر را اینجا رها کنید',
      RegisterTransactionMsg: 'معامله با موفقیت ثبت شد',
      DeleteTransactionMsg: 'معامله با موفقیت حذف شد',
      Or: 'یا',
      Choose: 'انتخاب کنید',
      'Valid Formats': 'فرمت‌های مجاز',
      Application: 'برنامه',
      'Application Settings': 'تنظیمات برنامه',
      'Edit Number': 'ویرایش شماره',
      'Connected Account': 'حساب متصل',
      'Block Access To Application': 'عدم دسترسی به برنامه',
      'Send Notification Via': 'ارسال اطلاعیه از طریق',
      SMS: 'پیامک',
      Telegram: 'تلگرام',
      Whatsapp: 'واتساپ',
      'Accept And Register': 'تایید و ثبت',
      Customers: 'مشتریان',
      'Customers List': 'فهرست مشتریان',
      'Draft Orders List': 'فهرست سفارشات حواله',
      Customer: 'مشتری',
      List: 'فهرست',
      'Register Customer': 'ثبت مشتری',
      'Select Customer': 'انتخاب مشتری',
      'Sign in to your account to continue':
        'برای ادامه لطفا وارد حساب تان شوید',
      'Welcome back': 'خوش آمدید',
      None: 'هیچکدام',
      'Email Address': 'ایمیل آدرس',
      Password: 'رمز عبور',
      'Sign in': 'ورود',
      'Remember me': 'مرا به خاطر بسپار',
      'Forgot password': 'رمز عبور تان را فراموش کرده اید!',
      Use: 'استفاده کنید',
      and: 'و',
      'to sign in': 'برای ورود.',
      Moneyx: 'مانیکس',
      'Exchange Accounting Software': 'نرم افزار حسابداری صرافی',
      Balance: 'بیلانس',
      CalculationBalance: 'درحال محاسبه باقی‌مانده',
      BalanceCurrency: 'ارز بیلانس',
      FinancialInformation: 'اطلاعات مالی',
      Inventory: 'موجودی',
      'Sample Balance': '5/435/000',
      'Sample Balance Currency': 'افغانی',
      'Complete Customer Information': 'تکمیل اطلاعات مشتری',
      'Next Step': 'مرحله بعدی',
      Sent: 'ارسال',
      'Not Sent': 'ارسال نشده',
      'Customer Information': 'اطلاعات مشتری',
      PaymentPlace: 'محل پرداخت',
      All: 'همه',
      Currencies: 'ارزها',
      SelectedCurrenciesList: 'لیست ارزهای انتخاب شده',
      'Draft Submission': 'ثبت حواله',
      Branch: 'نمایندگی',
      Sender: 'فرستنده',
      Receiver: 'گیرنده',
      'Confirm Buy': 'ثبت خرید',
      'Confirm Sell': 'ثبت فروش',
      'Trade Rate': 'نرخ تبدیل',
      'Currency Trade Rate': 'نرخ تبدیل ارز',
      Convert: 'تبدیل',
      SubmitConversion: 'ثبت تبدیل',
      'Sell Amount': 'مبلغ فروش',
      'Buy Amount': 'مبلغ خرید',
      'Draft Currency': 'ارز حواله',
      'Draft Amount': 'مبلغ حواله',
      'Company Registration': 'ثبت شرکت',
      'Contact Details': 'اطلاعات تماس',
      Address: 'آدرس',
      Addresses: 'آدرس‌ها',
      Shareholders: 'سهام‌داران',
      'Add/Update Shareholders': 'افزودن / ویرایش سهام‌دار',
      'Receivers List': 'فهرست گیرنده‌ها',
      'Receiver Registration': 'ثبت گیرنده',
      Accept: 'تایید',
      'Customer Registration': 'ثبت مشتری',
      'Search By': 'جستجو با',
      Name: 'نام',
      Comma: '،',
      'Document Prime': 'مدرک',
      DocumentName: 'نام مدرک',
      'Advanced Search': 'جستجوی پیشرفته',
      'Customer Number': 'شماره مشتری',
      'Customer Name': 'نام مشتری',
      CustomerPhoneNumber: 'شماره تلفن مشتری',
      ReceiverName: 'نام گیرنده',
      Group: 'گروه',
      Groups: 'گروه‌ها',
      'Father Name': 'نام پدر',
      'Account History': 'تاریخچه حساب',
      'Transaction History': 'تاریخچه معامله',
      'Selected Transaction History': 'تاریخچه معامله انتخاب شده',
      'Applications Settings': 'تنظیمات برنامه‌ها',
      'Draft Receivers': 'گیرنده‌های حواله',
      'Send Message': 'ارسال پیام',
      'Customer Full Details': 'اطلاعات کامل مشتری',
      'Download Customers List Summary': 'دانلود فهرست با خلاصه مشتریان',
      'Download Customers Full List': 'دانلود فهرست با مشخصات کامل',
      'Document Expiry Date': 'تاریخ انقضاء مدرک',
      'Total Debt In Dollars': 'مجموع بدهی به دلار',
      'Buy Currency Setting': 'تنظیمات خرید ارز',
      'Sell Currency Setting': 'تنظیمات فروش ارز',
      To: 'تا',
      Select: 'انتخاب',
      Collateral: 'معرف',
      'Communication Way': 'راه ارتباطی',
      Enable: 'فعال‌سازی',
      Webcam: 'کمره',
      Upload: 'آپلود',
      Photo: 'عکس',
      'Add New Phone Number': 'افزودن شماره جدید',
      'At Least One Number Is Mandatory': 'حداقل یک شماره اجباری است',
      'Phone Number Cannot Be Repeated.': 'شماره تلفن نمی تواند تکراری باشد.',
      'Register Customer Without Additional Information':
        'ثبت شخص بدون اطلاعات تکمیلی',
      'Wholesale Exchange': 'حواله‌دار عمده',
      'Retail Exchange': 'حواله‌دار پرچون',
      Create: 'ایجاد',
      'System Management': 'مدیریت سیستم',
      Business: 'تجارت',
      'Create Group': 'ایجاد گروه',
      'System Settings': 'تنظیمات سیستم',
      'System Rate': 'نرخ سیستم',
      'Advanced Settings': 'تنظیمات پیشرفته',
      'Initial Settings': 'تنظیمات اولیه',
      'Header Settings': 'تنظیمات سر ورقی',
      'Add Currency': 'افزودن ارز',
      'Update Currency': 'ویرایش ارز',
      'Upload a logo for the header': 'آپلود لوگو برای سر ورقی',
      'Upload Excel File': 'آپلود فایل اکسل',
      Debtor: 'باقی‌دار',
      DebtorSum: 'جمع باقی‌دار',
      CreditorSum: 'جمع طلبکار',
      'List Of Most Debtors': 'لیست بیشترین باقی‌داران',
      Creditor: 'طلبکار',
      'Create Currency': 'ایجاد ارز',
      Normal: 'عادی',
      Other: 'دیگر',
      Dollar: 'دالر',
      'Account Remaining': 'بیلانس حساب',
      Remaining: 'بیلانس',
      From: 'از',
      Date: 'تاریخ',
      'Balance Currency Type': 'نوع ارز موجودی',
      'Mobile Number': 'شماره موبایل',
      'Account Details': 'اطلاعات حساب',
      'Banks List': 'فهرست بانک‌ها',
      'Banks Management': 'مدیریت بانک‌ها',
      Row: 'ردیف',
      Add: 'افزودن',
      'New Bank': 'بانک جدید',
      'Bank Name': 'نام بانک',
      'Card Number': 'شماره کارت',
      'Bank Branch Code': 'کد شعبه',
      Status: 'وضعیت',
      OverallStatus: 'وضعیت کلی',
      ChequeOverallStatus: 'وضعیت کلی چک',
      SubmitChequeInMoneyx: 'ثبت چک در سامانه مانیکس',
      DebtCollection: 'جمع بدهی',
      Active: 'فعال',
      Archived: 'آرشیف',
      'Bank Book': 'دفتر بانک',
      'No Bank Is Registered': 'هیچ بانکی ثبت نشده است',
      'No Submited Transaction': 'معامله‌ای ثبت نشده است',
      Bank: 'بانک',
      Edit: 'ویرایش',
      State: 'ایالت',
      Yes: 'بله',
      No: 'خیر',
      IncreaseCredit: 'افزایش اعتبار',
      CancelDraft: 'لغو حواله',
      'Edit Draft': 'ویرایش حواله',
      'Edit Transaction': 'ویرایش معامله',
      'Edit Bank Transaction': 'ویرایش معامله بانکی',
      'Submit Edit': 'ثبت ویرایش',
      'Archiving The Account': 'آرشیف کردن حساب',
      'Cash withdrawal': 'پرداخت نقدی',
      'Cash deposit': 'دریافت نقدی',
      Deposit: 'دریافت',
      Withdraw: 'پرداخت',
      Deposit2: 'واریز',
      Withdraw2: 'برداشت',
      'Search In Moneyx': 'جستجو در مانیکس',
      'Quick Access': 'دسترسی سریع',
      'Print Information': 'چاپ اطلاعات',
      'Upload File': 'آپلود فایل',
      'Transaction Description': 'شرح معامله',
      'Receipt Number': 'شماره فیش',
      Receipt: 'فیش',
      uploadFileWithTouch: 'د فایل اپلوډ کولو لپاره ټچ وکړئ.',
      SubmitedDocumentsCount: 'د ثبت شویو اسنادو شمېر',
      ReceiptWithHeader: 'رسید با سربرگ',
      ReceiptWithoutHeader: 'رسید بدون سربرگ',
      'Transaction Amount': 'مبلغ معامله',
      'Changed Amount': 'مبلغ تغییر',
      Messages: 'پیام‌ها',
      Notifications: 'اعلان‌ها',
      Languages: 'زبان‌ها',
      'Account Balance': 'بیلانس حساب',
      HasAccountInitialBalance: 'حساب موجودی اولیه دارد؟',
      IsAccountHidden: 'مخفی سازی حساب از سایر کاربران',
      'Cash Desk': 'خزانه',
      'System Accounts': 'حساب‌های سیستم',
      Cash: 'خزانه',
      'Cash Name': 'نام خزانه',
      Costs: 'مصارفات',
      Commission: 'کمیشن',
      OnlyCommissions: 'فقط کمیشن ها',
      OnlyTransactions: 'فقط معامله ها',
      WithCommission: 'دارای کمیشن',
      WithoutCommission: 'بدون کمیشن',
      creditaccount: 'حساب اعتبارات',
      UnknownCreditAccount: 'اعتبار نامعلوم',
      unknowncreditaccount: 'اعتبار نامعلوم',
      'Unexecuted draft account': 'حواله‌های اجرا نشده',
      'unexecuted draft account': 'حواله‌های اجرا نشده',
      'Account Information': 'اطلاعات حساب',
      Accounts: 'حساب‌ها',
      ChargingSale: 'فروش شارژ',
      UnrealizedDraft: 'حواله‌های اجرا نشده',
      'Unrealized Drafts': 'حواله‌های اجرا نشده',
      UnexecutedDraft: 'حواله‌های اجرا نشده',
      BankPayment: 'دریافت و پرداخت بانکی',
      'No Option Message': 'موردی وجود ندارد',
      'No Content Message': 'موردی وجود ندارد',
      'Branch Registration': 'ثبت نمایندگی',
      'Branch Edition': 'ویرایش نمایندگی',
      'Advanced Details': 'اطلاعات پیشرفته',
      'Drafts List': 'فهرست حواله‌ها',
      'Draft Documents List': 'فهرست مدارک حواله',
      'Unrealized Drafts List': 'فهرست حواله‌های اجرا نشده',
      'Pending Drafts List': 'فهرست حواله‌های در انتظار',
      'Branches List': 'فهرست نمایندگی‌ها',
      'Branch List': 'فهرست نمایندگی‌',
      'Trade Currency': 'تبادله اسعار',
      'Currencies Management': 'مدیریت ارزها',
      'Branch Account': 'حساب نمایندگی',
      'Credits Account': 'حساب اعتبارات',
      'Customer Credit Account': 'حساب اعتبار مشتری',
      'Unknown Bank Deposits': 'جمع های نامعلوم بانکی',
      'Submit Transaction': 'ثبت معامله',
      'Submit Cash Transaction': 'ثبت معامله نقدی',
      'Submit Bank Transaction': 'ثبت معامله بانکی',
      'New Submit Bank Transaction': 'ثبت معامله بانکی (جدید)',
      SubmitInterBankTransactionSuccessfully:
        'معامله بین بانکی باموفقیت انجام شد.',
      'Register Person': 'ثبت شخص',
      'Edit Person': 'ویرایش شخص',
      'Edit Company': 'ویرایش شرکت',
      CommonAccount: 'حساب',
      BranchAccount: 'نمایندگی',
      BankAccount: 'بانک',
      SubmitNewBankAccount: 'ثبت حساب بانکی جدید',
      NewBankAccount: 'حساب بانکی جدید',
      'Unknown Bank Transactions': 'معاملات نامعلوم بانکی',
      customer: 'مشتری',
      receiver: 'گیرنده',
      'Interbank Transaction': 'معامله بین بانکی',
      // 'Outgoing Draft Submission': 'ثبت حواله صادره',
      // 'Incoming Draft Submission': 'ثبت حواله وارده',
      'Miscellaneous Draft Submission': 'ثبت حواله متفرقه',
      'Miscellaneous Draft': 'حواله متفرقه',
      'Draft Conduction': 'اجرای حواله',
      'Cheques List': 'فهرست چک‌ها',
      'Debtor And Creditor List': 'فهرست باقی‌داران و طلبکاران',
      'Commissions Report': 'گزارش کمیشن ها',
      'Trade Currencies List': 'فهرست تبادله اسعار',
      SavedToCashDeskOrBankAccount: 'ذخیره شده',
      SavedToCustomerAccount: 'واگذار شده',
      HasReturned: 'برگشت خورده',
      CashedToMoney: 'پاس شده',
      'Cashed to money': 'پاس شده',
      'cashed to money': 'پاس شده',
      UnknownBankAccounts: 'معاملات نامعلوم بانکی',
      'Profit And Loss Statement': 'بیلانس مفاد و ضرر',
      'Calculating Profit and Loss': 'محاسبه‌گر مفاد و ضرر',
      'Payment Order': 'دستور پرداخت',
      profile: 'پروفایل',
      'Operation of the day': 'عملیات روز',
      'Financial Operation': 'عملیات مالی',
      DocumentIsPrivate: 'سند بدون ثبت توضیحات است',
      'Net Profit': 'مفاد خالص',
      'Gross Profit': 'مفاد ناخالص',
      Loss: 'ضرر',
      Cost: 'مصرف',
      Delete: 'حذف',
      'no attachments': 'ضمیمه ندارد',
      'The Entered Amount Is Bigger Than The Sum Of The Payment Order':
        'مبلغ وارد شده، از جمع مقدار مبالغ فیش بالاتر می باشد',
      OK: 'تایید',
      Cancel: 'لغو',
      Clear: 'پاک کردن',
      'System Guides': 'درباره سیستم',
      'System Main Currency': 'ارز پیش فرض سیستم',
      'Increase Customer Credit': 'افزایش اعتبار مشتری',
      'Date And Time': 'تاریخ و زمان',
      AllEmployees: 'همه کارمندان',
      ViewGroup: 'مشاهده گروه',
      DataEntry: 'ورود اطلاعات',
      HaveAccount: 'دارای حساب',
      BranchStatus: 'وضعیت نمایندگی',
      DraftDescription: 'شرح حواله',
      'Credit Currency': 'ارز اعتبار',
      'Credit Amount': 'مبلغ اعتبار',
      'Transaction Information': 'اطلاعات معامله',
      'Credit Registration': 'ثبت اعتبار',
      'Reported Bugs': 'خطاهای گزارش شده',
      'Excel Data Output': 'خروجی اکسل اطلاعات',
      'Bug Details': 'جزئیات خطا',
      'My Tickets': 'تیکت‌های من',
      'From Date': 'از تاریخ',
      'To Date': 'تا تاریخ',
      Failed: 'ناموفق',
      Pend: 'در انتظار',
      Success: 'تایید شده',
      'Request Status': 'وضعیت درخواست',
      'Pending Transactions List': 'فهرست معامله های در انتظار',
      'Submitted By Employee': 'ثبت شده توسط کارمند',
      AllCashDesks: 'همه صندوق‌ها',
      SelectDocument: 'انتخاب سند',
      SelectDocumentAndSubmit: 'سند مورد نظر را انتخاب و سپس تایید را بزنید.',
      'Credits on Da Afghanistan Bank': 'طلبات بالای ده افغانستان بانک',
      UseWebcam: 'از کمره استفاده کنید (CTRL + k)',
      PaymentType: 'نوع پرداخت',
      RemainingCashAccount: 'مانده حساب صندوق',
      ShowCashAccountBalanceMsg:
        'پس از انتخاب صندوق، مانده حساب صندوق به تفکیک نمایش داده می‌شود.',
      PrivateCashTransaction: 'تراکنش نقدی محرمانه باشد.',
      'Submitted By Customer': 'ثبت شده توسط مشتری',
      'Submitted By Mirror Branch': 'ثبت شده توسط نمایندگی مقابل',
      'Remove Filters': 'حذف فیلترها',
      'From Number': 'از شماره',
      'To Number': 'تا شماره',
      'From Amount': 'از مبلغ',
      'To Amount': 'تا مبلغ',
      Submit: 'تایید',
      SubmitTransfer: 'ثبت انتقال',
      Reject: 'رد',
      'Error Number': 'شماره خطا',
      'Error Title': 'عنوان خطا',
      'Error Opening Date': 'تاریخ باز شدن خطا',
      'Error Closing Date': 'تاریخ بسته شدن خطا',
      'Error Status': 'وضعیت خطا',
      Operation: 'عملیات',
      RepeatOperation: 'تکرار عملیات',
      'Operation Type': 'نوع عملیات',
      Number: 'شماره',
      numbers: 'عدد',
      'Total Numbers': 'تعداد کل',
      Detail: 'جزئیات',
      ShowDetails: 'نمایش جزئیات',
      ShowBrief: 'نمایش مختصر',
      SeeDetails: 'مشاهده جزئیات',
      'Date Of Payment Order': 'تاریخ دستور پرداخت',
      'Account Owner': 'صاحب حساب',
      'Total Deposit Amount': 'جمع مبلغ واریز',
      'Birth Certificate': 'کارت تولد',
      'National Card': 'کارت ملی',
      Passport: 'پاسپورت',
      'Physical Tezkire': 'تذکره کاغذی',
      'Electronic Tezkire': 'تذکره برقی',
      'Kimlik Card': 'کارت کیملیک',
      'Driving License': 'جواز رانندگی',
      'Temporary Residence Card Of Foreign Nationals': 'کارت اقامه',
      Police: 'سرباز',
      'Civil Registry Office': 'اداره ثبت احوال',
      'Immigration Office': 'اداره مهاجرت',
      'Live Currency Rate': 'نرخ لحظه ای ارز',
      'Due to the long number of letters of the selected document information, it is not possible to share.':
        'نظر به طولانی بودن تعداد حروف اطلاعات سند انتخابی، امکان اشتراک گذاری فراهم نیست.',
      accountStatusMessage:
        'پس از انتخاب حساب و ارز خرید، مانده حساب نمایش داده می‌شود',
      draftStatusMessage:
        'پس از انتخاب حساب و ارز حواله، مانده حساب نمایش داده می‌شود',
      accountStatusMessage2: 'پس از انتخاب حساب، مانده حساب نمایش داده می‌شود',
      bankStatusMessage: 'پس از انتخاب بانک، موجودی بانک نمایش داده می‌شود',
      DestinationBankStatusMessage:
        'پس از انتخاب بانک مقصد، مانده بانک نمایش داده میشود.',
      SourseBankStatusMessage:
        'پس از انتخاب بانک مبدا، مانده حساب بانک نمایش داده میشود.',
      bankStatusMessage2:
        'پس از انتخاب بانک، مانده حساب بانک نمایش داده می‌شود',
      positiveTradeRate: 'نرخ تبدیل باید بیشتر از صفر باشد.',
      invalidTradeRate: 'نرخ تبدیل معتبر نیست',
      'Bank withdrawal': 'برداشت از بانک',
      'Bank deposit': 'واریز به بانک',
      AccountStatusMessage:
        'پس از انتخاب مشتری، مانده حساب مشتری نمایش داده می‌شود.',
      'Creditor Customer': 'مشتری طلبکار',
      Recieve: 'دریافت',
      'Debtor Customer': 'مشتری باقی دار',
      'Bank Transaction Is Confidential': 'معامله بانکی بدون ثبت توضیحات باشد',
      'Transaction Currency': 'ارز معامله',
      'Commission Amount': 'مبلغ کمیشن',
      'Commission Creditor Amount': 'مبلغ کمیشن طلبکار',
      'Commission Currency': 'ارز کمیشن',
      'Transaction Date': 'تاریخ معامله',
      'Operation Date': 'تاریخ عملیات',
      'Is There A Fee For This Transaction': 'این معامله کمیشن دارد؟',
      UncertainCreditLimit:
        'در صورت مشخص نکردن سقف اعتبار، سقف اعتبار صفر در نظر گرفته می‌شود',
      // Incoming: 'وارده',
      // Outgoing: 'صادره',
      'Buyer Commission': 'کمیشن خریدار',
      'Seller Commission': 'کمیشن فروشنده',
      'Transaction Type': 'نوع معامله',
      Documentations: 'مستندات',
      System: 'سیستم',
      'System Account': 'حساب سیستم',
      'Customer Commission': 'کمیشن مشتری',
      'Branch Commission': 'کمیشن نمایندگی',
      'Transaction Number': 'شماره معامله',
      'Transaction Status': 'وضعیت معامله',
      'Settings And File Upload': 'تنظیمات و آپلود فایل',
      'Other Number': 'شماره متفرقه',
      'Debtor Commission': 'کمیشن باقی دار',
      'Creditor Commission': 'کمیشن طلبکار',
      'Debtor Account': 'حساب باقی دار',
      'Creditor Account': 'حساب طلبکار',
      'Edit Miscellaneous Draft': 'ویرایش حواله متفرقه',
      'Spot Rate': 'نرخ لحظه‌ای',
      'Source Of Money': 'منبع پول',
      'Purpose Of Exchange': 'هدف معامله',
      'Periodic Profit Chart': 'گراف دوره ای مفاد',
      'Daily Transactions Chart': 'گراف معامله های روزانه',
      'Organization Chart': 'چارت تشکیلاتی',
      'Opening Account In Australia Bank': 'افتتاح حساب در بانک استرالیا',
      'See Australia Bank Account': 'مشاهده حساب بانک استرالیا',
      // ************************ end default document types **************************
      // ************************ phrase items translations **************************
      'transfer between accounts': 'انتقال بین حساب',
      'transaction id': 'شماره معامله',
      'transaction id:': 'شماره معامله:',
      'transfer between accounts with': 'انتقال بین حساب با',
      amount: 'مبلغ',
      currency: 'ارز',
      currencyCode: 'کد ارز',
      CurrencyRate: 'نرخ ارز',
      DecimalPlacesCalculation: 'به چند رقم اعشار محاسبه شود؟',
      CalculationType: 'نوع محاسبه',
      benchMarkCurrency: 'ارز معیار',
      'Bank Currency': 'ارز بانک',
      debtor: 'باقی‌دار',
      creditor: 'طلبکار',
      Percent: 'درصد',
      Liquidity: 'نقدینگی',
      Today: 'در تاریخ روز',
      'Until Today': 'تا تاریخ روز',
      'Draft Number': 'شماره حواله',
      // 'outgoing draft order': 'حواله صادره',
      // 'incoming draft order': 'حواله وارده',
      IncomingDrafts: 'حواله‌های آمد',
      OutgoingDrafts: 'حواله‌های رفت',
      UnexecutedDrafts: 'حواله‌های اجرا نشده',
      ExecutedDrafts: 'حواله‌های اجرا شده',
      AllDrafts: 'تمام حواله‌ها',
      'Payment Place': 'محل پرداخت',
      to: 'به',
      from: 'از',
      at: 'در',
      'was registered.': 'ثبت شد.',
      'commission for': 'کمیشن',
      'bank account': 'حساب بانک',
      'customer account': 'حساب مشتری',
      'sell currency': 'فروش ارز',
      'buy currency': 'خرید ارز',
      'Buy Currency': 'خرید ارز',
      'bank transaction': 'معامله بانکی',
      CustomerAccountCode: 'کد حساب مشتری',
      commission: 'کمیشن',
      'cash transaction': 'معامله نقدی',
      unrealizeddraft: 'حواله‌های اجرا نشده',
      unexecuteddraft: 'حواله‌های اجرا نشده',
      unknownbankaccounts: 'معاملات نامعلوم بانکی',
      chargingsale: 'فروش شارژ',
      'buy charge': 'خرید شارژ',
      cash: 'خزانه',
      costs: 'مصارف',
      'New Version Available Phrase':
        'نسخه جدید سامانه در دسترس است ؛ لطفا صفحه را بروزرسانی کنید',
      OnlyWithAccountTitle: 'فقط با عنوان حساب',
      // ************************ end phrase items translations **************************
      // ***********************************
      // countries
      // ***********************************
      Afghanistan: 'افغانستان',
      Australia: 'استرالیا',
      China: 'چین',
      Denmark: 'دانمارک',
      Germany: 'آلمان',
      Greece: 'یونان',
      Iran: 'ایران',
      Iraq: 'عراق',
      Norway: 'ناروی',
      Pakistan: 'پاکستان',
      'Saudi Arabia': 'عربستان سعودی',
      Sweden: 'سوئدن',
      Turkey: 'ترکیه',
      'United Arab Emirates': 'امارات متحده عربی',
      Baghdad: 'بغداد',
      Ankara: 'انقره',
      Kabul: 'کابل',
      'Islamabad, Karachi': 'کراچی ,اسلام آباد',
      // ***********************************
      // end countries
      // ***********************************
      // ***********************************
      // actionTypes
      // ***********************************
      TransferBetweenTwoAccounts: 'انتقال بین حساب',
      Cheque: 'چک',
      ChequeStatus: 'وضعیت چک',
      ChequeCurrentStatus: 'وضعیت فعلی چک',
      ConsideringCheque: 'با احتساب چک',
      CashTransaction: 'معامله نقدی',
      // IncomingDraft: 'حواله وارده',
      // OutgoingDraft: 'حواله صادره',
      Miscellaneous: 'متفرقه',
      BuySellCurrency: 'تبادله اسعار',
      BankTransaction: 'معامله بانکی',
      RemoveCheckout: 'حذف تسویه',
      PendDraft: 'حواله در انتظار',
      RemovePendDraft: 'حذف حواله در انتظار',
      ExecuteDraft: 'اجرای حواله',
      InterBankTransaction: 'معامله بین بانکی',
      Checkout: 'تسویه',
      PaymentOrderReceipt: 'رسید دستور پرداخت',
      ChangeBranch: 'تبدیل نرخ',
      UnknownBankTransaction: 'معامله نامعلوم بانکی',
      UpdateAccount: 'ویرایش حساب',
      DeleteAccount: 'حذف حساب',
      AddBank: 'افزودن بانک',
      UpdateBank: 'ویرایش بانک',
      DeleteBank: 'حذف بانک',
      AddCustomer: 'افزودن مشتری',
      UpdateCustomer: 'ویرایش مشتری',
      DeleteCustomer: 'حذف مشتری',
      AddCompany: 'افزودن شرکت',
      UpdateCompany: 'ویرایش شرکت',
      DeleteCompany: 'حذف شرکت',
      AddBranch: 'افزودن نمایندگی',
      UpdateBranch: 'ویرایش نمایندگی',
      DeleteBranch: 'حذف نمایندگی',
      ConnectToBranch: 'اتصال به نمایندگی',
      DisconnectFromBranch: 'قطع اتصال',
      OneWayConnecting: 'اتصال یک طرفه',
      BranchName: 'نام نمایندگی',
      BranchManager: 'مسئول نمایندگی',
      BranchIsNotFound: 'نمایندگی یافت نشد',
      SelecTheDesiredBranch: 'نمایندگی مورد نظر را انتخاب کنید',
      InOneWayConnectionModeTheCounterpartyIsNotAblleToSeeYoureTransactions:
        'در حالت اتصال یک طرفه نمایندگی مقابل قادر به مشاهده معاملات شما نیست',
      AddCurrency: 'افزودن ارز',
      UpdateCurrency: 'ویرایش ارز',
      DeleteCurrency: 'حذف ارز',
      AddPayOrder: 'افزودن دستور پرداخت',
      UpdatePayOrder: 'ویرایش دستور پرداخت',
      DeletePayOrder: 'حذف دستور پرداخت',
      PublishTransactionInWhatsapp: 'ارسال معامله در واتساپ',
      TransactionBeWithoutDescription: 'معامله بدون ثبت توضیحات باشد',
      CashTransactionWithoutDescription: 'معامله نقدی بدون ثبت توضیحات باشد.',
      SyncCreditLimit: 'همگام سازی سقف اعتبار',
      ShouldHaveSamevaluein: 'باید دارای مقادیر یکسان باشند در',
      'In progress': 'در حال پردازش',
      'Invalid date': 'تاریخ نامعتبر است',
      'Required field message': 'پرکردن این فیلد اجباری است',
      uploaderInputLabelMaximumSize: 'حجم فایل حداکثر باید ۳مگابایت باشد',
      DocumentsModalAcceptFormats: 'فرمت آن باید JPG, PNG, PDF, xlsx باشد.',
      ExcelUploaderInputLabelFormat: 'فرمت آن باید xlsx باشد.',
      FileUploaderInputLabelFormat:
        'فرمت آن باید xls, xlsx, pdf, png, jpg, jpeg, doc, docx, webp باشد.',
      WhatsAppCommunication: 'لینک یا آدرس گروه واتساپ',
      DocumentTypeDescription: 'توضیحات نوع مدرک',
      CommissionAmountRegisteredToCreditor:
        'مقدار کمیشن به حساب طلبکار ثبت خواهد شد.',
      CommissionAmountRegisteredToDebtor:
        'مقدار کمیشن به حساب بدهکار ثبت خواهد شد.',
      'The commission amount must be less than the transaction amount':
        'مقدار کمیشن باید کمتر از مقدار معامله باشد.',
      SelectItemToAutoCalculate: 'کدام آیتم به صورت خودکار محاسبه شود؟',
      'Value must be greater than zero': 'مقدار باید بزرگتر از صفر باشد',
      'File required': 'افزودن فایل اجباری است',
      'no future dates message': 'تاریخ نمی‌تواند بزرگتر از تاریخ امروز باشد',
      'The email entered is invalid': 'ایمیل نامعتبر است',
      'Invalid website message': 'آدرس وب‌سایت نامعتبر است',
      'Number message': 'فقط استفاده از اعداد مجاز است',
      'Existed username message': 'نام کاربری تکراری می‌باشد',
      'Numbers and English letters message':
        'فقط عدد و حروف کوچک و بزرگ انگلیسی معتبر است',
      'Capital English letters message': 'فقط حروف بزرگ انگلیسی معتبر است',
      'Uncheck message': 'در صورت عدم ثبت اطلاعات، تیک را بردارید',
      'Cheque date message': 'تاریخ سررسید نمی‌تواند از تاریخ چک کوچکتر باشد',
      'Iban message':
        'کد شبا باید دقیقا 26 کاراکتر باشد و فقط اعداد و حروف کوچک و بزرگ انگلیسی معتبر است',
      'Card number message': 'شماره کارت باید تنها عدد و شامل 16 رقم باشد',
      'Other occupation message':
        'هنگامی که شغل، سایر انتخاب می‌شود، پر کردن این فیلد الزامی است',
      'Customer age limit message': 'سن مشتری باید بیشتر از ۱۰سال باشد',
      'Issue date message': 'تاریخ صدور نمی‌تواند از تاریخ امروز بزرگتر باشد',
      'Expire date message': 'تاریخ انقضا باید از تاریخ صدور بزرگتر باشد',
      'Phone number minimum length message':
        'طول شماره تلفن باید بزرگتر از 9 کاراکتر باشد',
      'Phone number maximum length message':
        'طول شماره تلفن باید کوچکتر از 15 کاراکتر باشد',
      'Phone number start message': 'شماره تلفن نباید با صفر شروع شود',
      'Max number message': 'عدد باید بین صفر تا صد باشد',
      'Invalid trade rate': 'نرخ در بازه‌ی معتبر نیست',
      'Required currency': 'انتخاب ارز الزامی است',
      InsertResidenceNumberMessage:
        'شماره اقامت را از بخش ویرایش مشتری در تکست فیلد توضیحات نوع مدرک وارد نمایید.',
      HasUserName: 'نام کاربری دارد',
      InstantAccountAddition: 'افزودن فوری حساب',
      PersianUserNameError: 'نام کاربری نباید فارسی باشد',
      DuplicatedPhoneNumberError: 'این شماره تلفن قبلا ثبت شده است',
      AdditionalInformation: 'اطلاعات تکمیلی',
      RegisterCompanyWithoutAdditionalInformation:
        'ثبت شرکت بدون اطلاعات تکمیلی',
      SaveInformation: 'آیا اطلاعات ذخیره شود؟',
      AddNewDocument: 'افزودن مدرک جدید',
      SelectAsActiveDocument: 'انتخاب به عنوان مدرک فعال',
      SelectAsMainPhoneNumber: 'انتخاب به عنوان شماره اصلی',
      SelectAsActiveAddress: 'انتخاب به عنوان آدرس فعال',
      ChangeActiveDocumentSuccessfully: 'مدرک فعال باموفقیت تغییر کرد.',
      SubmitNewDocument: 'ثبت مدرک جدید',
      ContinueWithoutSubmitDocument: 'ادامه بدون ثبت مدرک',
      SelectedReferenceDocument: 'مدرک مرجع انتخاب شده',
      SelectAsReferenceDocument: 'انتخاب این مدرک به عنوان مدرک مرجع',
      ContinueWithoutSubmitAccount: 'ادامه بدون ثبت حساب',
      ContinueWithoutSubmitBankInformation: 'ادامه بدون ثبت اطلاعات بانکی',
      SubmitNewAccount: 'ثبت حساب جدید',
      'Continue without submit attachment': 'ادامه بدون ثبت ضمیمه',
      'Submit new attachment': 'ثبت ضمیمه جدید',
      AddNewAttachment: 'افزودن ضمیمه جدید',
      NoShareholders: 'هیچ سهام‌داری برای شرکت انتخاب نشده است.',
      NoBalanceIsThere: 'هیچ موجودی ثبت نشده است.',
      SelectShareholder: 'همین الان سهام‌داران را انتخاب کنید. ',
      AddToShareholdersList: 'افزودن به لیست سهام‌داران',
      DeleteUnexecutedDraftTitle: 'آیا از حذف حواله اجرانشده اطمینان دارید؟',
      DeleteDraftTitle: 'آیا از حذف حواله اطمینان دارید؟',
      DeleteBankAccountTitle: 'آیا از حذف حساب بانک اطمینان دارید؟',
      DeleteDraftOrderTitle: 'آیا از حذف سفارش حواله اطمینان دارید؟',
      DeleteTradeCurrencyTitle: 'آیا از حذف خرید و فروش ارز اطمینان دارید؟',
      DeleteSystemAccountTitle: 'آیا از حذف حساب سامانه اطمینان دارید؟',
      DeleteReceiverTitle: 'آیا از حذف دریافت کننده اطمینان دارید؟',
      DeleteDocumentTitle: 'آیا از حذف مدرک اطمینان دارید؟',
      DeleteAddressTitle: 'آیا از حذف آدرس اطمینان دارید؟',
      DeleteAttachmentTitle: 'آیا از حذف ضمیمه اطمینان دارید؟',
      DeletePaymentOrderTitle: 'آیا از حذف دستور پرداخت اطمینان دارید؟',
      DeleteCustomerTitle: 'آیا از حذف مشتری اطمینان دارید؟',
      DeleteAccountTitle: 'آیا از حذف حساب اطمینان دارید؟',
      DeleteCurrencyTitle: 'آیا از حذف ارز اطمینان دارید؟',
      DeleteShareholderTitle: 'آیا از حذف سهامدار اطمینان دارید؟',
      DeleteTransactionTitle: 'آیا از حذف سند اطمینان دارید؟',
      DeleteChequeTitle: 'آیا از حذف چک اطمینان دارید؟',
      DeleteHistoryTitle: 'آیا از حذف تاریخچه اطمینان دارید؟',
      DeletePaymentPlaceTitle: 'آیا از حذف محل پرداخت اطمینان دارید؟',
      DeleteBranchTitle: 'آیا از حذف حساب نمایندگی اطمینان دارید؟',
      DeleteCurrencyCalculationTitle: 'آیا از حذف محاسبات اطمینان دارید؟',
      DeletePersonTitle: 'آیا از حذف شخص اطمینان دارید؟',
      DeleteDraftDocumentTitle: 'آیا از حذف سند حواله اطمینان دارید؟',
      DeleteTransaction2Title: 'آیا از حذف معامله اطمینان دارید؟',
      NoSubmittedDraft: 'حواله‌ای ثبت نشده.',
      NoSubmittedBranch: 'نمایندگی ثبت نشده',
      NoSubmittedReceiver: 'گیرنده ثبت نشده',
      RejectPendingDraftTitle: 'آیا از رد حواله اطمینان دارید؟',
      UndoDraftCancellationTitle: 'آیا از بازگرداندن لغو حواله اطمینان دارید؟',
      CancelDraftSuccessfully: 'حواله با موفقیت بازگردانی شد.',
      NoSubmittedPaymentOrder: 'دستور پرداخت ثبت نشده',
      NoSubmittedCustomer: 'مشتری ثبت نشده',
      NoSubmittedCheckout: 'تسویه ثبت نشده.',
      NoSubmittedCheckout2: 'تسویه‌ای ثبت نشده است',
      NoSubmittedDocument: 'سندی ثبت نشده.',
      NoSubmittedDocument2: 'مدرکی ثبت نشده.',
      NoSubmittedOperation: 'عملیاتی انجام نشده!',
      NoSubmittedTicket: 'تیکتی ثبت نشده',
      DraftRegistrationSuccessfully: 'حواله با موفقیت ثبت شد.',
      EditDraftSuccessfully: 'حواله با موفقیت ویرایش شد.',
      IncreaseCustomerCredit: 'افزایش اعتبار مشتری باموفقیت انجام شد.',
      DraftNumberSettingsSuccessfully: 'تنظیمات شماره حواله باموفقیت انجام شد.',
      EditBranchSuccessfully: 'ویرایش نمایندگی باموفقیت انجام شد.',
      EditPaymentPlaceSuccessfully: 'ویرایش محل پرداخت باموفقیت انجام شد.',
      EditPersonSuccessfully: 'ویرایش شخص باموفقیت انجام شد',
      RegisterPaymentPlaceSuccessfully: 'ثبت محل پرداخت باموفقیت انجام شد.',
      RegisterPersonSuccessfully: 'ثبت مدرک باموفقیت انجام شد.',
      IncreaseOrganizationSuccessfully:
        'افزایش اعتبار سازمان باموفقیت انجام شد.',
      DeleteOrderDraftSuccessfully: 'سفارش حواله با موفقیت حذف شد',
      DeleteDraftSuccessfully: 'حواله با موفقیت حذف شد',
      DeleteTransactionSuccessfully: 'معامله با موفقیت حذف شد',
      ExportExcelSuccessfully: 'خروجی اکسل با موفقیت دانلود شد',
      ExportExcelAttention:
        'توجه داشته باشید که برای دریافت فایل اکسل باید تمامی گروه های پیشنهادی را در تنظیمات سیستم خود اضافه کرده باشید!',
      AddThisGroupError: 'لطفا گروه زیر را در تنظیمات سیستم خود اضافه کنید',
      ExportExcelAccountGroupsError:
        'متاسفانه دانلود انجام نشد. لطفا بعدا دوباره تلاش کنید',
      DeleteChequeSuccessfully: 'چک باموفقیت حذف شد',
      DeleteSystemAccountSuccessfully: 'حساب سامانه باموفقیت حذف شد',
      DeleteReceiverSuccessfully: 'حذف گیرنده باموفقیت انجام شد',
      DeleteDocumentSuccessfully: 'مدرک با موفقیت حذف شد.',
      DeleteAttachmentSuccessfully: 'ضمیمه باموفقیت حذف شد.',
      DeleteBankInformationSuccessfully: 'اطلاعات بانکی باموفقیت حذف شد.',
      DeleteAddressSuccessfully: 'آدرس باموفقیت حذف شد.',
      DeleteCustomerSuccessfully: 'حذف شخص باموفقیت انجام شد.',
      DeleteAccountSuccessfully: 'حساب باموفقیت حذف شد.',
      DeleteSuccessfully: 'حذف باموفقیت انجام شد',
      DeleteCurrencySuccessfully: 'ارز باموفقیت انجام شد',
      DeleteShareholderSuccessfully: 'سهامدار باموفقیت حذف شد.',
      DeletePaymentPlaceSuccessfully: 'محل پرداخت باموفقیت حذف شد.',
      DeleteBranchSuccessfully: 'نمایندگی باموفقیت حذف شد.',
      DeleteBankAccountSuccessfully: 'حساب بانکی باموفقیت حذف شد.',
      RejectPendingDraftSuccessfully: 'حواله باموفقیت رد شد.',
      SelectCheckoutDate: 'تاریخ تسویه مورد نظر را انتخاب کنید.',
      DeleteLastCheckoutText:
        'برای حذف ، صرفا آخرین تسویه مشتری را میتوانید حذف کنید.',
      DeleteLastBranchCheckoutText:
        'برای حذف، صرفا آخرین تسویه نمایندگی را می‌توانید حذف کنید.',
      DisconnectBranchTitle: 'آیا از قطع اتصال نمایندگی اطمینان دارید؟',
      DisconnectBranchSuccessfully: 'قطع اتصال نمایندگی باموفقیت انجام شد.',
      DeletePhoneNumberSuccessfully: 'شماره با موفقیت حذف شد.',
      SubmitDocSuccessfully: 'سند با موفقیت ثبت شد.',
      ShareReceiptText:
        'می‌توانید رسید معامله را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareInterBankTransactionReceiptText:
        'می‌توانید رسید تراکنش بین بانکی را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareTradeCurrencyTransactionReceiptText:
        'می‌توانید رسید خرید ارز را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareSellCurrencyTransactionReceiptText:
        'می‌توانید رسید فروش ارز را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareSubmitTransactionReceiptText:
        'می‌توانید رسید سند را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareExecuteDraftReceiptText:
        'می‌توانید رسید اجرای حواله را از طریق راه‌های زیر به اشتراک بگذارید:',
      ShareDraftReceiptText:
        'می‌توانید رسید حواله را از طریق راه‌های زیر به اشتراک بگذارید:',
      PrintReceiptWithHeader: 'چاپ رسید با سربرگ',
      PrintReceiptWithoutHeader: 'چاپ رسید بدون سربرگ',
      NotActiveSystemError: 'سامانه برای شما فعال نیست!',
      ExpiredSubscriptionError: 'زمان اشتراک شما به پایان رسیده است!',
      ReActiveAccountTitle: 'برای فعال‌سازی مجدد دکمه خرید نسخه را کلیک کنید.',
      buyVersionTitle: 'برای استفاده از سامانه آریانا ابتدا نسخه خریداری کنید.',
      UseDesktopVersionTitle:
        'جهت فعال کردن این امکان، از نسخه دسکتاپ نرم‌افزار استفاده کنید.',
      NoActiveMobile: 'حساب شماره موبایل فعالی ندارد.',
      SendAccountBalanceSuccessfully: 'باقیمانده حساب باموفقیت ارسال شد.',
      SendToBranch: 'ارسال به نمایندگی',
      SentToWhatsapp: 'ارسال به واتساپ',
      AutomaticSendToWhatsapp: 'ارسال اتوماتیک به واتساپ',
      SendManuallyToWhatsApp: 'ارسال دستی به واتساپ',
      DepositAmountsDivision: 'تقسیم مبالغ واریز',
      SelectBankInformation: 'انتخاب اطلاعات بانکی',
      'Cash desk cant be creditor': 'صندوق نمی‌تواند طلبکار باشد.',
      'What is private transaction?': 'تراکنش محرمانه چیست؟',
      'Submit unknown bank deposit': 'ثبت واریز نامعلوم بانکی',
      SelectRowAlert: 'ابتدا یک ردیف را انتخاب کنید.',
      Copied: 'کپی شد.',
      SpecifyDraftType: 'نوع حواله را مشخص کنید.',
      TransactionRegistrationDetails: 'مشخصات ثبت معامله',
      SubmitBugReportSuccessfully:
        'گزارش خطا باموفقیت ثبت شد و در اسرع وقت پاسخ داده خواهد شد.',
      RegisterBankAccountSuccessfully: 'بانک باموفقیت ایجاد شد.',
      RegisterNewFilesSuccessfully: 'سندهای جدید باموفقیت ثبت شدند.',
      RegisterBranchSuccessfully: 'نمایندگی باموفقیت ثبت شد.',
      EditAdditionalInfoSuccessfully: 'اطلاعات تکمیلی باموفقیت ویرایش شد.',
      SubmitAdditionalInfoSuccessfully: 'اطلاعات تکمیلی باموفقیت ثبت شد.',
      SubmitBuyCurrencySuccessfully: 'خرید ارز باموفقیت ثبت شد.',
      changeActivePhoneNumberSuccessfully: 'شماره فعال باموفقیت تغییر کرد.',
      CreateAccountSuccessfully: 'حساب با موفقیت ایجاد شد.',
      EditAccountSuccessfully: 'حساب باموفقیت ویرایش شد.',
      AddCustomersSuccessfully: 'مشتریان باموفقیت افزوده شدند.',
      ExecuteDraftSuccessfully: 'حواله باموفقیت اجرا شد.',
      ChangeActiveAddressSuccessfully: 'آدرس فعال باموفقیت انجام شد.',
      ShowMore: 'نمایش بیشتر',
      ShowLess: 'نمایش کمتر',
      DraftCommission: 'کارمزد حواله',
      DraftCommissionAmount: 'مبلغ کارمزد حواله',
      BranchShouldBeSelected: 'نمایندگی باید انتخاب شود.',
      ReceivedCurrency: 'ارز دریافتی',
      ConvertCurrencyRate: 'نرخ تبدیل ارز',
      'Draft Equivalent Currency': 'ارز معادل حواله',
      'Submit Common Commission': 'ثبت کارمزد مشترک',
      CheckRateTime: 'اعتبار نرخ حواله به پایان رسیده است.',
      BankAccountBalance: 'مانده حساب بانک',
      BuyerBankAccountBalance: 'مانده حساب بانک خریدار',
      SelerBankAccountBalance: 'مانده حساب بانک فروشنده',
      // ***********************************
      // end actionTypes
      // ***********************************
      Row3: '3\u200E ردیف',
      Row4: '4\u200E ردیف',
      Row5: '5\u200E ردیف',
      Row6: '6\u200E ردیف',
      Row10: '10\u200E ردیف',
      Row15: '15\u200E ردیف',
      Row20: '20\u200E ردیف',
      Row25: '25\u200E ردیف',
      Row50: '50\u200E ردیف',
      Row100: '100\u200E ردیف',
      Row150: '150\u200E ردیف',
      Row200: '200\u200E ردیف',
      Row250: '250\u200E ردیف',
      Dari: 'دری',
      Pashto: 'پشتو',
      Like: 'مثلا',
      Monthly: 'ماهانه',
      Yearly: 'سالانه',
      Quarterly: 'ربع وار',
      SpecifyTypesOfIdentityDocuments: 'انواع مدرک هویتی را مشخص کنید',
      IssuingOrganization: 'ارگان صادرکننده',
      Light: 'روشن',
      Dark: 'تیره',
      CheckoutAccount: 'تسویه',
      AccountDetails: 'جزئیات حساب',
      Copy: 'کپی',
      AUnexecutedDraft: 'حواله اجرانشده',
      TransactionDate: 'تاریخ سند',
      TransactionRow: 'ردیف سند',
      openingBalance: 'موجودی اولیه',
      SubmitNewCheckout: 'ثبت تسویه حساب جدید',
      BalanceAmount: 'مقدار موجودی',
      OperationHistory: 'تاریخچه عملیات',
      Unlimited: 'نامحدود',
      Share: 'اشتراک‌گذاری',
      Highlight: 'هایلایت',
      RemoveHighlight: 'حذف هایلایت',
      History: 'تاریخچه',
      HighlightedTransactions: 'تراکنش‌های هایلایت شده',
      ShowHighlightedTransactions: 'نمایش تراکنش‌های هایلایت شده',
      LastTransaction: 'آخرین تراکنش',
      Exchange: 'صرافی',
      RemainingAccount: 'مانده حساب',
      BranchAddress: 'آدرس نمایندگی',
      BranchPhone: 'تلفن نمایندگی',
      Rate: 'نرخ',
      BankWithdraw: 'برداشت بانکی',
      BankDeposit: 'Bank deposit',
      SyncBalanceAndCreditLimit: 'همگام سازی موجودی با سقف اعتبار',
      AmountAccountWhatsappReport:
        'بدینوسیله تایید می گردد که جمع مانده حساب شما به صورت زیر می باشد :',
      ChequeAssigner: 'واگذارنده چک',
      ChequeReceiver: 'گیرنده چک',
      ChequePaymentPlace: 'محل پرداخت چک',
      'Visit Cheque owner account': 'مشاهده حساب صاحب چک',
      Received: 'دریافتی',
      Paid: 'پرداختی',
      Category: 'دسته‌بندی',
      Branch2: 'شعبه',
      CustomerAccountRemaining: 'مانده حساب مشتری',
      VisitBuyerAccount: 'مشاهده حساب خریدار',
      VisitSellerAccount: 'مشاهده حساب فروشنده',
      EditOutgoingDraft: 'ویرایش حواله رفت',
      EditIncomingDraft: 'ویرایش حواله آمد',
      CancelIncomingDraft: 'لغو حواله آمد',
      CancelOutgoingDraft: 'لغو حواله رفت',
      ReceiveDraftAmount: 'دریافت مبلغ حواله',
      VisitCreditorAccount: 'مشاهده حساب طلبکار',
      VisitDebtorAccount: 'مشاهده حساب بدهکار',
      AccountType: 'نوع حساب',
      CustomerType: 'نوع مشتری',
      RealCustomer: 'حقیقی',
      LegalCustomer: 'حقوقی',
      TradeRate: 'نرخ روز',
      SubmitSellCurrencySuccessfully: 'فروش ارز باموفقیت ثبت شد.',
      CashDesk: 'حساب صندوق',
      PrivatePrint: 'چاپ محرمانه',
      General: 'عمومی',
      Banking: 'بانکی',
      AccountManagement: 'مدیریت حساب',
      ReceiveDate: 'تاریخ دریافت',
      NormalBranches: 'نمایندگی های عادی',
      ConnectedBranches: 'نمایندگی های متصل',
      ChequeNumber: 'شماره چک',
      ChequeBalance: 'باقیمانده چک',
      'Total balance of accounts': 'تراز کل حساب‌ها',
      CheckoutDate: 'تاریخ تسویه',
      Deactivated: 'غیرفعال',
      In: 'در',
      SubmitConnection: 'تایید اتصال',
      AddAdditionalInformation: 'افزودن اطلاعات تکمیلی',
      BranchCurrency: 'ارز نمایندگی',
      NationalCode: 'کد ملی',
      ConnectBranchText:
        'برای اتصال لینک زیر را را برای نمایندگی ارسال کنید.(ترجیحا ایمیل شود)',
      LinkValidityText: ' اعتبار لینک زیر 10 دقیقه می‌باشد',
      SureAboutOneWayConnection: 'آیا از اتصال یک طرفه مطمعن هستید؟',
      AllowedDraftLimit: 'سقف مجاز حواله',
      MirrorBranchAccount: 'حساب نمایندگی مقابل',
      DraftNumberSettings: 'تنظیمات شماره حواله',
      View: 'مشاهده',
      OtherNumbers: 'شماره های دیگر',
      SelectGroup: 'انتخاب گروه',
      DraftRow: 'ردیف حواله',
      DraftType: 'نوع حواله',
      BusinessName: 'نام تجاری',
      'With commission only': 'فقط دارای کارمزد',
      User: 'کاربر',
      Time: 'زمان',
      explanation: 'شرح',
      City2: 'شهرستان',
      ReceivedAmount: 'مبلغ دریافتی',
      DraftNumberAlert:
        'فیلد تا شماره حواله باید بزرگتر از فیلد از شماره حواله باشد.',
      FilterBasedOnDate: 'فیلتر براساس تاریخ',
      FilterBasedOnDraftNumber: 'فیلتر براساس شماره حواله',
      VisitAccountInMirrorBranch: 'مشاهده حساب در نمایندگی مقابل',
      Canceled: 'لغوشده',
      Cancellet: 'لغوکننده',
      New: 'جدید',
      Old: 'قدیم',
      CashAccount: 'حساب نقدی',
      SameCurrencyAlert: 'ارز تراکنشهای انتخاب شده باید یکی باشد.',
      ReceiveDraftDate: 'تاریخ دریافت حواله',
      TheWayOfTransaction: 'نحوه انجام تراکنش به چه شکل باشد؟',
      PrivateDraftConduction: 'اجرای حواله محرمانه باشد',
      SelectUnexecutedDraftAlert:
        'لطفا ابتدا حواله اجرا نشده مورد نظر خود را انتخاب کنید',
      AllBranches: 'همه نمایندگی‌ها',
      BaseOnAmount: 'مبلغ محور باشه',
      BaseOnRate: 'نرخ‌محور باشه',
      RegistrationCode: 'کد ثبت',
      Email2: 'پست الکترونیکی',
      IssuerName: 'نام صادرکننده',
      IssuerDescription: 'توضیحات صادرکننده',
      OccupationTitle: 'عنوان کار',
      RegistrationDate: 'تاریخ ثبت',
      BankInformation: 'اطلاعات بانکی',
      'Customer ID': 'شناسه مشتری',
      ViewMoreInformation: 'مشاهده اطلاعات بیشتر',
      SystemNewFeatures: 'ویژگی‌های جدید سامانه',
      CommunicationType: 'نوع ارتباط',
      Close: 'بستن',
      Next: 'بعدی',
      SelectedActiveDocument: 'مدرک فعال انتخاب شده',
      SelectedActiveNumber: 'شماره فعال انتخاب شده',
      ContinueWithoutSubmitNumber: 'ادامه بدون ثبت شماره',
      ViewLessInformation: 'مشاهده کمتر اطلاعات',
      SelectAsTheActiveNumber: 'انتخاب به عنوان شماره فعال',
      AddContactInfo: 'افزودن اطلاعات تماس',
      SelectAsCurrentNumber: 'انتخاب به عنوان شماره فعلی',
      AddDocument: 'افزودن مدرک',
      IndividualJobInformation: 'اطلاعات فردی شغلی',
      AccountCode: 'کد حساب',
      PersonalInformation: 'اطلاعات فردی',
      CountryCode: 'کد کشور',
      InputAmount: 'مبلغ ورودی',
      ExcelFile: 'فایل اکسل',
      ExcelFileFormat: 'فرمت فایل اکسل',
      SaderatBank: 'بانک صادرات',
      OldFormat: 'فرمت قدیم',
      NewFormat: 'فرمت جدید',
      SepahBank: 'بانک سپه',
      UserName: 'نام کاربری',
      UploadPhoto: 'آپلود عکس',
      MaknakUsername: 'نام کاربری مکنک',
      Language: 'زبان',
      ActivateTheCamera: 'فعال‌سازی دوربین',
      StandardFormat: 'فرمت استاندارد',
      AddAttachment: 'افزودن ضمیمه',
      PunishmentsList: 'لیست تعزیرات',
      DuplicateFileNameAlert: 'فایل با نام مشابه همواره وجود دارد',
      SearchResult: 'نتیجه جستجو',
      InputCurrency: 'ارز ورودی',
      Position: 'سمت',
      shareRate: 'مقدار سهام',
      SubmitShareholder: 'ذخیره سهامدار',
      AddShareholder: 'افزودن سهامدار',
      SubmitShareholder2: 'ثبت سهامدار',
      CustomerSearch: 'جستجو مشتری',
      '404 error': 'خطای 404 - صفحه یافت نشد',
      AddToShareholders: 'افزودن به سهامداران',
      PageNotFount: 'صفحه یافت نشد.',
      PassportNumber: 'شماره گذرنامه',
      ResidenceNumber: 'شماره اقامت',
      PageNotAvailable: 'صفحه جستجو شده موجود نیست.',
      'Is the Maknak software active for the user or not?':
        'آیا نرم افزار مکنک برای کاربر فعال باشد یا نه؟',
      HasApplicationUserName: 'نام کاربری اپلیکیشن دارد',
      'Edit Occupation And Personal Details': 'ویرایش اطلاعات فردی و شغلی',
      BirthCity: 'شهر تولد',
      MainDocument: 'مدرک اصلی',
      ServiceType: 'نوع خدمات',
      CompanyType: 'نوع شرکت',
      TakePicture: 'گرفتن عکس',
      CompanyName: 'نام شرکت',
      OrganizationNumber: 'شماره تجاری',
      RegistrationNumber: 'شماره ثبت',
      OriginCountry: 'کشور مبدا',
      PaymentOrderBalance: 'باقی مانده دستور پرداخت',
      DebtorInformation: 'اطلاعات بدهکار',
      ConfirmDraftTitle: 'آیا از تایید حواله های انتخاب شده اطمینان دارید؟',
      PureProfit: 'مفاد خالص',
      Profit: 'مفاد',
      CalculatedProfitAmount: 'میزان سود محاسبه شده',
      CalculatedLossAmount: 'میزان ضرر محاسبه شده',
      Lossment: 'زیان',
      RejectDraft: 'رد حواله',
      RejectDraftReason: 'دلیل رد حواله',
      RejectDraftReasonMessage: 'علت رد حواله را بنویسید.',
      OriginCurrency: 'ارز مبدا',
      DestinationCurrency: 'ارز مقصد',
      SubmittedBy: 'ثبت شده توسط',
      CreditorInformation: 'اطلاعات طلبکار',
      PreventionCalculations: 'محاسبات پیشنهادی',
      ComputingManagement: 'مدیریت محاسبات',
      GrindingRate: 'نرخ نقدشوندگی',
      InvalidRate: 'نرخ در بازه معتبر نیست.',
      CalculateProfit: 'محاسبه سود',
      BuyAmount: 'قیمت خرید',
      BuyCurrency: 'ارز خرید',
      TotalPurchase: 'جمع کل خرید',
      CreditCollection: 'جمع طلب',
      ExecutePaymentOrder: 'اجرای دستور پرداخت',
      PaymentOrderDetails: 'جزئیات دستور پرداخت',
      PrincipalAmount: 'مبلغ اصلی',
      CardSender: 'ارسال کننده کارت',
      CurrencyAmount: 'ارز مبلغ',
      ExecuteTransaction: 'اجرای تراکنش',
      Creditors: 'طلبکاران',
      Deleted: 'حذف شده',
      Deposited: 'واریز شده',
      VisitDeposited: 'مشاهده واریز شده',
      AllCheques: 'همه چک‌ها',
      HasAssignorCommission: 'این تراکنش برای واگذارنده کارمزد دارد؟',
      HasReceiverCommission: 'این تراکنش برای تحویل گیرنده کارمزد دارد؟',
      SubmitOperation: 'ثبت عملیات',
      ChequeOperation: 'عملیات چک',
      AllOptions: 'همه موارد',
      ReceivedFrom: 'دریافت شده از',
      FilteredBy: 'فیلتر براساس',
      AllDocs: 'همه سندها',
      TransactionTypeAmount: 'باقی مانده نوع تراکنش',
      VisitCreditors: 'مشاهده طلبکاران',
      NoState: 'وضعیتی موجود نیست!',
      Card: 'کارت',
      SellCurrency: 'ارز فروش',
      MirrorBranch: 'نمایندگی مقابل',
      BankDescription: 'توضیحات بانک',
      DeterminingDraftRateValidityTime: 'تعیین زمان اعتبار نرخ حواله',
      DraftRateValidityDateTime: 'تاریخ و ساعت اعتبار نرخ حواله',
      DollarRate: 'نرخ دلار',
      IncreaseCurrencyDecimalPlaceWarning:
        'کاهش دفت رعم اعشار ارز، موجب اختلال در ویرایش تراکنش‌هایی که با این ارز انجام شده‌اند خواهد شد.',
      RateValidityTime: 'زمان اعتبار نرخ حواله',
      SystemDefaultCurrency: 'ارز پیشفرض سامانه',
      SelectAsSystemDefaultCurrency: 'انتخاب به عنوان ارز پیشفرض سامانه',
      NoSelectedCurrency: 'هیچ ارزی برای نمایش انتخاب نشده است.',
      DecimalPlace: 'رقم اعشار',
      AllCurrencies: 'همه ارزها',
      SubmitNewBank: 'ثبت بانک جدید',
      SubmitCheque: 'ثبت چک',
      SubmitPaymentOrder: 'ثبت سفارش پرداخت',
      CommissionDetails: 'جزئیات کارمزد',
      Multiplication: 'ضرب',
      Division: 'تقسیم',
      RoundationAccuracy: 'میزان دقت رندشوندگی',
      Solar: 'خورشیدی',
      DateType: 'نوع تاریخ',
      gregorian: 'میلادی',
      MoneyxSupport: 'پشتیبان ساپورت',
      AnswerText: 'متن پاسخ',
      ExchangeSettingsTitle:
        'اطلاعات این صفحات مربوط به تنظیمات صرافی شما می باشد.',
      CompletionFormsRequired: '(تکمیل فرم‌ها الزامی است)',
      SystemColorMode: 'حالت نمایش رنگ سامانه',
      SubmitYourGroups: 'گروه‌های مدنظر خود را ثبت کنید.',
      ReceiveDocFromCustomerTitle:
        '(اگر نیازمند دریافت مدارک از مشتری نمی باشید از این قسمت عبور کنید.)',
      'Organizations List': 'لیست سازمان‌ها',
      currencyName: 'نام ارز',
      ViewCurrency: 'نمایش ارز',
      WhatsappActiveDefault:
        'به صورت پیشفرض اعلان واتس اپ در تراکنش ها فعال باشد؟',
      CurrencySymbol: 'نماد ارز',
      MyAccounts: 'حساب های من',
      TimeZone: 'منطقه زمانی',
      ProfitAndLossRate: 'نرخ سود و زیان',
      PercentageChange: 'تغییر درصد',
      LongTimeWarning: 'این فرایند ممکن است کمی زمان‌بر باشد',
      DefaultPhoneNumberCode: 'پیش شماره پیش فرض',
      Slogan: 'شعار',
      ReceiptRules: 'قوانین رسید',
      AddRule: 'افزودن قانون',
      AddSameGroupsError: 'امکان ایجاد گروه‌های مشابه وجود ندارد',
      DocumentTitleError: 'عنوان مدرک نمی‌تواند خالی باشد',
      IssuingOrganizationError: 'The issuing organization cannot be empty',
      CreateSameDocumentError: 'امکان ایجاد مدرک یکسان وجود ندارد',
      CharacterLimit: 'حداکثر ۱۰۰ کاراکتر',
      HeaderUploaderTitle: 'طول و عرض تصویر سربرگ باید یکسان باشد.',
      syncCreditLimitActive: 'همگام سازی سقف اعتبار فعال باشد؟',
      IncreaseCreditLimitDescription:
        '(در صورت فعال بودن همگام سازی هر زمان سقف اعتبار مشتری ناکافی باشد به میزان مبلغ تراکنش سقف اعتبار مشتری افزایش می‌یابد.)',
      profitAndLossAccuracy: 'درصد حساسیت سود و زیان',
      DisplayDeviation:
        'درصد میزان حساسیت سامانه برای نمایش مغایرت محاسبات را مشخص کنید.',
      CashDeskName: 'اسم حساب صندوق',
      CostAccountName: 'اسم حساب هزینه',
      SubmitYourCurrencies: 'ارزهای مورد استفاده خود را ثبت کنید.',
      DeterminingDraftRate: 'تعیین نرخ حواله',
      DraftCurrencyOrder: 'ارز سفارش حواله',
      AddPaymentPlace: 'افزودن محل پرداخت',
      NoCommissions: 'کارمزدی وجود ندارد.',
      ViewHeader: 'مشاهده سربرگ',
      Capitals: 'دارایی ها',
      ReportPeriod: 'تناوب گزارش دهی',
      ExchangeName: 'نام شرکت صرافی',
      CurrentCapitals: 'دارایی های جاری',
      ConnectAccountToEquivalentAccount: 'اتصال حساب به حساب معادل',
      PrePays: 'پیش پرداخت ها',
      PrePurchaseContract: 'قراردادهای پیش خرید',
      TotalValueOfOtherAssets: 'ارزش مجموعی سایر دارایی ها',
      FixedAssets: 'دارایی‌های ثابت',
      TangibleAssets: 'دارایی های ملموس',
      ReductionInDepreciationOfTangibleAssets:
        'کاهش استهلاکات دارایی‌های ملموس',
      ReductionInDepreciationOfIntangibleAssets:
        'کاهش استهلاکات دارایی‌های غیر ملموس',
      IntangibleAssets: 'دارایی های غیر ملموس',
      Debts: 'بدهی ها',
      CurrentDebts: 'بدهی‌های جاری',
      OtherDebts: 'سایر بدهی‌ها',
      PreSaleContracts: 'قراردادهای پیش فروش',
      PayableBills: 'بل های قابل پرداخت',
      PayableTax: 'مالیات قابل پرداخت',
      OperativeExpenses: 'مصارف عملیاتی',
      TotalShareholdersEquity: 'مجموع سرمایه سهم شرکاء',
      OtherRevenue: 'سایر عواید',
      AdministrativeExpenses: 'مصارف اداری',
      depreciationExpenses: 'استهلاکات',
      telephoneExpenses: 'مصارف تیلیفون/ مکالماتی',
      rentalExpenses: 'مصارف کرایه',
      insuranceExpenses: 'بیمه',
      licensingExpenses: 'مصارف اخذ جواز',
      employeeExpenses: 'مصارف کارمندان(معاشات و سایر امتیازات)',
      otherExpenses: 'سایر مصارف',
      ReceiveExcelFile: 'دریافت فایل اکسل',
      TotalDataOutput: 'خروجی کل اطلاعات',
      SelectedDataOutput: 'خروجی اطلاعات انتخابی',
      ExitFromSystem: 'خروج از برنامه',
      PersonProfile: 'پروفایل شخص',
      ReceiveTotalDataExcel: 'دریافت اکسل کل اطلاعات',
      DataOutPutOneTimeMessage:
        'انجام این عملیات یک بار در روز امکان پذیر می باشد.',
      AutoDownloadTheExcelFile:
        'فایل اکسل خروجی، پس از آماده سازی به صورت خودکار دانلود خواهد شد.',
      ExcelFileDownloaded: 'فایل اکسل خروجی باموفقیت دانلود شد.',
      ExcelFileDownloadFailed: 'دانلود فایل اکسل با خطا مواجه شد.',
      ReceiveTotalDataExcelFile: 'دریافت اکسل کل اطلاعات',
      UserAccountSystem: 'سامانه حساب کاربری',
      GetScreenShout: 'اسکرین شات از صفحه',
      AddFile: 'در صورت لزوم فایل اضافه کنید.',
      ErrorPage: 'صفحه خطا',
      chargingSystem: 'سامانه شارژ',
      AccountingSystem: 'سامانه حسابداری',
      Asset: 'سرمایه',
      WelcomeMessage: '!به پنل حساب داری مانیکس خوش آمدید',
      PoliticalFighter: 'متبارز سیاسی',
      HighRisk: 'پرخطر',
      ModerateRisk: 'خطر متوسط',
      LowRisk: 'کم‌خطر',
      SmallBusiness: 'کسب و کار کوچک',
      BigBusiness: 'کسب و کار بزرگ',
      CurrencyAuction: 'لیلام اسعار',
      SanctionList: 'تطبیق لیست تعزیرات',
      'Central analysis of trades and financial reports':
        'مرکز تحلیل معاملات و راپورهای مالی',
      'Exchange license validation': 'اعتبارسنجی جواز',
      EnterExchangeNameMessage: 'لطفا نام صرافی خود را وارد کنید',
      'Exchange Name': 'نام صرافی',
      Is: 'میباشد',
      IsNot: 'نمیباشد',
      ShareholdersList: 'لیست سهامداران',
      EditShareholder: 'ویرایش سهامدار',
      Attachments: 'ضمیمه‌ها',
      HasCustomer: 'مشتری دارد',
      Limited: 'محدود',
      MainPhoneNumber: 'شماره اصلی',
      Canceller: 'لغوکننده',
      AllPaymentPlaces: 'همه محل پرداخت ها',
      DraftSubmissionSettings: 'تنظیمات ثبت حواله',
      StatusBySelectedFiltersAlert: 'وضعیت براساس فیلترهای انتخابی',
      OldBankTransaction: 'معامله بانکی قدیمی',
      DraftConductionEditPersonAlert:
        'مشاهده مدارک و ویرایش گیرنده تنها در صورتی که گیرنده دستی ثبت نشده باشد، ممکن است.',
      AccountOperation: 'عملیات شخص',
      SelectDraftCustomer: 'انتخاب مشتری حواله',
      AddPerson: 'افزودن شخص',
      NewBankTransaction: 'معامله بانکی جدید',
      selectTransactionType: 'نوع تراکنش را مشخص کنید.',
      ViewDocuments: 'نمایش مدارک',
      DraftInformation: 'اطلاعات حواله',
      ApplicationUsername: 'نام کاربری اپلیکیشن',
      OutgoingNumber: 'شماره رفت',
      IncomingNumber: 'شماره آمد',
      DeleteDocument: 'حذف مدرک',
      DeleteReceiver: 'حذف گیرنده',
      '(Optional)': '(اختیاری)',
      Done: 'انجام شده',
      'Under Review': 'درحال بررسی',
      registerTransactionGroups: 'ثبت گروه برای معامله ها',
      registerAccountsGroups: 'ثبت گروه برای حساب ها',
      'Real customer': 'مشتری حقیقی',
      'Legal customer': 'مشتری حقوقی',
      'Number of Rows': 'تعداد',
      SubmitPaymentPlace: 'ثبت محل پرداخت',
      EditPaymentPlace: 'ویرایش محل پرداخت',
      PaymentPlaceName: 'نام محل پرداخت',
      agentName: 'نام مسئول',
      Transactions: 'تراکنش‌ها',
      TotalCustomerCreditStatus: 'وضعیت کل اعتبار مشتری',
      FromDraftNumber: 'از شماره حواله',
      ToDraftNumber: 'تا شماره حواله',
      'Settled documents': 'اسناد تسویه‌شده',
      CanceledDrafts: 'حواله‌های لغوشده',
      Count: 'تعداد',
      'Checkout History': 'تاریخچه تسویه',
      // ******************************************
      'Last Checkout': 'آخرین تسویه',
      'Cheque History': 'تاریخچه چک',
      'Information Output': 'خروجی اطلاعات',
      'Normal Print': 'چاپ عادی',
      'Sell Currency': 'فروش ارز',
      'Buy Commission': 'کارمزد خرید',
      'Sell Commission': 'کارمزد فروش',
      'Upload Documents': 'آپلود مدارک',
      Buyer: 'خریدار',
      Seller: 'فروشنده',
      'Incoming Draft Number': 'شماره حواله آمد',
      'Outgoing Draft Number': 'شماره حواله رفت',
      Confirm: 'تایید',
      'Draft Cancellation': 'لغو حواله',
      'Draft List': 'لیست حواله',
      'Connected With Data': 'وصل شده با دیتا',
      'Retry (Application Is Not Running)':
        'دوباره کوشش کنید (برنامه فعال نیست)',
      'Connected Without Data': 'وصل نیست',
      'Synchronization Done': 'همگام سازی انجام شد',
      'Sync Account Title':
        'آیا از  همگام سازی  سقف اعتبار مشتریان اطمینان دارید؟',
      customerStatus: 'وضعیت مشتری',
      Prepayments: 'پیش پرداخت ها',
      AdvancePurchaseContracts: 'قرارداد های پیش خرید',
      Assets: 'دارایی ها',
      ReductionInTangibleAssets: 'کاهش استهلاکات دارایی های ملموس',
      DepreciationOfIntangibleAssets: 'کاهش استهلاکات دارایی های غیر ملموس',
      PayableTaxes: 'مالیات قابل پرداخت',
      AdvanceSalesContracts: 'قرارداد های پیش فروش',
      TelephoneAndCommunicationExpenses: 'مصارف تیلیفون و مکالماتی',
      RentExpenses: 'مصارف کرایه',
      Insurance: 'بیمه',
      Partners: 'شرکا',
      LicensingExpenses: 'مصارف اخذ جواز',
      EmployeeSalaries: 'معاشات کارمندان',
      ClaimsOnCentralBank: 'طلبات بالای ده افغانستان بانک',
      Vendors: 'دست فروشان',
      Permanent1: 'دایمی',
      Temporary: 'موقت',
      'Draft Settlement': 'تصفیه حواله',
      SubmitInformation: 'Submitinformation',
      SubmitAdditionalInformation: 'ثبت اطلاعات تکمیلی',
      editAdditionalInformation: 'ویرایش اطلاعات تکمیلی',
      branchTitle: 'عنوان نمایندگی',
      EditedSuccessfully: 'ویرایش  انجام شد',
      EditBasicInformation: 'ویرایش اطلاعات اولیه',
      RegisterPaymentPlace: 'ثبت محل پرداخت'
    },
    dir: 'rtl'
  },
  fap: {
    translation: {
      dir: 'rtl',
      'Display reports up to': 'د وروستي معامله وخت:',
      'Show more documents': 'نور اسناد ښکاره کړئ',
      Show: 'ښکاره کړئ',
      Search: 'لټون',
      Pages: 'مخونه',
      Alerts: 'خبرتیاوې',
      Alert: 'اعلان',
      Transaction: 'معامله',
      'Number Of Transactions': 'د معاملو شمیر',
      'Accounting Panel': 'د حسابدارۍ پینل',
      Update: 'تازه کول',
      'Add Ticket': 'ټکټ ثبت کړئ',
      TicketTitle: 'د ټکټ سرلیک',
      'Your Credit': 'ستاسې کریډیټ',
      'General Ledger': 'عمومي کتاب',
      'Journal Entries': 'ژورنال داخلونه',
      'Cash Transaction': 'نقدي معامله',
      'Bank Transaction': 'بانکي معامله',
      'Transfer Between Accounts': 'تر منځ حسابونه لیږد',
      'Foreign Exchange': 'د اسعارو تبادله',
      'Sample Person': 'د بېلګې شخص',
      'Credit Limit': 'د کریډیټ حد',
      'Account Title': 'د حساب عنوان',
      'Account Registration': 'د حساب ثبت کول',
      'Create Customer': 'نوی مشتری جوړ کړئ',
      'Site under construction or maintenance': 'سایټ د جوړونې لاندې دی...',
      'building not finished yet': 'محتوا لا هم بشپړه شوې نه ده :)',
      And: 'او',
      English: 'انګلیسي',
      Persian: 'فارسي',
      Title: 'سرلیک',
      Account: 'حساب',
      Drafts: 'حوالې',
      Draft: 'حواله',
      Equal: 'برابر',
      'Equivalent Amount': 'برابر اندازه',
      'Equivalent Amount Currency': 'د برابرو مقدار اسعار',
      'Draft Equivalent Amount': 'د حوالې برابر اندازه',
      Documents: 'اسناد',
      Reports: 'راپورونه',
      Settings: 'ترتیبات',
      Administrator: 'مدیر',
      Dashboard: 'ډشبورډ',
      Welcome: 'ښه راغلاست',
      'Moneyx Accounting Panel': 'د مانیکس حسابدارۍ پینل',
      'Moneyx Exchange Accounting Software': 'د مانیکس حسابدارۍ سافټویر',
      'First Name': 'لومړی نوم',
      'Middle Name': 'منځنی نوم',
      'Last Name': 'تخلص',
      Gender: 'جنس',
      Undefined: 'نامعلوم',
      Unknown: 'نامعلوم',
      Known: 'معلوم',
      Male: 'نارینه',
      Female: 'ښځه',
      'Phone Number': 'د تلیفون شمېره',
      'Language Selection': 'د ژبې انتخاب',
      'Transfer Owners': 'د انتقال مالکین',
      Description: 'تشریح',
      Return: 'بېرته راستنېدل',
      'Register New Customer': 'نوی مشتری ثبت کړئ',
      'Basic Information': 'بنسټیز معلومات',
      BranchBasicInformation: 'د څانګې بنسټیز معلومات',
      CustomerBasicInformation: 'د مشتری بنسټیز معلومات',
      Colon: ':',
      'Account Number': 'د حساب شمېره',
      'Add Account': 'حساب اضافه کړئ',
      AddNewAccount: 'نوی حساب اضافه کړئ',
      AddNewBank: 'نوی بانک اضافه کړئ',
      AddBankAccount: 'د بانک حساب اضافه کړئ',
      'Occupation And Personal Details': 'شخصي او مسلکي معلومات',
      'Add Address': 'پته اضافه کړئ',
      AddNewAddress: 'نوی پته اضافه کړئ',
      SubmitNewAddress: 'نوی پته ثبت کړئ',
      SubmitNewPhoneNumber: 'نوی تلیفون شمېره ثبت کړئ',
      AddNewPaymentPlace: 'نوی د تادیې ځای اضافه کړئ',
      AddNewBranch: 'نوی څانګه اضافه کړئ',
      AddNewContactDetails: 'نوی د اړیکو معلومات اضافه کړئ',
      AddNewCustomer: 'نوی مشتری اضافه کړئ',
      'Bank Information': 'د بانک معلومات',
      Attachment: 'ضمیمه',
      'Birth Place Country': 'د زیږیدو هیواد',
      Nationality: 'تابعیت',
      'Birth Place': 'د زیږیدو ځای',
      'Birth Date': 'د زیږیدو نېټه',
      Email: 'بریښنالیک',
      Website: 'وېب پاڼه',
      Occupation: 'مسلک',
      'Other Occupation': 'نور مسلک',
      'Occupation Branch': 'د مسلک څانګه',
      'Economic Registering Number': 'د اقتصادي ثبت شمېره',
      Continue: 'ادامه',
      England: 'انګلینډ',
      Iranian: 'ایرانی',
      Programmer: 'برنامې جوړونکی',
      Employee: 'کارمند',
      Country: 'هیواد',
      Province: 'ولایت',
      City: 'ښار',
      'Zip Code': 'پوستی کوډ',
      'Exact Address': 'کره پته',
      Tehran: 'تهران',
      London: 'لندن',
      'Currency Type': 'د اسعارو ډول',
      'Account Owner Name': 'د حساب مالک نوم',
      'Account Owner': 'د حساب مالک',
      'Total Deposit Amount': 'د جمعې اندازه',
      'Branch Code': 'د څانګې کوډ',
      'Swift Code': 'سویفت کوډ',
      IBAN: 'آیبان',
      Register: 'ثبت کول',
      Rial: 'ریال',
      Pond: 'پونډ',
      CEO: 'اجرائیه رئیس',
      'Document Type': 'د سند ډول',
      'Document Type2': 'د سند ډول',
      'Document Number': 'د سند شمېره',
      'Document Number2': 'د سند شمېره',
      'Receive Document Date': 'د سند ترلاسه کولو نېټه',
      'Due Date': 'د سررسید نېټه',
      'Due Date Of Document': 'د سند د سررسید نېټه',
      'Expire Date': 'د ختمیدو نېټه',
      'Issue Date': 'د صدور نېټه',
      Profile: 'پروفایل',
      'Sign Out': 'وتل',
      'Document Status': 'د سند حالت',
      Permanent: 'دایمي',
      Current: 'فعلي',
      'Type 1': 'ډول ۱',
      'Type 2': 'ډول ۲',
      'Drop Your Desired File Here': 'خپل مطلوب فایل دلته راوغورځوئ',
      Or: 'یا',
      Choose: 'انتخاب کړئ',
      'Valid Formats': 'مجاز فورمې',
      Application: 'غوښتنلیک',
      'Application Settings': 'د غوښتنلیک ترتیبات',
      'Edit Number': 'شمېره ویرایش کړئ',
      'Connected Account': 'متصل حساب',
      'Block Access To Application': 'غوښتنلیک ته لاسرسی بند کړئ',
      'Send Notification Via': 'اعلان له لارې واستوئ',
      SMS: 'پیغام',
      Telegram: 'تلګرام',
      Whatsapp: 'واټساپ',
      'Accept And Register': 'قبول او ثبت کړئ',
      Customers: 'مشتریان',
      'Customers List': 'د مشتریانو لیست',
      'Draft Orders List': 'د حوالې فرمایشونو لیست',
      Customer: 'مشتری',
      List: 'لیست',
      'Register Customer': 'مشتری ثبت کړئ',
      'Select Customer': 'مشتری انتخاب کړئ',
      'Sign in to your account to continue': 'د دوام لپاره خپل حساب ته ننوځئ.',
      'Welcome back': 'بیا ښه راغلاست',
      None: 'نامعلوم',
      'Email Address': 'بریښنالیک پته',
      Password: 'پټنوم',
      'Sign in': 'ننوتل',
      'Remember me': 'ما په یاد ولره',
      'Forgot password': 'پټنوم مو هیر شوی؟',
      Use: 'وکاروئ',
      and: 'او',
      'to sign in': 'د ننوتلو لپاره',
      Moneyx: 'مانیکس',
      'Exchange Accounting Software': 'د مانیکس صرافۍ حسابدارۍ سافټویر',
      Balance: 'باقي اندازه',
      CalculationBalance: 'د باقی محاسبه روانه ده',
      BalanceCurrency: 'باقي اسعار',
      FinancialInformation: 'مالي معلومات',
      Inventory: 'ذخیره',
      'Sample Balance': '5/435/000',
      'Sample Balance Currency': 'تومان',
      'Complete Customer Information': 'د مشتری معلومات بشپړ کړئ',
      'Next Step': 'بل ګام',
      Sent: 'استول شوی',
      'Not Sent': 'نه دی استول شوی',
      'Customer Information': 'د مشتری معلومات',
      All: 'ټول',
      Currencies: 'اسعار',
      SelectedCurrenciesList: 'د انتخاب شویو اسعارو لیست',
      'Draft Submission': 'حواله ثبت کړئ',
      Branch: 'څانګه',
      Sender: 'لېږدونکی',
      Receiver: 'ترلاسه کونکی',
      'Draft Currency': 'د حوالې اسعار',
      'Draft Amount': 'د حوالې اندازه',
      'Company Registration': 'شرکت ثبت کړئ',
      'Contact Details': 'د اړیکو معلومات',
      Address: 'پته',
      Addresses: 'پته ګانې',
      Shareholders: 'سهامي شریکان',
      'Add/Update Shareholders': 'سهامي شریکان اضافه/تازه کړئ',
      'Receivers List': 'د ترلاسه کونکو لیست',
      'Receiver Registration': 'ترلاسه کونکی ثبت کړئ',
      Accept: 'قبول کړئ',
      'Customer Registration': 'مشتری ثبت کړئ',
      'Search By': 'لټون له لارې',
      Name: 'نوم',
      Comma: '،',
      'Document Prime': 'اصلي سند',
      DocumentName: 'د سند نوم',
      'Advanced Search': 'پرمختللی لټون',
      'Customer Number': 'د مشتری شمېره',
      'Customer Name': 'د مشتری نوم',
      CustomerPhoneNumber: 'د مشتری تلیفون شمېره',
      ReceiverName: 'د ترلاسه کونکی نوم',
      Group: 'ډله',
      Groups: 'ډلې',
      'Father Name': 'د پلار نوم',
      'Account History': 'د حساب تاریخچه',
      'Applications Settings': 'د غوښتنلیکونو ترتیبات',
      'Draft Receivers': 'د حوالې ترلاسه کوونکي',
      'Send Message': 'پیغام واستوئ',
      'Customer Full Details': 'د مشتری بشپړ معلومات',
      'Download Customers List Summary': 'د مشتریانو لنډیز لیست ډاونلوډ کړئ',
      'Download Customers Full List': 'د مشتریانو بشپړ لیست ډاونلوډ کړئ',
      'Document Expiry Date': 'د سند ختمیدو نېټه',
      'Total Debt In Dollars': 'په ډالرو کې ټول پور',
      To: 'تر',
      Select: 'انتخاب کړئ',
      Collateral: 'تضمین',
      'Communication Way': 'د اړیکو طریقه',
      Enable: 'فعال کړئ',
      Webcam: 'ویبکام',
      Upload: 'اپلوډ کړئ',
      Photo: 'عکس',
      'Add New Phone Number': 'نوی تلیفون شمېره اضافه کړئ',
      'At Least One Number Is Mandatory': 'لږترلږه یوه شمېره اړینه ده',
      'Phone Number Cannot Be Repeated.': 'شمېره نشي تکرارېدی.',
      'Register Customer Without Additional Information':
        'مشتری ثبت کړئ پرته له اضافي معلوماتو',
      'Wholesale Exchange': 'عمده تبادله',
      'Retail Exchange': 'پرچون تبادله',
      Create: 'جوړ کړئ',
      'System Management': 'د سیستم مدیریت',
      Business: 'سوداګري',
      'Create Group': 'ډله جوړ کړئ',
      'System Settings': 'د سیستم ترتیبات',
      'System Rate': 'د سیستم نرخ',
      'Advanced Settings': 'پرمختللي ترتیبات',
      'Initial Settings': 'لومړني ترتیبات',
      'Header Settings': 'د سرلیک ترتیبات',
      'Add Currency': 'اسعار اضافه کړئ',
      'Update Currency': 'اسعار تازه کړئ',
      'Upload a logo for the header': 'لوګو د سرلیک لپاره اپلوډ کړئ',
      Debtor: 'پورمن',
      DebtorSum: 'د پور ټولګه',
      CreditorSum: 'د طلبکار ټولګه',
      'List Of Most Debtors': 'د زیاترو پورمنانو لیست',
      Creditor: 'طلبکار',
      'Create Currency': 'اسعار جوړ کړئ',
      Normal: 'عادي',
      Other: 'نور',
      Dollar: 'ډالر',
      'Account Remaining': 'د حساب پاتې اندازه',
      Remaining: 'پاتې',
      From: 'له',
      Date: 'نېټه',
      'Balance Currency Type': 'د پاتې اسعارو ډول',
      'Mobile Number': 'د موبایل شمېره',
      'Account Details': 'د حساب معلومات',
      'Banks List': 'د بانکونو لیست',
      'Banks Management': 'د بانکونو مدیریت',
      Row: 'لیست',
      Add: 'اضافه کول',
      'New Bank': 'نوی بانک',
      'Bank Name': 'د بانک نوم',
      'Card Number': 'د کارت شمېره',
      'Bank Branch Code': 'د بانک څانګې کوډ',
      Status: 'حالت',
      OverallStatus: 'ټولیز حالت',
      ChequeOverallStatus: 'د چک ټولیز حالت',
      SubmitChequeInMoneyx: 'چک په مانیکس کې ثبت کړئ',
      DebtCollection: 'د پور ټولګه',
      Active: 'فعال',
      Archived: 'ارشیف شوی',
      'Bank Book': 'د بانک کتاب',
      'No Bank Is Registered': 'هیڅ بانک ثبت نه دی',
      'No Submited Transaction': 'هیڅ معامله ثبت نه ده',
      Bank: 'بانک',
      Edit: 'ویرایش',
      Yes: 'هو',
      IncreaseCredit: 'د کریډیټ زیاتوالی',
      CancelDraft: 'حواله لغوه کړئ',
      'Edit Draft': 'حواله ویرایش کړئ',
      'Edit Transaction': 'معامله ویرایش کړئ',
      'Edit Bank Transaction': 'بانکي معامله ویرایش کړئ',
      'Submit Edit': 'ویرایش ثبت کړئ',
      'Archiving The Account': 'حساب ارشیف کړئ',
      'Cash withdrawal': 'نغدي پیسې اخیستل',
      'Cash deposit': 'نغدي پیسې جمع کړئ',
      Deposit: 'جمع کول',
      Withdraw: 'بیرته اخیستل',
      Deposit2: 'جمع کول',
      Withdraw2: 'بیرته اخیستل',
      'Search In Moneyx': 'په مانیکس کې لټون وکړئ',
      'Quick Access': 'چټک لاسرسی',
      'Print Information': 'معلومات چاپ کړئ',
      'Upload File': 'فایل اپلوډ کړئ',
      'Transaction Description': 'د معاملې تشریح',
      'Receipt Number': 'د رسید شمېره',
      Receipt: 'رسید',
      ReceiptWithHeader: 'د سرلیک سره رسید',
      ReceiptWithoutHeader: 'رسید پرته له سرلیک',
      'Transaction Amount': 'د معاملې اندازه',
      Messages: 'پیغامونه',
      Notifications: 'اعلانونه',
      Languages: 'ژبې',
      'Account Balance': 'د حساب پاتې اندازه',
      HasAccountInitialBalance: 'آیا حساب ابتدائیه موجودی لري؟',
      IsAccountHidden: 'د حساب له نورو کاروونکو څخه پټول',
      'Cash Desk': 'صندوق',
      'System Accounts': 'سیستم حسابونه',
      Cash: 'نغدي',
      'Cash Name': 'د صندوق نوم',
      Costs: 'لګښتونه',
      Commission: 'کمیشن',
      creditaccount: 'کریډیټ حساب',
      UnknownCreditAccount: 'نامعلوم کریډیټ حساب',
      unknowncreditaccount: 'نامعلوم کریډیټ حساب',
      'Unexecuted draft account': 'نه اجرا شوی حواله حساب',
      'unexecuted draft account': 'نه اجرا شوی حواله حساب',
      'Account Information': 'د حساب معلومات',
      Accounts: 'حسابونه',
      ChargingSale: 'د خرڅلاو چارج',
      UnrealizedDraft: 'نا اجرا شوی حواله',
      'Unrealized Drafts': 'نا اجرا شوي حوالې',
      UnexecutedDraft: 'نا اجرا شوی حواله',
      BankPayment: 'بانکي تادیه',
      'No Option Message': 'هیڅ اختیار شتون نلري',
      'No Content Message': 'هیڅ محتوا شتون نلري',
      'Branch Registration': 'څانګه ثبت کړئ',
      'Branch Edition': 'څانګه ویرایش کړئ',
      'Advanced Details': 'پرمختللي معلومات',
      'Drafts List': 'د حوالو لیست',
      'Draft Documents List': 'د حوالو اسنادو لیست',
      'Unrealized Drafts List': 'نا اجرا شوی حوالو لیست',
      'Pending Drafts List': 'په انتظار حوالې',
      'Branches List': 'د څانګو لیست',
      'Branch List': 'د څانګې لیست',
      'Trade Currency': 'د اسعارو تجارت',
      'Currencies Management': 'د اسعارو مدیریت',
      'Branch Account': 'د څانګې حساب',
      'Credits Account': 'د اعتباري حساب',
      'Customer Credit Account': 'د مشتری اعتباري حساب',
      'Unknown Bank Deposits': 'نامعلوم بانکي پیسې',
      'Submit Transaction': 'معامله ثبت کړئ',
      'Submit Cash Transaction': 'نقدي معامله ثبت کړئ',
      'Submit Bank Transaction': 'بانکي معامله ثبت کړئ',
      'New Submit Bank Transaction': 'نوې بانکي معامله ثبت کړئ',
      SubmitInterBankTransactionSuccessfully:
        'بین بانکي معامله په بریالیتوب سره ترسره شوه',
      'Register Person': 'شخص ثبت کړئ',
      'Edit Person': 'شخص ویرایش کړئ',
      'Edit Company': 'شرکت ویرایش کړئ',
      CommonAccount: 'عام حساب',
      BranchAccount: 'څانګه حساب',
      BankAccount: 'بانکي حساب',
      NotHave: 'نه لري',
      'Aggregation Of Fees': 'د فیسونو ټولګه',
      SubmitNewBankAccount: 'نوی بانکي حساب ثبت کړئ',
      NewBankAccount: 'نوی بانکي حساب',
      Operation: 'عملیات',
      RepeatOperation: 'عملیات تکرار کړئ',
      'Operation Type': 'د عملیات ډول',
      'Unknown Bank Transactions': 'نامعلوم بانکي معاملات',
      customer: 'مشتری',
      receiver: 'ترلاسه کونکی',
      'Interbank Transaction': 'بین بانکي معامله',
      'Outgoing Draft Submission': 'د صادر شوي حوالې ثبت',
      'Incoming Draft Submission': 'د وارد شوي حوالې ثبت',
      'Miscellaneous Draft Submission': 'متفرقه حواله ثبت',
      'Draft Conduction': 'د حوالې اجرا',
      'Cheques List': 'د چکونو لیست',
      'Debtor And Creditor List': 'د پورمن او طلبکار لیست',
      'Commissions Report': 'د کمیشن راپور',
      'Trade Currencies List': 'د اسعارو تجارت لیست',
      SavedToCashDeskOrBankAccount: 'په نغدي صندوق یا بانک کې ثبت شوی',
      SavedToCustomerAccount: 'مشتری ته وسپارل شو',
      HasReturned: 'بېرته راستون شوی',
      CashedToMoney: 'نغد شوی',
      'Cashed to money': 'نغد شوی',
      'cashed to money': 'نغد شوی',
      UnknownBankAccounts: 'نامعلوم بانکي حسابونه',
      'Profit And Loss Statement': 'د ګټې او زیان راپور',
      'Calculating Profit and Loss': 'ګټه او زیان محاسبه',
      'Payment Order': 'د تادیې فرمایش',
      profile: 'پروفایل',
      'Operation of the day': 'د ورځې عملیات',
      DocumentIsPrivate: 'سند شخصي دی',
      'Net Profit': 'خالصه ګټه',
      'Gross Profit': 'ناخالصه ګټه',
      Loss: 'زیان',
      Cost: 'لګښت',
      Delete: 'حذف کړئ',
      'no attachments': 'هیڅ ضمیمه نشته',
      'The Entered Amount Is Bigger Than The Sum Of The Payment Order':
        'وارد شوی مقدار د تادیې فرمایش ټولګې څخه زیات دی',
      OK: 'هو',
      Cancel: 'لغوه کول',
      Clear: 'پاکول',
      'System Guides': 'د سیستم لارښوونې',
      'System Main Currency': 'د سیستم اصلي اسعار',
      'Increase Customer Credit': 'د مشتری کریډیټ زیات کړئ',
      'Date And Time': 'نېټه او وخت',
      AllEmployees: 'ټول کارمندان',
      ViewGroup: 'ډله وګورئ',
      DataEntry: 'د معلوماتو داخلول',
      HaveAccount: 'حساب لري',
      BranchStatus: 'د څانګې حالت',
      DraftDescription: 'د حوالې تشریح',
      'Credit Currency': 'د کریډیټ اسعار',
      'Credit Amount': 'د کریډیټ اندازه',
      'Transaction Information': 'د معاملې معلومات',
      'Credit Registration': 'کریډیټ ثبت کړئ',
      'Reported Bugs': 'راپور شوي نیمګړبیافافباغقغغتیاوې',
      'Excel Data Output': 'د ایکسل معلوماتو آوټپټ',
      'Bug Details': 'د نیمګړتیاوو جزئیات',
      'My Tickets': 'زما ټکټونه',
      'From Date': 'له نېټې څخه',
      'To Date': 'تر نېټې پورې',
      Failed: 'ناکام شوی',
      Pend: 'په انتظار',
      Success: 'بریالی',
      'Request Status': 'د غوښتنې حالت',
      'Pending Transactions List': 'د انتظار معاملو لیست',
      'Submitted By Employee': 'د کارمند لخوا ثبت شوی',
      AllCashDesks: 'همه صندوق‌ها',
      SelectDocument: 'انتخاب سند',
      SelectDocumentAndSubmit: 'سند مورد نظر را انتخاب و سپس تایید را بزنید.',
      'Credits on Da Afghanistan Bank': 'طلبات بالای ده افغانستان بانک',
      'Submitted By Customer': 'د مشتری لخوا ثبت شوی',
      'Submitted By Mirror Branch': 'د مقابلې څانګې لخوا ثبت شوی',
      Number: 'شمېره',
      numbers: 'شمېرې',
      'Total Numbers': 'ټول شمېرې',
      Detail: 'تفصیل',
      ShowDetails: 'تفصیل ښکاره کړئ',
      ShowBrief: 'لنډ تفصیل ښکاره کړئ',
      SeeDetails: 'تفصیل وګورئ',
      'From Number': 'له شمېرې څخه',
      'To Number': 'تر شمېرې پورې',
      SubmitTransfer: 'لېږد ثبت کړئ',
      Reject: 'رد کړئ',
      'Remove Filters': 'فیلټرونه لرې کړئ',
      'Creditor Customer': 'طلبکار مشتری',
      'Debtor Customer': 'پورمن مشتری',
      Recieve: 'ترلاسه کول',
      'Bank Transaction Is Confidential': 'بانکي معامله محرم ده',
      'Transaction Currency': 'د معاملې اسعار',
      'Commission Amount': 'د کمیشن اندازه',
      'Commission Creditor Amount': 'د طلبکار کمیشن اندازه',
      'Is There A Fee For This Transaction': 'آیا دې معاملې لپاره فیس شته؟',
      UncertainCreditLimit:
        'که کریډیټ حد نه وي ټاکل شوی، صفر په پام کې نیول کیږي',
      Incoming: 'وارد شوی',
      Outgoing: 'صادر شوی',
      'Account Name': 'د حساب نوم',
      'Employee Name': 'د کارمند نوم',
      Type: 'ډول',
      'Visit Account': 'حساب وګورئ',
      VisitCustomerAccount: 'د مشتری حساب وګورئ',
      VisitCashAccount: 'د نغدي حساب وګورئ',
      VisitBankAccount: 'بانکي حساب وګورئ',
      visitCustomerCreditAccount: 'د مشتری کریډیټ حساب وګورئ',
      VisitUnknownBankTransactionsAccount: 'نامعلوم بانکي معاملو حساب وګورئ',
      VisitDestinationBankBook: 'د مقصد بانک کتاب وګورئ',
      VisitSourceBankBook: 'د سرچینې بانک کتاب وګورئ',
      DestinationBranchAccount: 'د څانګې مقصد حساب',
      SourceBranchAccount: 'د څانګې سرچینه حساب',
      // ************************ end default document types **************************
      // ************************ phrase items translations **************************
      'transfer between accounts': 'انتقال تر منځ حسابونه',
      'transaction id': 'د معاملو شمېره',
      'transaction id:': 'د معاملو شمېره:',
      'transfer between accounts with': 'انتقال تر منځ حسابونه له',
      amount: 'اندازه',
      currency: 'اسعار',
      CurrencyRate: 'د اسعارو نرخ',
      DecimalPlacesCalculation: 'څو اعشاریه ځایونه محاسبه شي؟',
      CalculationType: 'د محاسبې ډول',
      benchMarkCurrency: 'معیاري اسعار',
      'Bank Currency': 'د بانک اسعار',
      'Account Status': 'د حساب حالت',
      debtor: 'پورمن',
      creditor: 'طلبکار',
      Percent: 'سلنه',
      Liquidity: 'نقدیني',
      Today: 'نن',
      'Until Today': 'تر نن پورې',
      'outgoing draft order': 'صادر شوی حواله',
      'incoming draft order': 'وارد شوی حواله',
      IncomingDrafts: 'وارد شوي حوالې',
      OutgoingDrafts: 'صادر شوي حوالې',
      UnexecutedDrafts: 'نه اجرا شوي حوالې',
      ExecutedDrafts: 'اجرا شوي حوالې',
      AllDrafts: 'ټولې حوالې',
      'Update Check Rate': 'د حوالې نرخ ویرایش کړئ',
      'Add Payment Place': 'د تادیې ځای اضافه کړئ',
      'Payment Place': 'د تادیې ځای',
      to: 'ته',
      from: 'له',
      at: 'په',
      'was registered.': 'ثبت شو.',
      'commission for': 'د کمیشن لپاره',
      'bank account': 'بانکي حساب',
      'customer account': 'د مشتری حساب',
      'sell currency': 'اسعار وپلورئ',
      'buy currency': 'اسعار واخلئ',
      'bank transaction': 'بانکي معامله',
      CustomerAccountCode: 'د مشتری حساب کوډ',
      commission: 'کمیشن',
      'cash transaction': 'نقدي معامله',
      unrealizeddraft: 'نه اجرا شوي حوالې',
      unexecuteddraft: 'نه اجرا شوي حوالې',
      unknownbankaccounts: 'نامعلوم بانکي حسابونه',
      chargingsale: 'د خرڅلاو چارج',
      'buy charge': 'د پیرود چارج',
      cash: 'نغدي',
      costs: 'لګښتونه',
      Documentations: 'مستندات',
      Others: 'نور',
      'Undo Cancellation': 'لغو بېرته راوګرځوئ',
      'New Version Available Phrase':
        'د سیستم نوې نسخه موجوده ده؛ لطفاً پاڼه تازه کړئ',
      OnlyWithAccountTitle: 'یوازې د حساب په عنوان',
      // ************************ end phrase items translations **************************
      // ***********************************
      // countries
      // ***********************************
      Afghanistan: 'افغانستان',
      Australia: 'آسټرالیا',
      China: 'چین',
      Denmark: 'ډنمارک',
      Germany: 'المان',
      Greece: 'یونان',
      Iran: 'ایران',
      Iraq: 'عراق',
      Norway: 'ناروې',
      Pakistan: 'پاکستان',
      'Saudi Arabia': 'سعودي عربستان',
      Sweden: 'سویډن',
      Turkey: 'ترکیه',
      'United Arab Emirates': 'متحده عربي امارات',
      Baghdad: 'بغداد',
      Ankara: 'انقره',
      Kabul: 'کابل',
      'Islamabad, Karachi': 'اسلام آباد، کراچۍ',

      // Action Types
      TransferBetweenTwoAccounts: 'انتقال تر منځ دوه حسابونه',
      Cheque: 'چک',
      ChequeStatus: 'د چک حالت',
      ChequeCurrentStatus: 'د چک فعلي حالت',
      ConsideringCheque: 'د چک په حساب کې نیول',
      CashTransaction: 'نقدي معامله',
      IncomingDraft: 'وارد شوی حواله',
      OutgoingDraft: 'صادر شوی حواله',
      Miscellaneous: 'متفرقه',
      BuySellCurrency: 'اسعار اخیستل او پلورل',
      BankTransaction: 'بانکي معامله',
      RemoveCheckout: 'تسویه حذف کړئ',
      PendDraft: 'په انتظار حواله',
      RemovePendDraft: 'په انتظار حواله حذف کړئ',
      ExecuteDraft: 'حواله اجرا کړئ',
      InterBankTransaction: 'بین بانکي معامله',
      Checkout: 'تسویه',
      PaymentOrderReceipt: 'د تادیې فرمایش رسید',
      ChangeBranch: 'د نرخ تبدیلول',
      UnknownBankTransaction: 'نامعلومه بانکي معامله',
      UpdateAccount: 'حساب ویرایش کړئ',
      DeleteAccount: 'حساب حذف کړئ',
      AddBank: 'بانک اضافه کړئ',
      UpdateBank: 'بانک ویرایش کړئ',
      DeleteBank: 'بانک حذف کړئ',
      AddCustomer: 'مشتری اضافه کړئ',
      UpdateCustomer: 'مشتری ویرایش کړئ',
      DeleteCustomer: 'مشتری حذف کړئ',
      AddCompany: 'شرکت اضافه کړئ',
      UpdateCompany: 'شرکت ویرایش کړئ',
      DeleteCompany: 'شرکت حذف کړئ',
      AddBranch: 'نمایندګي اضافه کړئ',
      UpdateBranch: 'نمایندګي ویرایش کړئ',
      DeleteBranch: 'نمایندګي حذف کړئ',
      ConnectToBranch: 'نمایندګۍ سره اتصال وکړئ',
      OneWayConnecting: 'یوه طرفه اتصال',
      BranchName: 'د نمایندګۍ نوم',
      BranchManager: 'د نمایندګۍ مدیر',
      BranchIsNotFound: 'نمایندګي ونه موندل شوه',
      SelecTheDesiredBranch: 'د مطلوبه نمایندګۍ انتخاب وکړئ',
      InOneWayConnectionModeTheCounterpartyIsNotAblleToSeeYoureTransactions:
        'په یوه طرفه اتصال کې مقابل طرف ستاسو معاملات نشي لیدلی',
      AddCurrency: 'اسعار اضافه کړئ',
      UpdateCurrency: 'اسعار ویرایش کړئ',
      DeleteCurrency: 'اسعار حذف کړئ',
      AddPayOrder: 'د تادیې فرمایش اضافه کړئ',
      UpdatePayOrder: 'د تادیې فرمایش ویرایش کړئ',
      DeletePayOrder: 'د تادیې فرمایش حذف کړئ',
      PublishTransactionInWhatsapp: 'معامله په واتساپ اعلان کړئ',
      TransactionBeWithoutDescription: 'معامله دې پرته له توضیحاتو وي',
      CashTransactionWithoutDescription: 'نقدي معامله پرته له توضیحاتو وي',
      SyncCreditLimit: 'د کریډیټ حد همغږي کړئ',
      ShouldHaveSamevaluein: 'باید په کې یو شان ارزښتونه ولري',
      'In progress': 'په جریان کې',
      'private transaction': 'معامله پرته له توضیحاتو',
      'Invalid date': 'نېټه ناسم ده',
      'Required field message': 'دا فیلډ ډکول اړین دي',
      uploaderInputLabelMaximumSize: 'د فایل اندازه باید اعظمي ۳ میګابایټه وي',
      ExcelUploaderInputLabelFormat: 'باید د ایکسل فارمیټ xlsx وي',
      FileUploaderInputLabelFormat:
        'فایل باید د xls, xlsx, pdf, png, jpg, jpeg, doc, docx, webp فارمیټونو څخه وي',
      WhatsAppCommunication: 'د واتساپ لینک یا آدرس',
      DocumentTypeDescription: 'د سند ډول توضیحات',
      CommissionAmountRegisteredToCreditor:
        'د طلبکار حساب ته د کمیشن اندازه ثبت کیږي',
      CommissionAmountRegisteredToDebtor:
        'د پورمن حساب ته د کمیشن اندازه ثبت کیږي',
      'The commission amount must be less than the transaction amount':
        'د کمیشن اندازه باید د معاملې له اندازې څخه کمه وي',
      SelectItemToAutoCalculate: 'کوم آیتم په اوتومات ډول محاسبه شي؟',
      'Value must be greater than zero': 'ارزښت باید له صفر څخه زیات وي',
      'File required': 'فایل اضافه کول اړین دي',
      'no future dates message': 'نېټه نشي کولی له نن ورځې لویه وي',
      'The email is invalid': 'ایمیل ناسم دی',
      'Invalid website message': 'ویب‌سایټ ناسم دی',
      'Number message': 'یوازې له عددونو کار واخلئ',
      'Existed username message': 'کارن نوم تکراري دی',
      'Numbers and English letters message':
        'یوازې ارقام او انګلیسي حروف د منلو وړ دي',
      'Capital English letters message': 'یوازې انګلیسي غټې حروف معتبر دي',
      'Uncheck message': 'که معلومات ثبت نه دي، تیک لرې کړئ',
      'Cheque date message': 'د چک نېټه نشي کولی د سررسید له نېټې څخه کوچنۍ وي',
      'Iban message':
        'IBAN باید دقیقاً 26 کرکتره وي او یوازې انګلیسي عددونه او حروف معتبر وي',
      'Card number message': 'د کارت شمېره باید یوازې عددونه وي او 16 رقمه وي',
      'Other occupation message':
        'کله چې شغل "نور" انتخاب شي، دا فیلډ ډکول اړین دي',
      'Customer age limit message': 'د مشتری عمر باید له ۱۰ کلونو څخه زیات وي',
      'Issue date message': 'د صدور نېټه نشي کولی له نن ورځې لویه وي',
      'Expire date message': 'د ختمیدو نېټه باید له صدور نېټې لویه وي',
      'Phone number minimum length message':
        'د تلیفون شمېره باید له 9 کرکتره زیاته وي',
      'Phone number maximum length message':
        'د تلیفون شمېره باید له 15 کرکتره کمه وي',
      'Phone number start message': 'د تلیفون شمېره باید له صفر سره پیل نه شي',
      'Max number message': 'شمېره باید د صفر او سل تر منځ وي',
      'Invalid trade rate': 'نرخ په معتبره کچه کې نه دی',
      'Required currency': 'د اسعارو انتخاب اړین دی',
      InsertResidenceNumberMessage:
        'د اقامت شمېره د مشتری ویرایش برخه کې دننه کړئ',
      HasUserName: 'کارن نوم لري',
      InstantAccountAddition: 'ژر حساب اضافه کړئ',
      PersianUserNameError: 'کارن نوم باید په فارسي نه وي',
      DuplicatedPhoneNumberError: 'دا تلیفون شمېره مخکې ثبت شوې ده',
      RegisterCompanyWithoutAdditionalInformation:
        'شرکت د اضافي معلوماتو پرته ثبت کړئ',
      SaveInformation: 'آیا معلومات خوندي شي؟',
      AddNewDocument: 'نوی سند اضافه کړئ',
      SelectAsActiveDocument: 'انتخاب د فعال سند په توګه',
      SelectAsMainPhoneNumber: 'انتخاب د اصلي تلیفون شمېره په توګه',
      SelectAsActiveAddress: 'انتخاب د فعال پته په توګه',
      ChangeActiveDocumentSuccessfully: 'فعال سند په بریالیتوب سره بدل شو',
      SubmitNewDocument: 'نوی سند ثبت کړئ',
      ContinueWithoutSubmitDocument: 'پرته له ثبت سند دوام ورکړئ',
      SelectedReferenceDocument: 'مرجع سند انتخاب شوی',
      SelectAsReferenceDocument: 'دا سند د مرجع په توګه انتخاب کړئ',
      ContinueWithoutSubmitAccount: 'پرته له ثبت حساب دوام ورکړئ',
      ContinueWithoutSubmitBankInformation:
        'پرته له ثبت بانکي معلومات دوام ورکړئ',
      SubmitNewAccount: 'نوی حساب ثبت کړئ',
      'Continue without submit attachment': 'پرته له ثبت ضمیمه دوام ورکړئ',
      'Submit new attachment': 'نوی ضمیمه ثبت کړئ',
      AddNewAttachment: 'نوی ضمیمه اضافه کړئ',
      NoShareholders: 'هیڅ سهامدار د شرکت لپاره انتخاب نه دی شوی',
      NoBalanceIsThere: 'هیڅ موجودي ثبت نه ده',
      SelectShareholder: 'اوس سهامداران انتخاب کړئ',
      AddToShareholdersList: 'د سهامدارانو لیست ته اضافه کړئ',
      DeleteUnexecutedDraftTitle: 'آیا د نه اجرا شوي حوالې حذفولو ډاډ لرئ؟',
      DeleteDraftTitle: 'آیا د حوالې حذفولو ډاډ لرئ؟',
      DeleteBankAccountTitle: 'آیا د بانکي حساب حذفولو ډاډ لرئ؟',
      DeleteDraftOrderTitle: 'آیا د حوالې فرمایش حذفولو ډاډ لرئ؟',
      DeleteTradeCurrencyTitle: 'آیا د اسعارو پیرود او پلور حذفولو ډاډ لرئ؟',
      DeleteSystemAccountTitle: 'آیا د سیستم حساب حذفولو ډاډ لرئ؟',
      DeleteReceiverTitle: 'آیا د ترلاسه کوونکي حذفولو ډاډ لرئ؟',
      DeleteDocumentTitle: 'آیا د سند حذفولو ډاډ لرئ؟',
      DeleteAddressTitle: 'آیا د پته حذفولو ډاډ لرئ؟',
      DeleteAttachmentTitle: 'آیا د ضمیمې حذفولو ډاډ لرئ؟',
      DeletePaymentOrderTitle: 'آیا د تادیې فرمایش حذفولو ډاډ لرئ؟',
      DeleteCustomerTitle: 'آیا د مشتری حذفولو ډاډ لرئ؟',
      DeleteAccountTitle: 'آیا د حساب حذفولو ډاډ لرئ؟',
      DeleteCurrencyTitle: 'آیا د اسعارو حذفولو ډاډ لرئ؟',
      DeleteShareholderTitle: 'آیا د سهامدار حذفولو ډاډ لرئ؟',
      DeleteTransactionTitle: 'آیا د سند حذفولو ډاډ لرئ؟',
      DeleteChequeTitle: 'آیا د چک حذفولو ډاډ لرئ؟',
      DeleteHistoryTitle: 'آیا د تاریخچه حذفولو ډاډ لرئ؟',
      DeletePaymentPlaceTitle: 'آیا د تادیې ځای حذفولو ډاډ لرئ؟',
      DeleteBranchTitle: 'آیا د نمایندګۍ حساب حذفولو ډاډ لرئ؟',
      DeleteCurrencyCalculationTitle: 'آیا د اسعارو محاسبات حذفولو ډاډ لرئ؟',
      DeletePersonTitle: 'آیا د شخص حذفولو ډاډ لرئ؟',
      DeleteDraftDocumentTitle: 'آیا د حوالې سند حذفولو ډاډ لرئ؟',
      DeleteTransaction2Title: 'آیا د معاملې حذفولو ډاډ لرئ؟',
      NoSubmittedDraft: 'حواله نه ده ثبت شوې.',
      NoSubmittedBranch: 'نمایندګي نه ده ثبت شوې.',
      NoSubmittedReceiver: 'ترلاسه کوونکی نه دی ثبت شوی.',
      RejectPendingDraftTitle: 'آیا د حوالې ردولو ډاډ لرئ؟',
      UndoDraftCancellationTitle: 'آیا د حوالې لغوه کولو بېرته راوړلو ډاډ لرئ؟',
      CancelDraftSuccessfully: 'حواله په بریالیتوب سره بېرته راوړل شوه.',
      NoSubmittedPaymentOrder: 'د تادیې فرمایش نه دی ثبت شوی.',
      NoSubmittedCustomer: 'مشتری نه دی ثبت شوی.',
      NoSubmittedCheckout: 'تسویه نه ده ثبت شوې.',
      NoSubmittedCheckout2: 'هیڅ تسویه نه ده ثبت شوې.',
      NoSubmittedDocument: 'هیڅ سند نه دی ثبت شوی.',
      NoSubmittedDocument2: 'هیڅ سند نه دی ثبت شوی.',
      NoSubmittedOperation: 'هیڅ عملیات نه دي ترسره شوي!',
      NoSubmittedTicket: 'هیڅ تیکټ نه دی ثبت شوی.',
      DraftRegistrationSuccessfully: 'حواله په بریالیتوب سره ثبت شوه.',
      EditDraftSuccessfully: 'حواله په بریالیتوب سره ویرایش شوه.',
      IncreaseCustomerCredit: 'د مشتری کریډیټ په بریالیتوب سره زیات شو.',
      DraftNumberSettingsSuccessfully:
        'د حوالې شمېره تنظیمات په بریالیتوب سره ترسره شول.',
      EditBranchSuccessfully: 'نمایندګي په بریالیتوب سره ویرایش شوه.',
      EditPaymentPlaceSuccessfully: 'د تادیې ځای په بریالیتوب سره ویرایش شو.',
      EditPersonSuccessfully: 'شخص په بریالیتوب سره ویرایش شو.',
      RegisterPaymentPlaceSuccessfully: 'د تادیې ځای په بریالیتوب سره ثبت شو.',
      RegisterPersonSuccessfully: 'شخص په بریالیتوب سره ثبت شو.',
      IncreaseOrganizationSuccessfully:
        'د سازمان کریډیټ په بریالیتوب سره زیات شو.',
      DeleteOrderDraftSuccessfully: 'د حوالې فرمایش په بریالیتوب سره حذف شو.',
      DeleteDraftSuccessfully: 'حواله په بریالیتوب سره حذف شوه.',
      DeleteTransactionSuccessfully: 'معامله په بریالیتوب سره حذف شوه.',
      ExportExcelSuccessfully: 'د اکسل فایل په بریالیتوب سره ډاونلوډ شو',
      ExportExcelAttention:
        'پام وکړئ چې د ایکسل فایل ترلاسه کولو لپاره باید ټول وړاندیز شوي ډلې په خپل سیسټم تنظیماتو کې اضافه کړې وي!',
      AddThisGroupError:
        'مهرباني وکړئ لاندې ګروپ په خپل سیسټم تنظیماتو کې اضافه کړئ',
      ExportExcelAccountGroupsError:
        'بخښنه غواړو، ډاونلوډ ترسره نه شو. مهرباني وکړئ وروسته بیا هڅه وکړئ',
      DeleteChequeSuccessfully: 'چک په بریالیتوب سره حذف شو.',
      DeleteSystemAccountSuccessfully: 'د سیستم حساب په بریالیتوب سره حذف شو.',
      DeleteReceiverSuccessfully: 'ترلاسه کوونکی په بریالیتوب سره حذف شو.',
      DeleteDocumentSuccessfully: 'سند په بریالیتوب سره حذف شو.',
      DeleteAttachmentSuccessfully: 'ضمیمه په بریالیتوب سره حذف شوه.',
      DeleteBankInformationSuccessfully:
        'بانکي معلومات په بریالیتوب سره حذف شول.',
      DeleteAddressSuccessfully: 'پته په بریالیتوب سره حذف شوه.',
      DeleteCustomerSuccessfully: 'شخص په بریالیتوب سره حذف شو.',
      DeleteAccountSuccessfully: 'حساب په بریالیتوب سره حذف شو.',
      DeleteSuccessfully: 'حذف په بریالیتوب سره ترسره شو.',
      DeleteCurrencySuccessfully: 'اسعار په بریالیتوب سره حذف شول.',
      DeleteShareholderSuccessfully: 'سهامدار په بریالیتوب سره حذف شو.',
      DeletePaymentPlaceSuccessfully: 'د تادیې ځای په بریالیتوب سره حذف شو.',
      DeleteBranchSuccessfully: 'نمایندګي په بریالیتوب سره حذف شوه.',
      DeleteBankAccountSuccessfully: 'بانکي حساب په بریالیتوب سره حذف شو.',
      RejectPendingDraftSuccessfully: 'حواله په بریالیتوب سره رد شوه.',
      SelectCheckoutDate: 'د تسویې نېټه انتخاب کړئ.',
      DeleteLastCheckoutText: 'یوازې د مشتری وروستۍ تسویه حذف کولی شئ.',
      DeleteLastBranchCheckoutText:
        'یوازې د نمایندګۍ وروستۍ تسویه حذف کولی شئ.',
      DisconnectBranchTitle: 'آیا د نمایندګۍ اتصال قطع کولو ډاډ لرئ؟',
      DisconnectBranchSuccessfully: 'د نمایندګۍ اتصال په بریالیتوب سره قطع شو.',
      DeletePhoneNumberSuccessfully: 'تلیفون شمېره په بریالیتوب سره حذف شوه.',
      SubmitDocSuccessfully: 'سند په بریالیتوب سره ثبت شو.',
      ShareReceiptText:
        'تاسو کولی شئ د معاملو رسید د لاندې لارو څخه شریکه کړئ:',
      ShareInterBankTransactionReceiptText:
        'تاسو کولی شئ د بین بانکي معاملې رسید د لاندې لارو څخه شریکه کړئ:',
      ShareTradeCurrencyTransactionReceiptText:
        'تاسو کولی شئ د اسعارو پیرود رسید د لاندې لارو څخه شریکه کړئ:',
      ShareSellCurrencyTransactionReceiptText:
        'تاسو کولی شئ د اسعارو خرڅلاو رسید د لاندې لارو څخه شریکه کړئ:',
      ShareSubmitTransactionReceiptText:
        'تاسو کولی شئ د سند رسید د لاندې لارو څخه شریکه کړئ:',
      ShareExecuteDraftReceiptText:
        'تاسو کولی شئ د حوالې اجرا رسید د لاندې لارو څخه شریکه کړئ:',
      ShareDraftReceiptText:
        'تاسو کولی شئ د حوالې رسید د لاندې لارو څخه شریکه کړئ:',
      PrintReceiptWithHeader: 'رسید د سربرګ سره چاپ کړئ.',
      PrintReceiptWithoutHeader: 'رسید پرته له سربرګ څخه چاپ کړئ.',
      NotActiveSystemError: 'سیستم ستاسو لپاره فعال نه دی!',
      ExpiredSubscriptionError: 'ستاسو د ګډون موده پای ته رسیدلې ده!',
      ReActiveAccountTitle: 'د بیا فعالولو لپاره، د نسخه اخیستلو تڼۍ فشار کړئ.',
      buyVersionTitle: 'د آریانا سیستم کارولو لپاره، لومړی نسخه واخلئ.',
      UseDesktopVersionTitle:
        'د دې ځانګړتیا فعالولو لپاره، د سیستم ډیسکتاپ نسخه وکاروئ.',
      NoActiveMobile: 'هیڅ فعال موبایل حساب نشته.',
      SendAccountBalanceSuccessfully:
        'د حساب پاتې شوې اندازه په بریالیتوب سره واستول شوه.',
      SendToBranch: 'نمایندګۍ ته واستوئ.',
      SentToWhatsapp: 'واتساپ ته واستول شو.',
      AutomaticSendToWhatsapp: 'په اوتومات ډول واتساپ ته واستوئ.',
      SendManuallyToWhatsApp: 'په لاس واتساپ ته واستوئ.',
      DepositAmountsDivision: 'د جمع شویو پیسو ویش.',
      SelectBankInformation: 'د بانک معلومات انتخاب کړئ.',
      'Cash desk cant be creditor': 'صندوق نه شي کولی طلبکار وي.',
      'What is private transaction?': 'محرمانه معامله څه ده؟',
      'Submit unknown bank deposit': 'نامعلوم بانکي واریز ثبت کړئ.',
      SelectRowAlert: 'لومړی یو ردیف انتخاب کړئ.',
      Copied: 'کاپي شوه.',
      SpecifyDraftType: 'د حوالې ډول مشخص کړئ.',
      TransactionRegistrationDetails: 'د معاملو ثبت مشخصات.',
      SubmitBugReportSuccessfully:
        'د خطا راپور په بریالیتوب سره ثبت شو او په نږدې وخت کې ځواب ورکړل شي.',
      RegisterBankAccountSuccessfully: 'بانک په بریالیتوب سره جوړ شو.',
      RegisterNewFilesSuccessfully: 'نوي اسناد په بریالیتوب سره ثبت شول.',
      RegisterBranchSuccessfully: 'نمایندګي په بریالیتوب سره ثبت شوه.',
      EditAdditionalInfoSuccessfully:
        'اضافي معلومات په بریالیتوب سره ویرایش شول.',
      SubmitAdditionalInfoSuccessfully:
        'اضافي معلومات په بریالیتوب سره ثبت شول.',
      SubmitBuyCurrencySuccessfully: 'د اسعارو پیرود په بریالیتوب سره ثبت شو.',
      changeActivePhoneNumberSuccessfully:
        'فعال تلیفون شمېره په بریالیتوب سره بدله شوه.',
      CreateAccountSuccessfully: 'حساب په بریالیتوب سره جوړ شو.',
      EditAccountSuccessfully: 'حساب په بریالیتوب سره ویرایش شو.',
      AddCustomersSuccessfully: 'مشتریان په بریالیتوب سره اضافه شول.',
      ExecuteDraftSuccessfully: 'حواله په بریالیتوب سره اجرا شوه.',
      ChangeActiveAddressSuccessfully: 'فعاله پته په بریالیتوب سره بدله شوه.',
      ShowMore: 'نور ښکاره کړئ',
      ShowLess: 'کم ښکاره کړئ',
      DraftCommission: 'د حوالې کمیشن',
      DraftCommissionAmount: 'د حوالې کمیشن اندازه',
      BranchShouldBeSelected: 'نمایندګي باید انتخاب شي.',
      ReceivedCurrency: 'د ترلاسه شوي اسعارو ډول',
      ConvertCurrencyRate: 'د اسعارو د تبادلې نرخ',
      'Draft Equivalent Currency': 'د حوالې معادل اسعار',
      'Submit Common Commission': 'مشترکه کمیشن ثبت کړئ',
      CheckRateTime: 'د حوالې نرخ اعتبار پای ته رسېدلی دی.',
      BankAccountBalance: 'د بانک حساب پاتې شوې اندازه',
      BuyerBankAccountBalance: 'د پیرودونکي بانک حساب پاتې شوې اندازه',
      SelerBankAccountBalance: 'د پلورونکي بانک حساب پاتې شوې اندازه',
      Dari: 'دری',
      Pashto: 'پشتو',
      // ***********************************
      // end actionTypes
      // ***********************************
      Row3: '3\u200E ردیف',
      Row4: '4\u200E ردیف',
      Row5: '5\u200E ردیف',
      Row6: '6\u200E ردیف',
      Row10: '10\u200E ردیف',
      Row15: '15\u200E ردیف',
      Row20: '20\u200E ردیف',
      Row25: '25\u200E ردیف',
      Row50: '50\u200E ردیف',
      Row100: '100\u200E ردیف',
      Row150: '150\u200E ردیف',
      Row200: '200\u200E ردیف',
      Row250: '250\u200E ردیف',
      // ************************* not translated ***************************
      Like: 'معلول',
      Monthly: 'میاشتنی',
      Yearly: 'کلنی',
      Quarterly: 'درې میاشتنی',
      SpecifyTypesOfIdentityDocuments: 'د پېژندنې د سندونو ډولونه مشخص کړئ',
      IssuingOrganization: 'صادرونکی ارګان',
      Light: 'روښانه',
      Dark: 'توره',
      CheckoutAccount: 'وتنه',
      AccountDetails: 'د حساب تفصيل',
      Copy: 'کاپي',
      AUnexecutedDraft: 'نامکمله حواله',
      TransactionDate: 'د سند نېټه',
      TransactionRow: 'د سند کتار',
      openingBalance: 'پیل موجودي',
      SubmitNewCheckout: 'نوي تسویه حساب ثبت کړئ',
      BalanceAmount: 'د موجودي اندازه',
      OperationHistory: 'د عملیات تاریخچه',
      Share: 'شریک کړئ',
      Highlight: 'روښانه کړئ',
      RemoveHighlight: 'روښانه کول لرې کړئ',
      History: 'تاریخچه',
      HighlightedTransactions: 'روښانه شوي لیږدونه',
      ShowHighlightedTransactions: 'روښانه شوي لیږدونه ښکاره کړئ',
      LastTransaction: 'وروستی لیږد',
      Exchange: 'صرافی',
      RemainingAccount: 'د حساب پاتې',
      BranchAddress: 'د څانګې پته',
      BranchPhone: 'د څانګې ټلیفون',
      Rate: 'نرخ',
      PaymentType: 'د تادیې ډول',
      RemainingCashAccount: 'د نغدي حساب پاتې',
      ShowCashAccountBalanceMsg: 'د صندوق د حساب پاتې به په تفکیک ښکاره شي.',
      PrivateCashTransaction: 'د نغدي لیږد محرمانه وي.',
      Submit: 'تایید',
      BankWithdraw: 'د بانک ایستنه',
      BankDeposit: 'د بانک واریز',
      SyncBalanceAndCreditLimit: 'موجودي له اعتبار حد سره همغږي کړئ',
      AmountAccountWhatsappReport:
        'دلته ستاسو د حساب موجودي داسې ښودل شوی دی :',
      ChequeAssigner: 'چک سپارونکی',
      ChequeReceiver: 'چک ترلاسه کوونکی',
      ChequePaymentPlace: 'د چک د تادیې ځای',
      'Visit Cheque owner account': 'د چک د مالک حساب وګورئ',
      Received: 'ترلاسه شوی',
      Paid: 'تادیه شوی',
      Category: 'کټه ګورۍ',
      Branch2: 'څانګه',
      CustomerAccountRemaining: 'د مشتری حساب پاتې',
      VisitBuyerAccount: 'د اخیستونکي حساب وګورئ',
      VisitSellerAccount: 'د پلورونکي حساب وګورئ',
      EditOutgoingDraft: 'د صادر شوي حوالې تدوین',
      EditIncomingDraft: 'د راتلونکې حوالې تدوین',
      CancelIncomingDraft: 'د راتلونکې حوالې لغوه کول',
      CancelOutgoingDraft: 'د صادر شوي حوالې لغوه کول',
      ReceiveDraftAmount: 'د حوالې اندازه ترلاسه کړئ',
      VisitCreditorAccount: 'د طلبکار حساب وګورئ',
      VisitDebtorAccount: 'د قرضدار حساب وګورئ',
      AccountType: 'د حساب ډول',
      CustomerType: 'د مشتری ډول',
      RealCustomer: 'حقیقي',
      LegalCustomer: 'حقوقي',
      TradeRate: 'د سوداګرۍ نرخ',
      SubmitSellCurrencySuccessfully:
        'د اسعارو خرڅلاو په بریالیتوب سره ثبت شو.',
      CashDesk: 'د نغدي حساب',
      PrivatePrint: 'محرمانه چاپ',
      General: 'عمومي',
      Banking: 'بانکي',
      RegisterTransactionMsg: 'معامله په بریالیتوب سره ثبت شوه',
      AccountManagement: 'د حساب مدیریت',
      ReceiveDate: 'د ترلاسه کولو نېټه',
      NormalBranches: 'عادي څانګې',
      ConnectedBranches: 'وصل شوي څانګې',
      ChequeNumber: 'د چک شمېره',
      ChequeBalance: 'د چک پاتې',
      'Total balance of accounts': 'د ټولو حسابونو موجودي',
      CheckoutDate: 'د تسویه نیټه',
      In: 'په',
      SubmitConnection: 'وصل تایید کړئ',
      AddAdditionalInformation: 'نور معلومات اضافه کړئ',
      BranchCurrency: 'د څانګې اسعار',
      NationalCode: 'د ملي کوډ',
      ConnectBranchText: 'د څانګې سره د وصلولو لپاره لاندې لینک ولیږئ.',
      LinkValidityText: 'د لینک اعتبار 10 دقیقې دی',
      SureAboutOneWayConnection:
        'آیا تاسو د یو طرفه وصل کولو په اړه مطمئن یاست؟',
      AllowedDraftLimit: 'د حوالې مجاز حد',
      MirrorBranchAccount: 'د مقابل څانګې حساب',
      DraftNumberSettings: 'د حوالې شمېره تنظیمات',
      View: 'وګورئ',
      OtherNumbers: 'نور شمیرې',
      SelectGroup: 'ډله انتخاب کړئ',
      DraftRow: 'د حوالې کتار',
      DraftType: 'د حوالې ډول',
      BusinessName: 'تجارتي نوم',
      'With commission only': 'یوازې کمیسیون لرونکی',
      User: 'کاروونکی',
      Time: 'وخت',
      Explanation: 'شرح',
      City2: 'شهر',
      ReceivedAmount: 'ترلاسه شوې اندازه',
      DraftNumberAlert: 'د حوالې له شماره باید لوی وي.',
      FilterBasedOnDate: 'په تاریخ پراساس فیلتر کړئ',
      FilterBasedOnDraftNumber: 'په حوالې شمېره پراساس فیلتر کړئ',
      VisitAccountInMirrorBranch: 'په مقابل څانګه کې حساب وګورئ',
      Canceled: 'لغو شوی',
      Canceller: 'لغو کونکی',
      AllPaymentPlaces: 'ټول د تادیې ځایونه',
      DraftSubmissionSettings: 'د حوالې ثبت تنظیمات',
      StatusBySelectedFiltersAlert: 'وضعیت د غوره شویو فیلټرونو پراساس',
      OldBankTransaction: 'زوړ بانک لیږد',
      DraftConductionEditPersonAlert:
        'د ترلاسه کونکي اسناد وګورئ او تدوین یې کړئ.',
      AccountOperation: 'شخص عملیات',
      SelectDraftCustomer: 'د حوالې مشتری وټاکئ',
      AddPerson: 'شخص اضافه کړئ',
      NewBankTransaction: 'نوی بانک لیږد',
      SelectTransactionType: 'د لیږد ډول مشخص کړئ.',
      ViewDocuments: 'اسناد وګورئ',
      DraftInformation: 'د حوالې معلومات',
      ApplicationUsername: 'د اپلیکیشن کارن نوم',
      OutgoingNumber: 'صادره شمېره',
      IncomingNumber: 'ورودی شمېره',
      DeleteDocument: 'سند حذف کړئ',
      DeleteReceiver: 'ترلاسه کونکی حذف کړئ',
      New: 'نوې',
      Old: 'زوړ',
      CashAccount: 'نغدي حساب',
      SameCurrencyAlert: 'د لیږد اسعار باید یو وي.',
      ReceiveDraftDate: 'د حوالې ترلاسه کولو نېټه',
      TheWayOfTransaction: 'د لیږد طریقه',
      PrivateDraftConduction: 'محرمانه حواله ترسره کړئ',
      SelectUnexecutedDraftAlert: 'پیل شوی حواله وټاکئ.',
      AllBranches: 'ټولې څانګې',
      BaseOnAmount: 'د مقدار پر اساس',
      BaseOnRate: 'د نرخ پر اساس',
      RegistrationCode: 'د ثبت کود',
      Email2: 'برېښنالیک',
      IssuerName: 'د صادرونکي نوم',
      IssuerDescription: 'د صادرونکي توضیحات',
      OccupationTitle: 'د کار عنوان',
      RegistrationDate: 'د ثبت نیټه',
      BankInformation: 'د بانک معلومات',
      'Customer ID': 'د پیرودونکي ID',
      ViewMoreInformation: 'د نورو معلوماتو کتنه',
      SystemNewFeatures: 'د سیسټم نوې ځانګړتیاوې',
      CommunicationType: 'د اړیکې ډول',
      Close: 'بندول',
      Next: 'بل',
      SelectedActiveDocument: 'فعال سند غوره شوی',
      SelectedActiveNumber: 'فعال شمېره غوره شوې',
      ContinueWithoutSubmitNumber: 'د شمیرې پرته دوام',
      ViewLessInformation: 'د لږ معلوماتو کتنه',
      SelectAsTheActiveNumber: 'د فعالې شمیرې په توګه غوره کول',
      AddContactInfo: 'د تماس معلومات اضافه کړئ',
      SelectAsCurrentNumber: 'د اوسني شمیرې په توګه غوره کول',
      AddDocument: 'سند اضافه کړئ',
      IndividualJobInformation: 'شخصي دندې معلومات',
      AccountCode: 'د حساب کود',
      PersonalInformation: 'شخصي معلومات',
      CountryCode: 'د هیواد کود',
      InputAmount: 'ورود مقدار',
      ExcelFile: 'ایکسېل فایل',
      ExcelFileFormat: 'د ایکسېل فایل بڼه',
      SaderatBank: 'صادرات بانک',
      OldFormat: 'زوړ بڼه',
      NewFormat: 'نوې بڼه',
      SepahBank: 'سپه بانک',
      UserName: 'د کارونکي نوم',
      UploadPhoto: 'عکس پورته کول',
      MaknakUsername: 'د مکنک کارونکي نوم',
      Language: 'ژبه',
      ActivateTheCamera: 'کمره فعال کړئ',
      StandardFormat: 'معیاري بڼه',
      AddAttachment: 'ضمیمه اضافه کړئ',
      PunishmentsList: 'د مجازاتو لیست',
      DuplicateFileNameAlert: 'یوه ورته نوم لرونکی فایل موجود دی',
      SearchResult: 'د لټون پایله',
      InputCurrency: 'ورود اسعار',
      Position: 'سمت',
      shareRate: 'د ونډې کچه',
      SubmitShareholder: 'د ونډه لرونکي ثبتول',
      AddShareholder: 'ونډه لرونکی اضافه کړئ',
      SubmitShareholder2: 'ونډه لرونکی ثبت کړئ',
      CustomerSearch: 'د پیرودونکي لټون',
      '404 error': 'د 404 تېروتنه - پاڼه ونه موندل شوه',
      AddToShareholders: 'ونډه لرونکو ته اضافه کول',
      PageNotFount: 'پاڼه ونه موندل شوه.',
      PassportNumber: 'د پاسپورت شمېره',
      ResidenceNumber: 'د استوګنې شمېره',
      PageNotAvailable: 'د لټون شوې پاڼه موجوده نه ده.',
      'Is the Maknak software active for the user or not?':
        'ایا د کارونکي لپاره مکنک سافټویر فعال دی یا نه؟',
      HasApplicationUserName: 'د اپلیکیشن کارونکي نوم لري',
      'Edit Occupation And Personal Details': 'د کار او شخصي معلوماتو سمون',
      BirthCity: 'د زېږېدنې ښار',
      MainDocument: 'اصلي سند',
      ServiceType: 'د خدماتو ډول',
      CompanyType: 'د شرکت ډول',
      TakePicture: 'عکس اخیستل',
      CompanyName: 'د شرکت نوم',
      OrganizationNumber: 'د سازمان شمېره',
      RegistrationNumber: 'د ثبت شمېره',
      OriginCountry: 'اصلي هېواد',
      PaymentOrderBalance: 'د تادیې امر پاتې والی',
      DebtorInformation: 'د پوروړي معلومات',
      ConfirmDraftTitle: 'آیا تاسو په ټاکل شوو حوالو کې ډاډمن یاست؟',
      PureProfit: 'خالص ګټه',
      Profit: 'ګټه',
      CalculatedProfitAmount: 'محاسبه شوې ګټه',
      CalculatedLossAmount: 'محاسبه شوی زیان',
      Lossment: 'زیان',
      RejectDraft: 'حواله رد کړئ',
      RejectDraftReason: 'د حوالې د رد دلیل',
      RejectDraftReasonMessage: 'د حوالې د رد علت ولیکئ.',
      OriginCurrency: 'اصلي اسعار',
      DestinationCurrency: 'مقصد اسعار',
      SubmittedBy: 'ثبت شوی د لخوا',
      CreditorInformation: 'د طلبګار معلومات',
      PreventionCalculations: 'مخنیوی محاسبې',
      ComputingManagement: 'د محاسبې مدیریت',
      GrindingRate: 'د نقدۍ نرخ',
      InvalidRate: 'نرخ په معتبر حدودو کې نه دی.',
      CalculateProfit: 'ګټه محاسبه کړئ',
      BuyAmount: 'د پیرود قیمت',
      BuyCurrency: 'د پیرود اسعار',
      TotalPurchase: 'د پیرود ټولیزه کچه',
      CreditCollection: 'د طلب ټولګه',
      ExecutePaymentOrder: 'د تادیې امر اجرا کړئ',
      PaymentOrderDetails: 'د تادیې امر جزئیات',
      PrincipalAmount: 'اصلي مقدار',
      CardSender: 'د کارت لیږونکی',
      CurrencyAmount: 'د اسعار مقدار',
      ExecuteTransaction: 'تراکنش اجرا کړئ',
      Creditors: 'طلبګاران',
      Deleted: 'حذف شوی',
      Deposited: 'زېرمه شوی',
      VisitDeposited: 'زېرمه شویو کتنه',
      AllCheques: 'ټول چکونه',
      HasAssignorCommission: 'آیا د دې تراکنش لپاره لیږونکی کمیسیون لري؟',
      HasReceiverCommission: 'آیا د دې تراکنش لپاره اخیستونکی کمیسیون لري؟',
      SubmitOperation: 'عملیات ثبت کړئ',
      ChequeOperation: 'د چک عملیات',
      AllOptions: 'ټول انتخابونه',
      ReceivedFrom: 'ترلاسه شوی د',
      FilteredBy: 'فیلتر شوی د',
      AllDocs: 'ټول اسناد',
      TransactionTypeAmount: 'د تراکنش ډول پاتې والی',
      VisitCreditors: 'د طلبګارانو کتنه',
      NoState: 'هیڅ حالت شتون نه لري!',
      Card: 'کارت',
      SellCurrency: 'د پلور اسعار',
      MirrorBranch: 'مقابله څانګه',
      BankDescription: 'د بانک توضیحات',
      DeterminingDraftRateValidityTime: 'د حوالې نرخ اعتبار وخت ټاکل',
      DraftRateValidityDateTime: 'د حوالې نرخ د اعتبار نیټه او وخت',
      DollarRate: 'د ډالر نرخ',
      IncreaseCurrencyDecimalPlaceWarning:
        'د اسعار اعشاریه ځایونو کمښت د تراکنشونو سمون کې ګډوډي رامنځته کوي.',
      RateValidityTime: 'د نرخ اعتبار وخت',
      SystemDefaultCurrency: 'د سیسټم ډیفالټ اسعار',
      SelectAsSystemDefaultCurrency: 'د سیسټم ډیفالټ اسعار په توګه غوره کړئ',
      NoSelectedCurrency: 'هیڅ اسعار د نمایش لپاره نه دی غوره شوی.',
      DecimalPlace: 'اعشاریه ځای',
      AllCurrencies: 'ټول اسعار',
      SubmitNewBank: 'نوی بانک ثبت کړئ',
      SubmitCheque: 'چک ثبت کړئ',
      SubmitPaymentOrder: 'د تادیې امر ثبت کړئ',
      CommissionDetails: 'د کمیسیون جزئیات',
      Multiplication: 'ضرب',
      Division: 'تقسیم',
      RoundationAccuracy: 'د دقت ګردش',
      Solar: 'شمسي',
      DateType: 'د نیټې ډول',
      gregorian: 'میلادي',
      MoneyxSupport: 'د منيکس ملاتړ',
      AnswerText: 'د ځواب متن',
      ExchangeSettingsTitle: 'د دې پاڼو معلومات ستاسو د صرافۍ تنظیمات دي.',
      CompletionFormsRequired: '(د فورمو بشپړول لازمي دی)',
      SystemColorMode: 'د سیسټم رنګ حالت',
      SubmitYourGroups: 'خپل ګروپونه ثبت کړئ.',
      ReceiveDocFromCustomerTitle:
        '(که تاسو د پیرودونکي څخه سند نه غواړئ، له دې برخې تیر شئ.)',
      'Organizations List': 'د سازمانونو لیست',
      currencyName: 'د اسعار نوم',
      ViewCurrency: 'د اسعار نمایش',
      WhatsappActiveDefault:
        'آیا د تراکنشونو په اعلانونو کې د واتساپ خبرتیاوې په طور د مخکینۍ ټاکنې فعاله وي؟',
      CurrencySymbol: 'د اسعارو سمبول',
      MyAccounts: 'زما حسابونه',
      TimeZone: 'وخت زون',
      ProfitAndLossRate: 'د ګټې او زیان کچه',
      PercentageChange: 'سلنه بدلون',
      LongTimeWarning: 'دا پروسه ممکن یو څه وخت ونیسي',
      DefaultPhoneNumberCode: 'اصلي د تلیفون نمبر کوډ',
      Slogan: 'شعار',
      ReceiptRules: 'د رسید قوانین',
      AddRule: 'قانون اضافه کړئ',
      AddSameGroupsError: 'د ورته ګروپونو جوړولو امکان نشته',
      DocumentTitleError: 'د سند سرلیک نشي تش پاتې کېدای',
      IssuingOrganizationError: 'د صادرونکي سازمان نوم تش پاتې نشي',
      CreateSameDocumentError: 'د ورته سند جوړولو امکان نشته',
      CharacterLimit: 'تر ۱۰۰ کرکټرونو پورې',
      HeaderUploaderTitle: 'د سرلیک انځور اوږدوالی او پلنوالی باید یو شان وي.',
      syncCreditLimitActive: 'د کریډیټ حد همغږي فعاله کړئ؟',
      IncreaseCreditLimitDescription:
        '(که همغږي فعاله وي، هر کله چې د پیرودونکي کریډیټ کافي نه وي، د تراکنش د مقدار په اندازه کریډیټ حد زیاتېږي.)',
      profitAndLossAccuracy: 'د ګټې او زیان دقت سلنه',
      DisplayDeviation:
        'د سیسټم د حساسیت سلنه مشخص کړئ ترڅو حسابونو کې مغایرتونه ښودل شي.',
      CashDeskName: 'د نغدي حساب نوم',
      CostAccountName: 'د لګښت حساب نوم',
      SubmitYourCurrencies: 'خپل کاریدونکي اسعارونه ثبت کړئ.',
      DeterminingDraftRate: 'د حوالې نرخ ټاکل',
      DraftCurrencyOrder: 'د حوالې د اسعارو ترتیب',
      AddPaymentPlace: 'د پیسو ځای اضافه کړئ',
      NoCommissions: 'هیڅ کمیسیون نشته.',
      ViewHeader: 'سرلیک وګورئ',
      Capitals: 'شریک او اسهام',
      ReportPeriod: 'د راپور موده',
      ExchangeName: 'د تبادلې شرکت نوم',
      CurrentCapitals: 'فعاله سرمایې',
      ConnectAccountToEquivalentAccount: 'حساب له معادل حساب سره ونښلوئ',
      PrePays: 'دمخه پیسې',
      PrePurchaseContract: 'د مخکې پیرود قرارداد',
      TotalValueOfOtherAssets: 'د نورو شتمنیو ټول ارزښت',
      FixedAssets: 'ثابتې شتمنۍ',
      TangibleAssets: 'ملموسه شتمنۍ',
      ReductionInDepreciationOfTangibleAssets:
        'د ملموسه شتمنیو په ارزښت کې کموالی',
      ReductionInDepreciationOfIntangibleAssets:
        'د غیر ملموسه شتمنیو په ارزښت کې کموالی',
      IntangibleAssets: 'غیر ملموسه شتمنۍ',
      Debts: 'پورونه',
      CurrentDebts: 'فعاله پورونه',
      OtherDebts: 'نور پورونه',
      PreSaleContracts: 'دمخه د خرڅلاو قراردادونه',
      PayableBills: 'د تادیې وړ بلونه',
      PayableTax: 'د تادیې وړ مالیه',
      OperativeExpenses: 'عملیاتي لګښتونه',
      TotalShareholdersEquity: 'د شریکانو ټول سرمایې',
      OtherRevenue: 'نورې عواید',
      AdministrativeExpenses: 'اداري لګښتونه',
      depreciationExpenses: 'د ارزښت کمښت',
      telephoneExpenses: 'د تلیفون لګښتونه',
      rentalExpenses: 'کرایه',
      insuranceExpenses: 'بیمه',
      licensingExpenses: 'د جواز لګښتونه',
      employeeExpenses: 'د کارمندانو لګښتونه (معاشونه او نورې امتیازونه)',
      otherExpenses: 'نور لګښتونه',
      ReceiveExcelFile: 'د اکسل فایل ترلاسه کړئ',
      TotalDataOutput: 'د ټولو معلوماتو محصول',
      SelectedDataOutput: 'د انتخاب شوي معلوماتو محصول',
      ExitFromSystem: 'له سیسټم څخه ووتل',
      PersonProfile: 'د شخص پروفایل',
      ReceiveTotalDataExcel: 'د ټولو معلوماتو اکسل ترلاسه کړئ',
      DataOutPutOneTimeMessage: 'دا عملیات په ورځ کې یو ځل ترسره کیدی شي.',
      AutoDownloadTheExcelFile:
        'د اکسل فایل به له چمتو کیدو وروسته په اتومات ډول ډاونلوډ شي.',
      ExcelFileDownloaded: 'د اکسل فایل په بریالیتوب سره ډاونلوډ شو.',
      ExcelFileDownloadFailed: 'د اکسل فایل ډاونلوډ ناکامه شوه.',
      ReceiveTotalDataExcelFile: 'د ټولو معلوماتو د اکسل فایل ترلاسه کړئ',
      UserAccountSystem: 'کارونکي حساب سیسټم',
      GetScreenShout: 'له صفحې څخه سکرین شاټ واخلئ',
      AddFile: 'که اړتیا وي فایل اضافه کړئ.',
      ErrorPage: 'د خطا صفحه',
      chargingSystem: 'چارجینګ سیسټم',
      AccountingSystem: 'محاسبې سیسټم',
      Asset: 'سرمایه',
      WelcomeMessage: 'ته د مانیکس حسابدارۍ پینل ته ښه راغلاست وایو!',
      PoliticalFighter: 'سیاسي مبارز',
      HighRisk: 'لوړ خطر',
      ModerateRisk: 'منځنۍ خطر',
      LowRisk: 'کم خطر',
      SmallBusiness: 'وړه سوداګري',
      BigBusiness: 'لویه سوداګري',
      CurrencyAuction: 'د اسعارو لیلام',
      SanctionList: 'د تعزیراتو لیست پلي کول',
      'Central analysis of trades and financial reports':
        'د سوداګریو او مالي راپورونو مرکزي تحلیل',
      'Exchange license validation': 'د جواز اعتبار تایید',
      EnterExchangeNameMessage: 'مهرباني وکړئ د خپل صرافۍ نوم دننه کړئ',
      'Exchange Name': 'د صرافۍ نوم',
      Is: 'دی',
      IsNot: 'ندی',
      ShareholdersList: 'د شریکانو لیست',
      EditShareholder: 'شریک ویرایستل',
      Attachments: 'ضمیمې',
      HasCustomer: 'مشتری لري',
      Limited: 'محدود',
      MainPhoneNumber: 'اصلي نمبر',
      '(Optional)': '(اختیاري)',
      Done: 'ترسره شو',
      'Under Review': 'تر بیاکتنې لاندې',
      'Real customer': 'حقیقي مشتری',
      'Legal customer': 'حقوقي مشتری',
      'Number of Rows': 'شمیر',
      SubmitPaymentPlace: 'د پیسو ځای ثبت کړئ',
      EditPaymentPlace: 'د پیسو ځای ویرایستل',
      PaymentPlaceName: 'د پیسو د ځای نوم',
      agentName: 'د مسوول نوم',
      Transactions: 'تراکنشونه',
      TotalCustomerCreditStatus: 'د مشتری ټول کریډیټ حالت',
      FromDraftNumber: 'له حوالې نمبر څخه',
      ToDraftNumber: 'تر حوالې نمبر پورې',
      'Settled documents': 'تصفیه شوې اسناد',
      registerTransactionGroups: 'د معاملې لپاره ګروپ ثبت کړئ',
      registerAccountsGroups: 'د حسابونو لپاره د ګروپ ثبتول',
      'Error Number': 'د خطا شمېره',
      'Error Title': 'د خطا سرلیک',
      'Error Opening Date': 'د خطا پرانیستلو نېټه',
      'Error Closing Date': 'د خطا تړلو نېټه',
      'Error Status': 'د خطا حالت',
      CanceledDrafts: 'لغوه شوي حوالې',
      Count: 'شمېر',
      UseWebcam: 'ویبکام وکاروئ (CTRL + k)',
      accountStatusMessage2:
        'د حساب په انتخاب سره، د حساب پاتې شوني به ښودل شي',
      'Checkout History': 'د تسویې تاریخچه',
      'Last Checkout': 'وروستۍ تسویه',
      'Cheque History': 'د چک تاریخچه',
      'Upload Excel File': 'د اکسل فایل اپلوډ کړئ',
      'Information Output': 'د معلوماتو محصول',
      'Normal Print': 'معمول چاپ',
      'Date Of Payment Order': 'د تادیې د حکم نېټه',
      'Transaction Date': 'د تراکنش نېټه',
      'Transaction Number': 'د تراکنش شمېره',
      'Buy Currency': 'د اسعارو پیرود',
      'Sell Currency': 'د اسعارو پلور',
      'Buy Commission': 'د پیرود کمیسیون',
      'Sell Commission': 'د پلور کمیسیون',
      'Buy Currency Setting': 'د اسعارو د پیرود تنظیمات',
      'Sell Currency Setting': 'د اسعارو د پلور تنظیمات',
      'Upload Documents': 'لاسوند پورته کړئ',
      'Confirm Buy': 'پېر تایید کړئ',
      'Confirm Sell': 'پلور تایید کړئ',
      'Trade Rate': 'د تبادلې نرخ',
      Convert: 'تبدیل',
      'Buy Amount': 'د پېر اندازه',
      'Sell Amount': 'د پلور اندازه',
      'Seller Commission': 'د پلورونکي کمیسیون',
      'Buyer Commission': 'د خریدار کمیسیون',
      Buyer: 'پېرودونکی',
      Seller: 'پلورونکی',
      'Draft Number': 'د حوالې شمېره',
      'Incoming Draft Number': 'د راتلونکې حوالې شمېره',
      'Outgoing Draft Number': 'د تلونکې حوالې شمېره',
      Confirm: 'تایید',
      'Draft Cancellation': 'د حوالې لغو',
      'Transaction Type': 'د تراکنش ډول',
      System: 'سیستم',
      'System Account': 'د سیستم حساب',
      'Customer Commission': 'د مشتری کمیسیون',
      'Branch Commission': 'د څانګې کمیسیون',
      'Transaction Status': 'د تراکنش وضعیت',
      'Settings And File Upload': 'تنظیمات او فایل پورته کول',
      'Other Number': 'بله شمېره',
      'Debtor Commission': 'د پوروړي کمیسیون',
      'Creditor Commission': 'د پور ورکوونکي کمیسیون',
      'Debtor Account': 'د پوروړي حساب',
      'Creditor Account': 'د پور ورکوونکي حساب',
      'Commission Currency': 'د کمیسیون ارز',
      'Edit Miscellaneous Draft': 'متفرقه حواله ویرایش کړئ',
      'Spot Rate': 'د موجوده نرخ',
      'Source Of Money': 'د پیسو سرچینه',
      'Purpose Of Exchange': 'د تبادلې موخه',
      'Periodic Profit Chart': 'د ګټې دوره يي چارټ',
      'Daily Transactions Chart': 'د ورځنیو تراکنشونو چارټ',
      'Organization Chart': 'د سازمان چارټ',
      'Draft List': 'د حوالې لیست',
      'Birth Certificate': 'د زیږون سند',
      'National Card': 'ملي کارت',
      Passport: 'پاسپورټ',
      'Physical Tezkire': 'فزیکي تذکره',
      'Electronic Tezkire': 'برقي تذکره',
      'Kimlik Card': 'د کیملیک کارت',
      'Driving License': 'د موټر چلولو جواز',
      'Temporary Residence Card Of Foreign Nationals':
        'د بهرني اتباعو د موقتي اوسېدو کارت',
      Police: 'پولیس',
      'Civil Registry Office': 'د احوال ثبت اداره',
      'Immigration Office': 'د مهاجرت اداره',
      'Live Currency Rate': 'د ارز ژوندی نرخ',
      'Due to the long number of letters of the selected document information, it is not possible to share.':
        'د انتخاب شوي سند د معلوماتو اوږدو حروفو له امله، د شراکت امکان نشته.',
      draftStatusMessage:
        'له حساب او ارز حوالې وروسته، د حساب موجودي ښودل کېږي',
      bankStatusMessage: 'له بانک انتخاب وروسته، د بانک موجودي ښودل کېږي',
      DestinationBankStatusMessage:
        'له بانک مقصد انتخاب وروسته، د بانک موجودي ښودل کېږي',
      SourseBankStatusMessage:
        'له بانک مبدا انتخاب وروسته، د بانک حساب ښودل کېږي',
      bankStatusMessage2: 'له بانک انتخاب وروسته، د بانک حساب موجودي ښودل کېږي',
      positiveTradeRate: 'د تبادلې نرخ باید له صفر څخه زیات وي.',
      invalidTradeRate: 'د تبادلې نرخ معتبر نه دی',
      'Bank withdrawal': 'له بانکه پیسې ایستل',
      'Bank deposit': 'بانک ته پیسې جمع کول',
      accountStatusMessage:
        'د حساب او ارز انتخاب وروسته، د حساب موجودي ښودل کېږي',
      'Connected With Data': 'له ډیټا سره تړلی',
      'Retry (Application Is Not Running)': 'بې له ډیټا تړلی',
      'Connected Without Data': 'بیا هڅه وکړئ (کاریال نه دی روان)',
      'Synchronization Done': 'همغږي ترسره شوه',
      'Sync Account Title':
        'ایا تاسو د پیرودونکو د پور محدودیتونو همغږي کولو ډاډ لرئ؟',
      customerStatus: 'حالت مشتری',
      Prepayments: 'مخکې ورکړې',
      AdvancePurchaseContracts: 'د مخکې پیرلو قراردادونه',
      Assets: 'شتمونه',
      ReductionInTangibleAssets: 'د ملموس شتمنیو د استهلاکاتو کمښت',
      DepreciationOfIntangibleAssets: 'د غیر ملموس شتمنیو کمښت',
      PayableTaxes: 'د ورکړې وړ مالیات',
      AdvanceSalesContracts: 'د مخکې پلورلو قراردادونه',
      TelephoneAndCommunicationExpenses: 'د تلیفون او مخابراتي لګښتونه',
      RentExpenses: 'د کرایې لګښتونه',
      Insurance: 'بیمه',
      Partners: 'شریکان',
      LicensingExpenses: 'د جواز اخستلو لګښتونه',
      EmployeeSalaries: 'د کارمندانو معاشونه',
      ClaimsOnCentralBank: 'د افغانستان بانک غوښتنې',
      Vendors: 'دستفروشانو',
      Permanent1: 'دایمی',
      Temporary: 'موقتي',
      uploadFileWithTouch: 'د فایل اپلوډ کولو لپاره ټچ وکړئ.',
      'Draft Settlement': 'د حوالې تصفیه',
      AdditionalInformation: 'اضافي معلومات',
      SubmitInformation: 'د معلوماتو ثبت',
      SubmitAdditionalInformation: 'اضافي معلومات ثبت کړئ',
      editAdditionalInformation: 'اضافي معلومات سم کړئ',
      branchTitle: 'د نمایندګۍ لقب',
      EditedSuccessfully: 'په بریالیتوب سره ترمیم شوی',
      EditBasicInformation: 'لومړني معلومات سم کړئ',
      RegisterPaymentPlace: 'د تادیې ځای ثبت کړئ',
      NumberOfPaymentPlaces: 'د تادیاتو ځایونو شمیر',
      ThePlaceOfPaymentIsNotRegistered: 'د تادیې ځای راجستر شوی نه دی',
      PaymentPlace: 'د تادیې ځای',
      RegistrationIsDone: 'نوم لیکنه ترسره کیږي'
    },
    dir: 'rtl'
  }
};

export default resources;
